import GaAnalytics from './main';
import Vue from 'vue';

export default {
    install: (app) => {
        let pluginName = '$gaAnalytics';
        let pluginInstance = new GaAnalytics();

        if (app.config && app.config.hasOwnProperty('globalProperties')) {
            // Vue3

            /* eslint-disable-next-line no-param-reassign */
            app.config['globalProperties'][pluginName] = pluginInstance;
        } else {
            // Vue2

            if (!app.prototype[pluginName]) {
                Object.defineProperty(app.prototype, pluginName, {value: pluginInstance})
            }
        }
    },
};
