import * as DataStructure from './dataStructures';

export class DataClass {
    constructor(value) {
        this.value = value;
    }

    getValue() {
        return this.value;
    }

    getDataStructure() {
        throw new Error('getDataStructure must be overwritten');
    }

    getType() {
        if (this instanceof InitBit) {
            return 'InitBit';
        } else if (this instanceof Version) {
            return 'Version';
        } else if (this instanceof ResultBarPickerValue) {
            return 'ResultBarPickerValue';
        } else if (this instanceof AgeGroup) {
            return 'AgeGroup';
        } else if (this instanceof Theme) {
            return 'Theme';
        } else if (this instanceof Reflection) {
            return 'Reflection';
        } else if (this instanceof SignWithSlider) {
            return 'SignWithSlider';
        } else if (this instanceof Sign) {
            return 'Sign';
        }

        return 'Unknown type';
    }
}

export class InitBit extends DataClass {
    /**
     * A class representing the very first "1" in the binary string
     *
     * Bit meaning: Always 1; used to avoid trimming 0's in the start of the string
     *
     * Example:
     *   1
     */

    constructor() {
        super(1);
    }

    getDataStructure() {
        return DataStructure.InitBit;
    }
}

export class Version extends DataClass {
    /**
     * A class representing the version of the data in case it ever gets updated
     *
     * Bit meaning: The literal binary string converted to decimal
     *
     * Example:
     *   0 1 0
     *     |
     *     \-- A literal binary representation of the version, in this case the version is "2"
     */

    getDataStructure() {
        return DataStructure.Version;
    }
}

export class ResultBarPickerValue extends DataClass {
    /**
     * A class representing the answer of the age group
     *
     * Bit meaning: The literal binary string converted to decimal
     *
     * Example:
     *   0 1 0 0
     *     |
     *     \-- A literal binary representation of the slider value - in this case the slider value is "4"
     */

    getDataStructure() {
        return DataClasses.ResultBarPickerValue;
    }
}

export class AgeGroup extends DataClass {
    /**
     * A class representing the answer of the age group
     *
     * Bit meaning: The literal binary string converted to decimal
     *
     * Example:
     *   0 1 0 0
     *     |
     *     \-- A literal binary representation of the age group index, in this case the index is "4" is "6 - 9"
     */

    getDataStructure() {
        return DataStructure.AgeGroup;
    }
}

export class Reflection extends DataClass {
    /**
     * A class representing the answer of a Reflection (checkboxes) where every bit means selected/not selected
     *
     * Bit meaning: Indexes are selected/deselected
     *
     * Example:
     *   0 0 0 1 1 0 0
     *         | |
     *         \-\-- Answer 4 and 5 are selected, the rest are not
     */

    constructor(reflectionAnswers) {
        let calcTotal, calcReflectionAnswers, calcType;

        if (reflectionAnswers instanceof Array) {
            calcType = 'array';
            calcReflectionAnswers = reflectionAnswers;
            calcTotal = 0;

            for (let i = 0; i < 7; i++) {
                if (reflectionAnswers.includes(i)) {
                    calcTotal += 1 << i;
                }
            }
        } else {
            calcType = 'int';
            calcTotal = reflectionAnswers;
            calcReflectionAnswers = [];

            for (let i = 0; i < 7; i++) {
                if ((1 << i) & calcTotal) {
                    calcReflectionAnswers.push(i);
                }
            }
        }

        super(calcTotal);

        this.reflectionAnswers = calcReflectionAnswers;
    }

    getDataStructure() {
        return DataStructure.Reflection;
    }
}

export class SignWithSlider extends DataClass {
    /**
     * A class representing the answer of a Reflection (checkboxes)
     *
     * Bit meaning: The first bit is the yes/no answer, the next 3 are for the "often" answer and the next 3 are for the "worry" answer
     *
     * Example:
     *   1 0 0 1 1 0 0
     *   | | | | | | |
     *   | | | | \-\-\-- The index of the worry answer - 0 being the first answer and 5 being the last one
     *   | | | |
     *   | \-\-\-- The index of the often answer - 0 being the first answer and 5 being the last one
     *   |
     *   \-- The "yes/no" answer - always 1 for slider values
     */

    constructor(value, oftenIndex, worryIndex) {
        super(value); // The answer is always yes if we have indexes

        this.oftenIndex = oftenIndex || 0;
        this.worryIndex = worryIndex || 0;
    }

    getDataStructure() {
        return DataStructure.SignWithSlider;
    }
}

export class Sign extends DataClass {
    /**
     * A class representing the answer of a Reflection (checkboxes)
     *
     * Bit meaning: The first bit is the yes/no answer
     *
     * Example:
     *   1
     *   |
     *   \-- The "yes/no" answer - 1 for yes and 0 for no
     */

    getDataStructure() {
        return DataStructure.Sign;
    }
}

export class Theme extends DataClass {
    /**
     * A class representing the answer of whether a theme should be included (checkboxes)
     *
     * Bit meaning: The first bit is whether or not to include this theme
     *
     * Example:
     *   1
     *   |
     *   \-- The "include theme" answer - 1 for include, 0 for do not include
     */

    getDataStructure() {
        return DataStructure.Theme;
    }
}