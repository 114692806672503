<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-5">
                    <label>Age group</label>
                    <select v-model="registrationAgeGroup" class="form-control">
                        <option v-for="ageGroupKey in ageGroupKeys" :key="ageGroupKey" :value="ageGroupKey"
                                v-text="ageGroups[ageGroupKey]"/>
                    </select>

                    <label>Store data</label>
                    <textarea v-model="questionAnswersJson" class="form-control" rows="25"/>
                </div>
                <div class="col-2">
                    <div class="mt-5">
                        <button class="btn btn-success btn-block" v-on:click="compress">-> Compress -></button>
                    </div>
                    <div class="mt-5">
                        <button class="btn btn-success btn-block" v-on:click="decompress"><- Decompress <-</button>
                    </div>
                </div>
                <div class="col-5">
                    <label>Compressed data</label>
                    <textarea v-model="compressedData" class="form-control" rows="29"/>
                </div>
            </div>
            <div class="row">
                <h4>Summary</h4>

                <summary-container :questionAnswers="questionAnswers" :reflectionsQuestions="reflectionsQuestions"
                                   :signsQuestions="signsQuestions"/>
            </div>
        </div>
    </div>
</template>

<script>
import OverviewBar from '@/components/common/OverviewBar.vue';
import ResultBar from '@/components/result-bar/ResultBar.vue';
import RoskildeButton from '@/components/buttons/RoskildeButton'
import DataClassRow from '@/components/dev/compression/DataClassRow';
import StructureRow from '@/components/dev/compression/StructureRow';
import DataCompressor from '@/utils/compression/dataCompressor';
import SummaryContainer from '../../components/summary/SummaryContainer';
import {ageGroupKeys, ageGroups} from '@/collections';
import {mapGetters} from 'vuex';

export default {
    name: 'dev-components',
    components: {
        SummaryContainer,
        RoskildeButton,
        OverviewBar,
        ResultBar,
        DataClassRow,
        StructureRow,
    },
    data() {
        let questionAnswers = {
            "1.signs.yesNo": 0,
            "2.signs.yesNo": 0,
            "3.signs.yesNo": 1,
            "4.signs.yesNo": 1,
            "4.signs.slider": {"often": 0, "worried": 0},
            "1.reflections": [0, 1],
            "2.reflections": [0, 1, 3],
            "3.reflections": [1, 4]
        };

        let registrationAgeGroup = '0-1';

        return {
            ageGroupKeys: ageGroupKeys,
            ageGroups: ageGroups,

            compressedData: '',
            registrationAgeGroup: registrationAgeGroup,
            questionAnswersJson: JSON.stringify(questionAnswers, null, 4),

            signsQuestions: [],
            reflectionsQuestions: [],
        };

    },
    methods: {
        compress() {
            this.compressedData = DataCompressor.compressFromAgeGroup(this.registrationAgeGroup, this.questionAnswers);
        },
        decompress() {
            let data = DataCompressor.decompressFromData(this.compressedData);

            console.log('data', data);

            this.questionAnswersJson = JSON.stringify(data.questionAnswers, null, 4);
            this.registrationAgeGroup = data.registrationAgeGroup;

            // Calc signs
            let signsQuestions = [];
            let signs = this.appData.signs;

            if (signs.hasOwnProperty(this.registrationAgeGroup)) {
                let signsAgeGroup = signs[this.registrationAgeGroup];

                for (let questionUid of signsAgeGroup['questionUids']) {
                    signsQuestions.push(signsAgeGroup['questions'][questionUid]);
                }
            } else {
                console.error('Could not find signs for age group', this.registrationAgeGroup);
            }

            this.signsQuestions = signsQuestions;

            // Calc reflections
            let reflectionsQuestions = [];
            let reflections = this.appData.reflections;

            if (reflections.hasOwnProperty(this.registrationAgeGroup)) {
                let reflectionsAgeGroup = reflections[this.registrationAgeGroup];

                for (let reflectionUid of reflectionsAgeGroup['reflectionUids']) {
                    reflectionsQuestions.push(reflectionsAgeGroup['reflections'][reflectionUid]);
                }
            } else {
                console.error('Could not find reflections for age group', this.registrationAgeGroup);
            }

            this.reflectionsQuestions = reflectionsQuestions;
        },
    },
    computed: {
        ...mapGetters({
            appData: 'getAppData',
        }),
        questionAnswers() {
            return this.questionAnswersJson ? JSON.parse(this.questionAnswersJson) : {};
        }
    },
    mounted() {
        this.compress();
        this.decompress();
    }
}
</script>

<style lang="scss" scoped>
textarea {
    white-space: pre;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 12px;
}
</style>