<template>
    <div class="pl-3">
        <div class="mb-3">
            <div v-for="size in buttonMutations.size" :key="size" class="row mb-2">
                <roskilde-button
                    v-for="type in buttonMutations.type"
                    :key="type"
                    :size="size"
                    :text="type + ' - ' + size"
                    :type="type"
                    class="mr-2"
                />
            </div>
        </div>
        <div>
            <div class="form-group">
                <label>Disabled</label>
                <div class="btn-group" role="group">
                    <button
                        v-for="value in buttonMutations.disabled"
                        :class="buttonConfig.disabled === value ? 'btn-primary' : 'btn-secondary'"
                        class="btn"
                        type="button"
                        v-on:click="buttonConfig.disabled = value"
                        v-text="value"
                    />
                </div>
            </div>
            <div class="form-group">
                <label>Can toggle</label>
                <div class="btn-group" role="group">
                    <button
                        v-for="value in buttonMutations.canToggle"
                        :class="buttonConfig.canToggle === value ? 'btn-primary' : 'btn-secondary'"
                        class="btn"
                        type="button"
                        v-on:click="buttonConfig.canToggle = value"
                        v-text="value"
                    />
                </div>
            </div>
            <div class="form-group">
                <label>Type</label>
                <div class="btn-group" role="group">
                    <button
                        v-for="value in buttonMutations.type"
                        :class="buttonConfig.type === value ? 'btn-primary' : 'btn-secondary'"
                        class="btn"
                        type="button"
                        v-on:click="buttonConfig.type = value"
                        v-text="value"
                    />
                </div>
            </div>
            <div class="form-group">
                <label>Toggle Type</label>
                <div class="btn-group" role="group">
                    <button
                        v-for="value in buttonMutations.type"
                        :class="buttonConfig.toggleType === value ? 'btn-primary' : 'btn-secondary'"
                        class="btn"
                        type="button"
                        v-on:click="buttonConfig.toggleType = value"
                        v-text="value"
                    />
                </div>
            </div>
            <div class="form-group">
                <label>Size</label>
                <div class="btn-group" role="group">
                    <button
                        v-for="value in buttonMutations.size"
                        :class="buttonConfig.size === value ? 'btn-primary' : 'btn-secondary'"
                        class="btn"
                        type="button"
                        v-on:click="buttonConfig.size = value"
                        v-text="value"
                    />
                </div>
            </div>
            <div class="form-group">
                <label>Border type</label>
                <div class="btn-group" role="group">
                    <button
                        v-for="value in buttonMutations.borderType"
                        :class="buttonConfig.borderType === value ? 'btn-primary' : 'btn-secondary'"
                        class="btn"
                        type="button"
                        v-on:click="buttonConfig.borderType = value"
                        v-text="value"
                    />
                </div>
            </div>
            <div class="form-group">
                <label>Width type</label>
                <div class="btn-group" role="group">
                    <button
                        v-for="value in buttonMutations.widthType"
                        :class="buttonConfig.widthType === value ? 'btn-primary' : 'btn-secondary'"
                        class="btn"
                        type="button"
                        v-on:click="buttonConfig.widthType = value"
                        v-text="value"
                    />
                </div>
            </div>
        </div>
        <roskilde-button
            :borderType="buttonConfig.borderType"
            :canToggle="buttonConfig.canToggle"
            :disabled="buttonConfig.disabled"
            :size="buttonConfig.size"
            :toggleType="buttonConfig.toggleType"
            :type="buttonConfig.type"
            :widthType="buttonConfig.widthType"
        >
            Content
        </roskilde-button>
    </div>
</template>

<script>
import OverviewBar from '@/components/common/OverviewBar.vue';
import ResultBar from '@/components/result-bar/ResultBar.vue';
import RoskildeButton from '@/components/buttons/RoskildeButton';

let buttonMutations = {
    disabled: [
        false,
        true
    ],
    canToggle: [
        false,
        true
    ],
    type: [
        'dark',
        'light',
        'blue',
        'white',
        'lighter',
    ],
    widthType: [
        'auto',
        'fixed',
        'fixed-small',
    ],
    size: [
        'xxs',
        'xs',
        'sm',
        'md',
        'lg',
        'xl'
    ],
    borderType: [
        'full',
        'full-left',
        'full-right',
        'partial'
    ],
};

export default {
    name: 'dev-buttons',
    data() {
        return {
            buttonMutations: buttonMutations,
            buttonConfig: {
                disabled: false,
                canToggle: true,
                type: 'dark',
                toggleType: 'dark',
                widthType: 'auto',
                size: 'md',
                borderType: 'full',
            }
        }
    },
    components: {
        RoskildeButton,
        OverviewBar,
        ResultBar,
    }
}
</script>
