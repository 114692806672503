export let ageGroupKeys = ['unborn', '0-1', '1-2', '3-5', '6-9', '10-14', '15-17', '18-22'];

export let ageGroups = {
    'unborn': 'Ufødt',
    '0-1': '0-1',
    '1-2': '1-2',
    '3-5': '3-5',
    '6-9': '6-9',
    '10-14': '10-14',
    '15-17': '15-17',
    '18-22': '18-22'
};