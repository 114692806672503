<template>
    <div>
        <roskilde-button v-on:click="openPopupChoices">Open questions</roskilde-button>

        <roskilde-button v-on:click="openPopupInfo">Open info</roskilde-button>
    </div>
</template>

<script>
import OverviewBar from '@/components/common/OverviewBar.vue';
import ResultBar from '@/components/result-bar/ResultBar.vue';
import RoskildeButton from '../../components/buttons/RoskildeButton';

export default {
    name: 'dev-components',
    components: {
        RoskildeButton,
        OverviewBar,
        ResultBar,
    },
    data() {
        return {}
    },
    methods: {
        openPopupChoices() {
            this.$popup.open({
                title: 'Popup title',
                text: 'This is a popup!',
                choices: [
                    {
                        value: 'back',
                        text: 'Tilbage til tegn',
                    },
                    {
                        value: 'summary',
                        text: 'Fortsæt til overblik',
                    }
                ]
            }).then((result) => {
                console.log('result', result);
            });
        },
        openPopupInfo() {
            this.$popup.open({
                title: 'Popup title',
                text: 'This is a popup!',
            }).then((result) => {
                console.log('result', result);
            });
        }
    }
}
</script>
