let appData = require('../../fixtures/app-data');

const thresholds = appData.thresholds;

export function getAnswerUid(uid, category, type) {
    if (category === 'signs') {
        uid = uid.replace(/\.signs$/, '');
    } else if (category === 'reflections') {
        uid = uid.replace(/\.reflections$/, '');
    }

    return uid + '.' + category + (type ? '.' + type : '');
}

export function getThresholdScoreLevel(scoreThresholdMinimum, scoreThresholdMaximum, totalScore) {
    let thresholdNormalized = Math.abs(scoreThresholdMinimum) + Math.abs(scoreThresholdMaximum);
    let thresholdScoreNormalized = Math.abs(scoreThresholdMinimum) + totalScore;
    let thresholdScoreLevel = (thresholdScoreNormalized / thresholdNormalized) * 10.5;

    console.log({
        name: 'ScoreCalculator.getThresholdScoreLevel',
        scoreThresholdMinimum: scoreThresholdMinimum,
        scoreThresholdMaximum: scoreThresholdMaximum,
        totalScore: totalScore,
        thresholdNormalized: thresholdNormalized,
        thresholdScoreNormalized: thresholdScoreNormalized,
        thresholdScoreLevel: thresholdScoreLevel
    });

    return thresholdScoreLevel;
}

export function getQuestionClassificationFromScore(questionScore, questionAnswerYesNo, questionAnswerSlider) {
    if (questionAnswerSlider) {
        if (questionAnswerSlider.hasOwnProperty('worried')) {
            if (questionAnswerSlider.worried === -2) {
                return 'critical';
            } else if (questionAnswerSlider.worried === -1) {
                return 'caution';
            } else if (questionAnswerSlider.worried === 0) {
                return 'moderate';
            } else if (questionAnswerSlider.worried === 2) {
                return 'positive';
            }
        }
    }

    return null;
    /*
        for (let classification in thresholds) {
            let classificationThreshold = thresholds[classification];

            if (questionScore >= classificationThreshold.minimum && questionScore < classificationThreshold.maximum) {
                return classification;
            }
        }

        return null;*/
}

function _getFraction(min, max, fraction) {
    return ((max - min) * fraction) + min;
}

export function getFraction(min, max, fraction) {
    return _getFraction(min, max, fraction);
}

export function getSignScore(question, questionAnswerYesNo, questionAnswerSlider) {
    let questionScore = 0;

    if (questionAnswerYesNo === question.confirmation.answer) {
        if (questionAnswerSlider) {
            let oftenModification = 0, worriedModification = 0;
            let baseScore = question.confirmation.scoreModification;

            let oftenAnswer = questionAnswerSlider.often;
            let worriedAnswer = questionAnswerSlider.worried;

            if (oftenAnswer === 2) {
                // Not really that worried, do nothing
            } else if (oftenAnswer === 0) {
                oftenModification = question.often.rareBaseScore * baseScore;
            } else if (oftenAnswer === -2) {
                oftenModification = question.often.oftenBaseScore * baseScore;
            }

            if (worriedAnswer === 2) {
                // Not really that worried, do nothing
            } else if (worriedAnswer === 0) {
                worriedModification = question.worried.notWorriedModifier * baseScore;
            } else if (worriedAnswer === -1) {
                worriedModification = question.worried.moderatelyWorriedModifier * baseScore;
            } else if (worriedAnswer === -2) {
                worriedModification = question.worried.veryWorriedModifier * baseScore;
            }

            let totalModification = 0;

            if (oftenModification) {
                totalModification += oftenModification;
            }

            if (worriedModification) {
                totalModification += worriedModification;
            }

            questionScore += totalModification;

            console.log('getSignScore', {
                questionAnswerYesNo: questionAnswerYesNo,
                questionAnswerSlider: questionAnswerSlider,
                question: question,

                oftenAnswer: oftenAnswer,
                worriedAnswer: worriedAnswer,

                baseScore: baseScore,

                oftenModification: oftenModification,
                worriedModification: worriedModification,
                totalModification: totalModification,
                questionScore: questionScore,
            });
            /*

                        let fractionOften = questionAnswerSlider.often / 4; // Turn 4 into 1, turn 0 into 0
                        let fractionWorried = questionAnswerSlider.worried / 4; // Turn 4 into 1, turn 0 into 0

                        let calculatedFractionOften, calculatedFractionWorried;

                        calculatedFractionOften = _getFraction(question.often.rareBaseScore, question.often.oftenBaseScore,
                            fractionOften);

                        if (question.worried) {
                            calculatedFractionWorried = _getFraction(question.worried.notWorriedModifier,
                                question.worried.veryWorriedModifier, fractionWorried) / 100;
                        } else {
                            calculatedFractionWorried = 1;
                        }

                        let calculatedScore = (calculatedFractionOften * calculatedFractionWorried);

                        questionScore += calculatedScore;*/
        }
    }

    return questionScore;
}

export function getReflectionScore(question, questionAnswers) {
    let questionScore = 0;

    for (let i of questionAnswers) {
        questionScore += question.answers[i].scoreModification;
    }

    console.log({
        'question': question,
        'questionAnswers': questionAnswers,
        'questionScore': questionScore
    });
    return questionScore;
}

export function getScoreThresholds(questionsSigns, questionsReflections) {
    let worstScoreTotal = 0;
    let bestScoreTotal = 0;

    let questionsAssociated = {};

    let counts = {
        worstScoreTotalNotNan: 0,
        bestScoreTotalNotNan: 0,
        bestScoreTotal: 0,
        worstScoreTotal: 0,
        worstScoreOften: 0,
        worstScoreWorried: 0,
        questionsSignsCount: questionsSigns.length,
        questionsReflectionCount: questionsReflections.length,
    };

    let questionSignUids = [];
    let questionReflectionUids = [];

    for (let question of questionsSigns) {
        questionsAssociated[question.uid] = question;

        questionSignUids.push(question.uid);

        console.log('getScoreThresholds.QUESTION', question);

        /*if (questionAnswerSlider.often === 2) {
            questionScore += question.often.oftenBaseScore * question.confirmation.scoreModification;
        } else if (questionAnswerSlider.often === 1) {
            questionScore += question.often.rareBaseScore * question.confirmation.scoreModification;
        }

        if (questionAnswerSlider.worried === 2) {
            questionScore += question.worried.veryWorriedModifier * question.confirmation.scoreModification;
        } else if (questionAnswerSlider.worried === 1) {
            questionScore += question.worried.notWorriedModifier * question.confirmation.scoreModification;
        }*/

        let worstScore = 0;
        let bestScore = 0;

        let scoreModification = question.confirmation.scoreModification;

        if (scoreModification > 0) {
            bestScore += scoreModification;
        } else {
            if (question.sliders.often) {
                let multiplier = Math.max(question.often.oftenBaseScore, question.often.rareBaseScore);

                worstScore += scoreModification * multiplier;
                counts.worstScoreOften++;
            }

            if (question.sliders.worried) {
                let multiplier = Math.max(question.worried.notWorriedModifier, question.worried.veryWorriedModifier);

                worstScore += scoreModification * multiplier;
                counts.worstScoreWorried++;
            }
        }

        if (!isNaN(worstScore)) {
            worstScoreTotal += worstScore;
            counts.worstScoreTotalNotNan++;
        } else {
            console.log('Worst score', worstScore, 'was NaN');
        }

        if (!isNaN(bestScore)) {
            bestScoreTotal += bestScore;
            counts.bestScoreTotalNotNan++;
        } else {
            console.log('Best score', bestScore, 'was NaN');
        }
    }

    for (let question of questionsReflections) {
        questionsAssociated[question.uid] = question;

        questionReflectionUids.push(question.uid);

        let foundNegative = false, foundPositive = false;
        let lowestScore = null, highestScore = null;

        for (let answer of question.answers) {
            lowestScore = lowestScore === null ? answer.scoreModification : Math.min(lowestScore, answer.scoreModification);
            highestScore = highestScore === null ? answer.scoreModification : Math.max(highestScore, answer.scoreModification);

            if (answer.scoreModification <= 0) {
                bestScoreTotal += answer.scoreModification;
                counts.bestScoreTotal++;

                foundNegative = true;
            }

            if (answer.scoreModification >= 0) {
                worstScoreTotal += answer.scoreModification;
                counts.worstScoreTotal++;

                foundPositive = true;
            }
        }

        if (!foundNegative) {
            //console.log('question', question, 'had no negative scores');
            //scoreThresholdMaximum += highestScore;
        }

        if (!foundPositive) {
            //console.log('question', question, 'had no positive scores');
            //bestScoreTotal += lowestScore;
        }
    }

    /*for (let question of questionsSigns) {
        if (question.children && question.children.length) {
            for (let childId of question.children) {
                let childQuestion = questionsAssociated[childId];

                if (!questionsAssociated.hasOwnProperty(childId)) {
                    console.log('Could not find associated question by id "' + childId + '"');

                    continue;
                }

                if (childQuestion.hasSlider) {
                    if (!childQuestion.often) {
                        //console.error('Often key is missing', childQuestion);

                        continue;
                    }

                    if (!childQuestion.worried) {
                        //console.error('Worried key is missing', childQuestion);

                        continue;
                    }

                    let oftenRareBaseScore = childQuestion.often.rareBaseScore;
                    let oftenOftenBaseScore = childQuestion.often.oftenBaseScore;
                    let notWorriedModifier = childQuestion.worried.notWorriedModifier;
                    let veryWorriedModifier = childQuestion.worried.veryWorriedModifier;

                    let notWorriedFraction = notWorriedModifier / 100;
                    let veryWorriedFraction = veryWorriedModifier / 100;

                    let edgeCalculations = [
                        oftenRareBaseScore * notWorriedFraction,
                        oftenRareBaseScore * veryWorriedFraction,
                        oftenOftenBaseScore * notWorriedFraction,
                        oftenOftenBaseScore * veryWorriedFraction,
                    ];

                    let edgeMin = Math.min(...edgeCalculations);
                    let edgeMax = Math.max(...edgeCalculations);

                    scoreThresholdMinimum += edgeMin;
                    scoreThresholdMaximum += edgeMax;
                }
            }
        }
    }*/

    console.log('ScoreCalculator', {
        url: window.location.href,
        counts: counts,
        questionSignUids: questionSignUids,
        questionReflectionUids: questionReflectionUids,
        worst: worstScoreTotal,
        best: bestScoreTotal,
        questionsSigns: questionsSigns,
        questionsReflections: questionsReflections,
    });

    return {
        worst: worstScoreTotal,
        best: bestScoreTotal,
    }
}
