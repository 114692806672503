export default {
    setup(timeTakenMs) {
        this.time({
            name: 'setup',
            value: timeTakenMs,
            event_label: 'Setup',
            event_category: 'Flow',
        })
    },

    signs(timeTakenMs) {
        this.time({
            name: 'signs',
            value: timeTakenMs,
            event_label: 'Signs',
            event_category: 'Flow',
        })
    },

    reflections(timeTakenMs) {
        this.time({
            name: 'reflections',
            value: timeTakenMs,
            event_label: 'Reflections',
            event_category: 'Flow',
        })
    },
};
