import Vue from 'vue'
import VueRouter from 'vue-router'

// Flow views
import IntroView from '@/views/flow/00-Intro.vue'
import SetupView from '@/views/flow/01-Setup.vue'
import SignsView from '@/views/flow/02-Signs.vue'
import ReflectionsView from '@/views/flow/03-Reflections.vue'
import SummaryView from '@/views/flow/04-Summary.vue'
import SingleSummaryView from '@/views/flow/05-SingleSummary.vue'
import PrintSummaryView from '@/views/flow/06-PrintSummary.vue'

// Dev views
import DevIndexView from '@/views/dev/index.vue';
import DevButtonsView from '@/views/dev/Buttons.vue';
import DevComponentsView from '@/views/dev/Components.vue';
import DevPopupView from '@/views/dev/Popup.vue';
import DevCompressionView from '@/views/dev/Compression.vue'
import DevBarView from '@/views/dev/Bar.vue';

const routes = [
    {
        path: '/',
        name: 'Intro',
        component: IntroView
    },
    {
        path: '/setup',
        name: 'Setup',
        component: SetupView,
        children: [
            {
                path: 'age',
                meta: {
                    page: 'age',
                }
            },
            {
                path: 'function',
                meta: {
                    page: 'function',
                    showBackButton: true
                }
            },
            {
                path: 'theme',
                meta: {
                    page: 'theme',
                    showBackButton: true
                }
            }
        ]
    },
    {
        path: '/signs',
        name: 'Signs',
        component: SignsView,
        meta: {
            showBackButton: true
        },
        children: [
            {
                path: 'summary',
                meta: {
                    page: 'summary',
                    showBackButton: false
                }
            },
            {
                path: ':page',
                meta: {
                    showBackButton: true
                }
            },
            {
                path: ':page/:questionIndex',
                meta: {
                    showBackButton: true
                }
            },
            {
                path: ':page/:questionIndex/slider',
                meta: {
                    withSlider: true,
                    showBackButton: true
                }
            },
            {
                path: ':page/:questionIndex/slider/often',
                meta: {
                    withSlider: true,
                    sliderType: 'often',
                    showBackButton: true
                }
            },
            {
                path: ':page/:questionIndex/slider/worried',
                meta: {
                    withSlider: true,
                    sliderType: 'worried',
                    showBackButton: true
                }
            }
        ]
    },
    {
        path: '/reflections',
        name: 'Reflections',
        component: ReflectionsView,
        meta: {
            showBackButton: true
        },
        children: [
            {
                path: 'summary',
                meta: {
                    page: 'summary',
                    showBackButton: false
                }
            },
            {
                path: ':page',
                meta: {
                    showBackButton: true
                }
            },
            {
                path: ':page/:questionIndex',
                meta: {
                    showBackButton: true
                }
            },
        ]
    },
    {
        path: '/summary',
        name: 'Summary',
        component: SummaryView
    },
    {
        path: '/r',
        name: 'SingleSummary',
        component: SingleSummaryView,
        children: [
            {
                path: ':code',
            }
        ]
    },
    {
        path: '/print',
        name: 'PrintSummary',
        component: PrintSummaryView,
        children: [
            {
                path: ':code',
            }
        ]
    }
];

routes.push({
    path: '/dev',
    name: 'dev-index',
    component: DevIndexView
});

routes.push({
    path: '/dev/buttons',
    name: 'dev-buttons',
    component: DevButtonsView
});

routes.push({
    path: '/dev/components',
    name: 'dev-components',
    component: DevComponentsView
});

routes.push({
    path: '/dev/bar',
    name: 'dev-bar',
    component: DevBarView
});

routes.push({
    path: '/dev/popup',
    name: 'dev-popup',
    component: DevPopupView
});

routes.push({
    path: '/dev/compression',
    name: 'dev-compression',
    component: DevCompressionView
});

Vue.use(VueRouter)

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes
})

export default router
