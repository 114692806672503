<template>
    <roskilde-button borderType="partial" size="sm" type="light" widthType="auto" v-on:click="goBack">Tilbage
    </roskilde-button>
</template>

<script>
import RoskildeButton from '@/components/buttons/RoskildeButton';

export default {
    name: 'back-button',
    components: {RoskildeButton},
    methods: {
        goBack() {
            window.history.back();
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
