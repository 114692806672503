<template>
    <div>
        <router-link to="/dev/buttons">Buttons</router-link>
        <br/>
        <router-link to="/dev/components">Components</router-link>
        <br/>
        <router-link to="/dev/compression">Compression</router-link>
        <br/>
        <router-link to="/dev/popup">Popup</router-link>
        <br/>
        <router-link to="/dev/bar">Bar</router-link>
        <br/>
    </div>
</template>

<script>
export default {
    name: 'dev-index'
}
</script>
