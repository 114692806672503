<template>
    <div class="segment-row">
        <div>
            <h4>{{ type }}</h4>
        </div>
        <div>
            <p>Bit length: {{ bitLength }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'segment-row',
    props: {
        structure: {
            type: Object
        }
    },
    computed: {
        bitLength() {
            return this.structure.getBitLength();
        },
        type() {
            return this.structure.getType();
        }
    }
}
</script>

<style lang="scss" scoped>
.segment-row {
    background: $gray;
    color: $black;
    padding: 10px;
    margin-bottom: 5px;
}
</style>