<template>
    <div class="reflection-checkbox-container">
        <reflection-checkbox
            v-for="(checkbox, i) in checkboxes"
            v-if="type !== 'summary' || selectedCheckboxes[i]"
            :key="i"
            :info="checkbox.info"
            :label="checkbox.label"
            :type="type"
            :value="selectedCheckboxes[i]"
            v-on:input="toggleCheckbox(i)"/>
    </div>
</template>

<script>
import ReflectionCheckbox from './ReflectionCheckbox';

export default {
    name: 'reflection-checkbox-container',
    components: {ReflectionCheckbox},
    props: {
        type: {
            type: String,
            default: 'toggle'
        },
        checkboxes: {
            type: Array
        },
        value: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            selectedCheckboxes: {}
        };
    },
    mounted() {
        this.resetSelectedCheckboxes();

        this.setSelectedCheckboxesFromValue(this.value);
    },
    methods: {
        setSelectedCheckboxesFromValue(values) {
            let valuesString = [];

            for (let value of values) {
                valuesString.push(parseInt(value, 10));
            }

            for (let i = 0, len = this.checkboxes.length; i < len; i++) {
                let checkbox = this.checkboxes[i];

                let checkboxValueString = checkbox.value;

                if (valuesString.includes(i)) {
                    this.$set(this.selectedCheckboxes, i, true);
                } else {
                    this.$set(this.selectedCheckboxes, i, false);
                }
            }
        },
        toggleCheckbox(i) {
            i = i.toString();

            this.$set(this.selectedCheckboxes, i, !this.selectedCheckboxes[i]);

            this.emitValues();
        },
        resetSelectedCheckboxes() {
            for (let i = 0, len = this.checkboxes.length; i < len; i++) {
                this.$set(this.selectedCheckboxes, i, false);
            }
        },
        emitValues() {
            let selectedValues = [];

            for (let key in this.selectedCheckboxes) {
                if (this.selectedCheckboxes[key]) {
                    selectedValues.push(parseInt(key, 10));
                }
            }

            this.$emit('input', selectedValues);
        },
    },
    watch: {
        checkboxes() {
            this.resetSelectedCheckboxes();
        },
        value() {
            this.setSelectedCheckboxesFromValue(this.value);
        }
    }
}
</script>
<style lang="scss" scoped>
</style>
