<template>
    <div :class="'summary-container-' + (isPrint ? 'summary-print' : type)" class="summary-container container">
        <div v-if="!isPrint" class="summary-header row">
            <div class="col summary-button-container text-right">
                <roskilde-button v-if="type === 'signs'" borderType="full" size="md" type="blue" widthType="fixed-small"
                                 v-on:click="startReflection">Start Refleksion
                </roskilde-button>
                <div v-else class="link-container-wrapper">
                    <div class="link-container">
                        <roskilde-button v-if="showSaveButtons" :disabled="!copyLink" borderType="full" class="mr-4" size="md" type="blue"
                                         widthType="fixed-small" v-on:click="savePdf">PDF
                        </roskilde-button>
                        <div class="copy-link-input-container">
                            <input ref="copyLink" :value="copyLink" class="copy-link-input" readonly="readonly"/>
                        </div>
                        <roskilde-button v-if="showSaveButtons" :disabled="!copyLink" borderType="full" size="md" type="blue"
                                         widthType="fixed-small" v-on:click="copyLinkToClipboard">Kopier link
                        </roskilde-button>

                        <help-box
                            v-if="showSaveButtons && showLinkHelp && !linkHelpClosed && resultBarHelpClosed"
                            iconSide="right"
                            style="right: 140px; top: 60px;"
                            text="Tryk her for at kopiere et link til din sammenfatning. Husk og gemme link et sted, så du kan vende tilbage."
                            v-on:submit="linkHelpClosed = true"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="childInfo" class="container" style="margin-top: -70px; margin-bottom: 20px;">
            <div class="row">
                <strong>Barnets navn:</strong> {{ childInfo.name }}
            </div>
            <div class="row">
                <strong>Dato:</strong> {{ childInfo.date }}
            </div>
        </div>
        <div v-if="debugScoresStatsJson">
            <pre>{{ debugScoresStatsJson }}</pre>
        </div>
        <div v-if="type === 'signs' || type === 'reflections'" class="summary-simple">
            <div v-if="signsQuestionThemes.length" class="summary-wrapper-signs page-break-always">
                <h4>Tegn</h4>
                <div class="summary-classification-row mb-3">
                    <div class="summary-classification-container">
                        <classifier :count="classifierCount.critical" type="critical"/>
                        <classifier :count="classifierCount.caution" type="caution"/>
                        <classifier :count="classifierCount.moderate" type="moderate"/>
                        <classifier :count="classifierCount.positive" type="positive"/>
                    </div>
                </div>
                <summary-theme
                    v-for="questionTheme in signsQuestionThemes"
                    v-if="signsQuestionThemes.length"
                    :key="questionTheme"
                    :rows="signsQuestionThemeRows[questionTheme]"
                    :theme="questionTheme"
                    type="signs"
                />
            </div>
            <div v-if="reflectionsQuestionThemes.length" class="summary-wrapper-reflections page-break-always">
                <h4>Refleksion</h4>
                <div class="summary-classification-row mb-3">
                    <div class="summary-classification-container">
                        <classifier :count="classifierCount.critical" type="critical"/>
                        <classifier :count="classifierCount.caution" type="caution"/>
                        <classifier :count="classifierCount.moderate" type="moderate"/>
                        <classifier :count="classifierCount.positive" type="positive"/>
                    </div>
                </div>
                <summary-theme
                    v-for="reflectionTheme in reflectionsQuestionThemes"
                    v-if="reflectionsQuestionThemes.length"
                    :key="reflectionTheme"
                    :rows="reflectionsQuestionThemeRows[reflectionTheme]"
                    :theme="reflectionTheme"
                    type="reflections"
                />
            </div>
        </div>
        <div v-else>
            <div class="summary-classification-row">
                <div class="summary-classification-container">
                    <classifier :count="classifierCount.critical" type="critical"/>
                    <classifier :count="classifierCount.caution" type="caution"/>
                    <classifier :count="classifierCount.moderate" type="moderate"/>
                    <classifier :count="classifierCount.positive" type="positive"/>
                </div>
            </div>
            <div class="summary-tabs">
                <div v-if="!isPrint" class="summary-tab-header row">
                    <div class="summary-tab-button-container col text-right">
                        <button :class="{ 'summary-tab-button-active': (summaryTab === 'answers') }"
                                class="summary-tab-button"
                                v-on:click="summaryTab = 'answers'"
                                v-text="'Tegn og Refleksion'"/>
                    </div>
                    <div class="summary-tab-button-container col text-left">
                        <button v-if="resultBarPickerValue >= 1"
                                :class="{ 'summary-tab-button-active': (summaryTab === 'conclusion') }"
                                class="summary-tab-button"
                                v-on:click="summaryTab = 'conclusion'"
                                v-text="'Vurdering og faglig sparring'"/>
                    </div>
                </div>
                <div v-if="isPrint || summaryTab === 'answers'" class="summary-tab">
                    <!--<div class="summary-wrapper row" v-if="summaryDescriptionMarkdown && type === 'summary'" v-html="summaryDescriptionMarkdown"></div>-->
                    <summary-box v-if="signsQuestionThemes.length" key="signs" :expandByDefault="expandByDefault"
                                 :show-toggle-button="!isPrint"
                                 class="summary-wrapper-signs page-break-always"
                                 title="Tegn">
                        <summary-theme
                            v-for="questionTheme in signsQuestionThemes"
                            v-if="signsQuestionThemes.length"
                            :key="questionTheme"
                            :rows="signsQuestionThemeRows[questionTheme]"
                            :theme="questionTheme"
                            type="signs"
                        />
                    </summary-box>
                    <summary-box v-if="reflectionsQuestionThemes.length" key="reflections"
                                 :expandByDefault="expandByDefault" :show-toggle-button="!isPrint" class="summary-wrapper-reflections  page-break-always"
                                 title="Refleksion">
                        <summary-theme
                            v-for="reflectionTheme in reflectionsQuestionThemes"
                            v-if="reflectionsQuestionThemes.length"
                            :key="reflectionTheme"
                            :rows="reflectionsQuestionThemeRows[reflectionTheme]"
                            :theme="reflectionTheme"
                            type="reflections"
                        />
                    </summary-box>
                </div>
                <div v-if="!isPrint && summaryTab === 'conclusion'" :style="isPrint ? { 'margin-top': '30px' } : {}"
                     class="summary-tab">
                    <summary-box key="conclusion" :expandByDefault="expandByDefault" :show-toggle-button="!isPrint"
                                 :title="conclusionTitle"
                                 class="summary-box-conclusion page-break-always">
                        <div class="summary-conclusion-text" v-html="conclusionTextMarkdown"/>

                        <div class="summary-tip-box" v-html="ageGroupTipsMarkdown"/>
                    </summary-box>
                    <summary-box :expandByDefault="expandByDefault" :show-toggle-button="!isPrint"
                                 class="summary-box-links page-break-always"
                                 title="Genveje til roskildemodellen.dk">
                        <div class="text-center links-container">
                            <p>Herunder er direkte link til sektioner på roskildemodellen.dk som kan underbygge din
                                vurdering</p>

                            <p>
                                <a href="https://www.roskildemodellen.dk/tvaerfagligt-samarbejde">
                                    <no-drag-image src="@/assets/buttons/btn-samarbejde.png"/>
                                </a>
                                <a v-if="resultBarPickerValue <= 6" href="https://www.roskildemodellen.dk/underretning">
                                    <no-drag-image src="@/assets/buttons/btn-underretning.png"/>
                                </a>
                                <a href="https://www.roskildemodellen.dk/viden-om">
                                    <no-drag-image src="@/assets/buttons/btn-viden-om.png"/>
                                </a>
                            </p>
                        </div>
                    </summary-box>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Classifier from './Classifier';
import RoskildeButton from '@/components/buttons/RoskildeButton.vue';
import SummaryTheme from './SummaryTheme';
import SummaryBox from './SummaryBox';
import HelpBox from '@/components/help-box';
import * as MutationTypes from '@/store/mutation-types'
import * as ScoreCalculator from '@/utils/scoreCalculator';
import copy from 'copy-to-clipboard';
import {savePdf} from "../../utils/pdfCreator";
import {getHostWithProtocol, getHostWithProtocolAndPath, isLocalHost} from "../../utils/various";
import slugify from "slugify";
import {SET_DEBUG_SCORES_STATS} from "../../store/mutation-types";

const axios = require('axios').default;

var markdown = require("markdown").markdown;

export default {
    name: 'summary-container',
    components: {
        SummaryBox,
        SummaryTheme,
        Classifier,
        RoskildeButton,
        HelpBox,
    },
    props: {
        title: {
            type: String
        },
        type: {
            type: String,
        },
        compressedCode: {
            type: String,
            default: ''
        },
        registrationAgeGroup: {
            type: String
        },
        registrationFunction: {
            type: String
        },
        signsQuestions: {
            type: Array,
            default() {
                return [];
            }
        },
        reflectionsQuestions: {
            type: Array,
            default() {
                return [];
            }
        },
        questionAnswers: {
            type: Object,
            default() {
                return {};
            }
        },
        expandByDefault: {
            type: Boolean,
            default: false,
        },
        showHelp: {
            type: Boolean,
            default: false,
        },
        showSaveButtons: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            signsQuestionThemeRows: {},
            signsQuestionThemes: [],

            reflectionsQuestionThemeRows: {},
            reflectionsQuestionThemes: [],

            classifierCount: {
                critical: 0,
                caution: 0,
                moderate: 0,
                positive: 0,
                unknown: 0,
            },

            totalScore: 0,

            summaryTab: 'answers',

            showLinkHelp: this.showHelp,
            linkHelpClosed: false,
        }
    },
    watch: {
        signsQuestions() {
            this.recalculateRows();
        },
        reflectionsQuestions() {
            this.recalculateRows();
        },
        questionAnswers() {
            this.recalculateRows();
        },
        resultBarPickerValue() {
            if (this.resultBarPickerValue === 0 && this.summaryTab === 'conclusion') {
                this.summaryTab = 'answers';
            }
        },
    },
    methods: {
        copyLinkToClipboard() {
            copy(this.copyLink);

            let input = this.$refs.copyLink;

            input.focus();
            input.select();

            this.$popup.open({
                text: 'Linket er nu kopieret til din udklipsholder'
            });

            this.$gaAnalytics.events.copiedLink();
        },
        startReflection() {
            this.$emit('startReflection');
        },
        recalculateRows() {
            console.log('signsQuestions', this.signsQuestions);

            let totalScore = 0;

            let signsQuestionThemeRows = {};
            let signsQuestionThemes = [];
            let reflectionsQuestionThemeRows = {};
            let reflectionsQuestionThemes = [];

            this.classifierCount.critical = 0;
            this.classifierCount.caution = 0;
            this.classifierCount.moderate = 0;
            this.classifierCount.positive = 0;
            this.classifierCount.unknown = 0;

            let questionsAssociated = {};

            for (let question of this.signsQuestions) {
                questionsAssociated[question.uid] = question;
            }

            for (let question of this.reflectionsQuestions) {
                questionsAssociated[question.uid] = question;
            }

            for (let question of this.signsQuestions) {
                let questionAnswerYesNoUid = ScoreCalculator.getAnswerUid(question.uid, 'signs', 'yesNo');
                let questionAnswerSliderUid = ScoreCalculator.getAnswerUid(question.uid, 'signs', 'slider');

                if (!this.questionAnswers.hasOwnProperty(questionAnswerYesNoUid)) {
                    //console.warn('Missing question answer for question with uid', questionAnswerYesNoUid);

                    continue;
                }

                let questionAnswerYesNo = this.questionAnswers[questionAnswerYesNoUid];
                let questionAnswerSlider = this.questionAnswers[questionAnswerSliderUid] || null;

                if (!questionAnswerYesNo) {
                    questionAnswerSlider = null;
                }

                let questionTheme = question.theme;
                let childQuestions = [];

                if (!signsQuestionThemeRows.hasOwnProperty(questionTheme)) {
                    signsQuestionThemeRows[questionTheme] = [];

                    signsQuestionThemes.push(questionTheme);
                }

                if (question.parent) {
                    continue; // It was already included automatically
                } else if (question.children) {
                    for (let childId of question.children) {
                        if (!questionsAssociated.hasOwnProperty(childId)) {
                            continue;
                        }

                        let childQuestion = questionsAssociated[childId];

                        let childQuestionAnswerYesNoUid = ScoreCalculator.getAnswerUid(childQuestion.uid, 'signs',
                            'yesNo');
                        let childQuestionAnswerSliderUid = ScoreCalculator.getAnswerUid(childQuestion.uid, 'signs',
                            'slider');

                        if (!this.questionAnswers.hasOwnProperty(childQuestionAnswerYesNoUid)) {
                            console.warn('Missing question answer for childQuestion with uid',
                                childQuestionAnswerYesNoUid);

                            continue;
                        }

                        let childQuestionAnswerYesNo = this.questionAnswers[childQuestionAnswerYesNoUid];
                        let childQuestionAnswerSlider = this.questionAnswers[childQuestionAnswerSliderUid] || null;

                        if (childQuestionAnswerYesNo) {
                            let childQuestionScore = ScoreCalculator.getSignScore(
                                childQuestion,
                                childQuestionAnswerYesNo,
                                childQuestionAnswerSlider
                            );

                            let childQuestionClassification = ScoreCalculator.getQuestionClassificationFromScore(
                                childQuestionScore,
                                childQuestionAnswerYesNo,
                                childQuestionAnswerSlider
                            );

                            this.classifierCount[childQuestionClassification || 'unknown']++;

                            childQuestions.push({
                                score: childQuestionScore,
                                classification: childQuestionClassification,
                                question: childQuestion,
                                answer: {
                                    yesNo: childQuestionAnswerYesNo,
                                    slider: childQuestionAnswerSlider,
                                }
                            });

                            totalScore += childQuestionScore;
                        }
                    }
                }

                let questionScore = ScoreCalculator.getSignScore(
                    question,
                    questionAnswerYesNo,
                    questionAnswerSlider
                );

                let questionClassification = ScoreCalculator.getQuestionClassificationFromScore(
                    questionScore,
                    questionAnswerYesNo,
                    questionAnswerSlider
                );

                this.classifierCount[questionClassification || 'unknown']++;

                signsQuestionThemeRows[questionTheme].push({
                    score: questionScore,
                    classification: questionClassification,
                    question: question,
                    childQuestions: childQuestions,
                    answer: {
                        yesNo: questionAnswerYesNo,
                        slider: questionAnswerSlider,
                    }
                });

                totalScore += questionScore;
            }

            totalScore = Math.floor(totalScore);

            let totalAnswers = 0;
            let totalAnswerSelected = 0;

            for (let question of this.reflectionsQuestions) {
                let questionAnswerUid = ScoreCalculator.getAnswerUid(question.uid, 'reflections');

                if (!this.questionAnswers.hasOwnProperty(questionAnswerUid)) {
                    console.warn('Missing question answer for question with uid', questionAnswerUid);

                    continue;
                }

                let questionAnswer = this.questionAnswers[questionAnswerUid];
                let questionTheme = question.theme;

                if (!reflectionsQuestionThemeRows.hasOwnProperty(questionTheme)) {
                    reflectionsQuestionThemeRows[questionTheme] = [];

                    reflectionsQuestionThemes.push(questionTheme);
                }

                let answerCount = question.answers.length;
                let answersSelected = questionAnswer.length;

                totalAnswers += answerCount;
                totalAnswerSelected += answersSelected;

                //let questionScore = ScoreCalculator.getReflectionScore(question, questionAnswer);

                reflectionsQuestionThemeRows[questionTheme].push({
                    classification: null,
                    question: question,
                    answer: questionAnswer
                });
            }

            this.classifierCount['positive'] += this.classifierCount['unknown'];

            let answerCountSelectedPercent = (totalAnswerSelected / totalAnswers) * 100;

            let thresholdBias = null;

            if (this.type !== 'signs') {
                if (answerCountSelectedPercent >= 80) {
                    thresholdBias = 1;
                }
                /*else if (answerCountSelectedPercent >= 60) {
                    thresholdBias = 0;
                }*/
                else {
                    thresholdBias = -1;
                }
            }

            let threatDisplay = true;

            if (this.type === 'signs') {
                threatDisplay = false;
            }

            this.signsQuestionThemeRows = signsQuestionThemeRows;
            this.signsQuestionThemes = signsQuestionThemes;
            this.reflectionsQuestionThemeRows = reflectionsQuestionThemeRows;
            this.reflectionsQuestionThemes = reflectionsQuestionThemes;
            this.totalScore = totalScore;

            let thresholds = ScoreCalculator.getScoreThresholds(this.signsQuestions, this.reflectionsQuestions);
            let thresholdScoreLevel = ScoreCalculator.getThresholdScoreLevel(thresholds.worst, thresholds.best, totalScore);
            let thresholdScoreLevelOriginal = thresholdScoreLevel;

            thresholdScoreLevel = Math.max(2, Math.min(9, thresholdScoreLevel)); // Clamp

            this.$store.commit(MutationTypes.SET_RESULT_BAR_THREAT_DISPLAY, threatDisplay);
            this.$store.commit(MutationTypes.SET_RESULT_BAR_THREAT_LEVEL, thresholdScoreLevel);
            this.$store.commit(MutationTypes.SET_RESULT_BAR_THREAT_BIAS, thresholdBias);
            this.$store.commit(MutationTypes.SET_RESULT_BAR_THREAT_DISPLAY, true);

            this.$store.commit(SET_DEBUG_SCORES_STATS, {
                totalAnswers: totalAnswers,
                totalAnswerSelected: totalAnswerSelected,
                thresholds: thresholds,
                thresholdScoreLevel: thresholdScoreLevel,
                totalScore: totalScore,
                thresholdScoreLevelOriginal: thresholdScoreLevelOriginal,
                thresholdBias: thresholdBias,
                answerCountSelectedPercent: answerCountSelectedPercent,
            });
        },
        savePdf() {
            let popupData = {};

            this.$popup.open({
                template: 'pdfGenerationStep1',
                data: {
                    childName: null,
                    date: new Date()
                },
            }).then((data) => {
                popupData.childName = data.selectedData.childName;
                popupData.date = data.selectedData.date;

                return this.$popup.open({
                    template: 'pdfGenerationStep2',
                });
            }).then((data) => {
                const slugifyOptions = {
                    lower: true,
                    trim: true,
                    locale: 'da',
                };

                const date = popupData.date || new Date();

                const dateString = [
                    popupData.date.getDate(),
                    popupData.date.getMonth() + 1,
                    popupData.date.getFullYear()
                ].join('.');

                const nameSlug = slugify(popupData.childName, slugifyOptions);
                const dateSlug = slugify(dateString, slugifyOptions);

                return this.$popup.open({
                    template: 'pdfGenerationStep3',
                    data: {
                        pdfName: `linealen-${nameSlug}-${dateSlug}.pdf`,
                    }
                });
            }).then((data) => {
                this.$popup.open({
                    template: 'pdfGenerationStep4',
                    choices: []
                });

                popupData.pdfName = data.selectedData.pdfName;

                if (!popupData.pdfName.match(/\.pdf$/i)) {
                    popupData.pdfName += '.pdf';
                }

                const date = popupData.date || new Date();

                const dateString = [
                    date.getDate(),
                    date.getMonth() + 1,
                    date.getFullYear()
                ].join('.');

                const postData = {
                    childName: popupData.childName,
                    date: dateString,
                };

                if (isLocalHost()) {
                    window.postData = postData;

                    this.$popup.close();

                    this.$router.push('/print/' + this.compressedCode);

                    return;
                }

                let url = getHostWithProtocolAndPath() + '#/print/' + this.compressedCode;

                console.log('postData', postData, 'url', url);

                savePdf({
                    url: url,
                    contentDisposition: popupData.pdfName,
                    postData: postData
                }).then((pdfUrl, savedFile) => {
                    console.log('Closing popup!');

                    this.$gaAnalytics.events.downloadedPdf();

                    this.$popup.close();

                    if (!savedFile) {
                        // We can't actually download the file, so let's just open up the PDF directly
                        window.location.href = pdfUrl;
                    }
                }).catch((e) => {
                    console.log('Could not save PDF:', e);

                    this.$popup.close();

                    alert('Kunne ikke gemme PDF: ' + e.toString());
                })
            })
        }
    },
    mounted() {
        this.recalculateRows();
    },
    computed: {
        ...mapGetters({
            appData: 'getAppData',
            resultBarPickerValue: 'getResultBarPickerValue',
            resultBarHelpClosed: 'getResultBarHelpClosed',
            debugScores: 'getDebugScores',
            debugScoresStats: 'getDebugScoresStats',
        }),
        debugScoresStatsJson() {
            if (!this.debugScores) {
                return null;
            }

            if (!this.debugScoresStats) {
                return null;
            }

            return JSON.stringify(this.debugScoresStats, null, '\t');
        },
        childInfo() {
            if (this.isPrint && window.postData) {
                return {
                    name: window.postData.childName,
                    date: window.postData.date,
                }
            }

            return null;
        },
        summaryDescriptionMarkdown() {
            let resultLevelKey;
            let ageGroup = this.registrationAgeGroup;

            if (this.resultBarThreatLevel >= 8) {
                resultLevelKey = 'positiveText';
            } else if (this.resultBarThreatLevel >= 4) {
                resultLevelKey = 'moderateText';
            } else {
                resultLevelKey = 'criticalText';
            }

            if (this.appData && this.appData.summaryTextMatrix && this.appData.summaryTextMatrix.hasOwnProperty(
                ageGroup)) {
                if (this.appData.summaryTextMatrix[ageGroup].hasOwnProperty(resultLevelKey)) {
                    return markdown.toHTML(this.appData.summaryTextMatrix[ageGroup][resultLevelKey]);
                }
            }


        },
        isPrint() {
            return this.type === 'summary:print';
        },
        copyLink() {
            if (!this.compressedCode) {
                return null;
            }

            return window.location.protocol + '//' + window.location.host + '/#/r/' + this.compressedCode;
        },
        conclusionTitle() {
            if (!this.conclusion) {
                return null;
            }

            return this.conclusion.title;
        },
        conclusionTextMarkdown() {
            if (!this.conclusion) {
                return null;
            }

            return markdown.toHTML(this.conclusion.text);
        },
        conclusionSubText() {
            if (!this.conclusion) {
                return null;
            }

            return this.conclusion.subText;
        },
        ageGroupTipsMarkdown() {
            let ageGroup = this.registrationAgeGroup;

            if (!ageGroup) {
                return null;
            }

            let _function = this.registrationFunction;

            console.log('function', _function);

            if (!_function) {
                return null;
            }

            if (!this.appData.offerAgeFunctionsMatrix.offerReverse.hasOwnProperty(_function)) {
                return null;
            }

            let markdownLines = [];

            markdownLines.push(this.conclusionSubText);
            markdownLines.push('');

            let ageGroupSplit = ageGroup.split(/\s*\-\s*/);
            let ageGroupMin = parseInt(ageGroupSplit[0], 10);
            let ageGroupMax = parseInt(ageGroupSplit[1], 10);

            //console.log('ageGroup', ageGroup, ageGroupMin, ageGroupMax);

            for (let offer of this.appData.offerAgeFunctionsMatrix.offerReverse[_function]) {
                if (offer.ageRange) {
                    let inRange = false;

                    if (ageGroupMin >= offer.ageRange.min && ageGroupMin <= offer.ageRange.max) {
                        inRange = true;
                    } else if (ageGroupMax >= offer.ageRange.min && ageGroupMax <= offer.ageRange.max) {
                        inRange = true;
                    }

                    //console.log('function.' + _function + '.offer.ageRange', offer.ageRange, inRange);

                    if (!inRange) {
                        continue;
                    }
                } else {
                    //console.log('function.' + _function + '.offer.ageRange.empty');
                }

                markdownLines.push(' - ' + this.appData.offers[offer.type]);
            }

            //console.log('markdownLines', markdownLines);

            return markdown.toHTML(markdownLines.join('\r\n'));
        },
        conclusion() {
            let barValue = this.resultBarPickerValue;

            let key = null;

            if (barValue <= 2) {
                key = '1-2';
            } else if (barValue <= 4) {
                key = '3-4';
            } else if (barValue <= 6) {
                key = '5-6';
            } else if (barValue <= 8) {
                key = '7-8';
            } else {
                key = '9-10';
            }

            if (!this.appData.summaryBarResult.hasOwnProperty(key)) {
                return null;
            }

            return this.appData.summaryBarResult[key];
        }
    }
};
</script>

<style lang="scss" scoped>
.summary-container {
    padding-top: 50px;
}

.summary-classification-container {
    text-align: left;
}

.summary-button-container {
    text-align: right;
}

h4 {
    font-weight: bold;
    font-size: 30px;
    color: $gray-dark;
    text-align: center;
}

.link-container {
    label {
        margin-right: 10px;
    }
}

.summary-wrapper-reflections,
.summary-box-links {
    margin-top: 30px;
}

.summary-container {
    .link-container-wrapper {
        padding-right: 0px !important;
    }
}

.link-container-wrapper {
    position: relative;

    height: 49px;
    padding-right: 190px;

    button {
        position: absolute;
        top: 0;
        right: 0;
    }

    .link-container {

    }

    input {
        float: right;
        border: 0;
        outline: 0;
        width: 100%;
        height: 49px;
        text-align: right;

        &:focus {
            border: 0;
            outline: 0;
        }
    }

}

.summary-wrapper {
    max-width: 50%;

    p, ul {
        display: block;
        width: 100%;
    }
}

.summary-box {
    .summary-theme {
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}

.summary-tab-header {
    margin-bottom: 0;
    text-align: center;

    .summary-tab-button {
        outline: 0;
        border: 0;
        background: $gray-light;
        border-radius: 10px 10px 0px 0px;
        margin-right: 20px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        padding: 10px;
        min-width: 300px;
        height: 100%;

        &:last-child {
            margin-right: 0;
        }

        &.summary-tab-button-active {
            background: $white-almost;
        }
    }
}

.summary-tabs {
    margin-top: 50px;
}

@media only screen and (max-width: 800px) {
    .summary-tab-header {
        .summary-tab-button {
            min-width: auto;
        }
    }
}

.summary-box-conclusion {
    p {
        padding: 15px;
        line-height: 140%;
    }

    .summary-tip-box {
        background: $gray-lighter;
        border-radius: 4px;
        padding: 15px;
    }
}

.links-container {
    padding-top: 20px;

    a {
        margin: 5px;

        img {
            transition-property: all;
            transition-duration: 300ms;
        }

        &:hover {
            img {
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
            }
        }
    }
}

.headline-container {
    width: 100%;
    position: absolute;
}

.summary-header {
    margin-top: -65px;
}

.summary-container-summary {
    .summary-classification-row {
        margin-top: -35px;
    }
}

@media (max-width: 1200px) {
  .summary-classification-row {
    padding-top: 50px !important;
  }
}

.summary-conclusion-text {
    &, p {
        text-align: center;
    }
}

.copy-link-input-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;

    .copy-link-input {
        position: absolute;
        left: -1000px;
        top: -1000px;
        width: 1px;
        height: 1px;
        display: block;
    }
}
</style>
<style lang="scss">

.summary-wrapper {
    h1, h2, h3, h4 {
        font-weight: bold !important;
        font-size: 30px !important;
        color: #4b4b4b !important;
        text-align: center !important;
    }

    p {
        font-size: 18px;
        line-height: 22px;
        font-weight: 500;
    }
}

.summary-container-signs,
.summary-container-reflections {
    .summary-theme {
        margin-bottom: 50px;

        .summary-row-container {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.page-break-always {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
}
</style>
