<template>
    <div :class="{'overview-bar-container-with-title': showTitle}" class="overview-bar-container">
        <h4 v-if="showTitle" class="header-title">Linealen</h4>

        <div :class="coloured ? 'overview-bar-coloured' : 'overview-bar-greyscale'" class="overview-bar">
			<span :class="active === 'signs' ? 'overview-bar-segment-active' : ''"
                  class="overview-bar-segment overview-bar-signs"
            >
				Tegn
				<span v-if="currentSignIndex !== null && currentSignIndex < 1000 && totalSignIndexes > 0"
                      class="count-container">
					<span class="count-current">{{ currentSignIndex + 1 }}</span>
					<span class="count-total">{{ totalSignIndexes }}</span>
				</span>
			</span>

            <span :class="active === 'reflections' ? 'overview-bar-segment-active' : ''"
                  class="overview-bar-segment overview-bar-reflection"
            >
				Refleksion
				<span v-if="currentReflectionIndex !== null && currentReflectionIndex < 1000 && totalReflectionIndexes > 0"
                      class="count-container">
					<span class="count-current">{{ currentReflectionIndex + 1 }}</span>
					<span class="count-total">{{ totalReflectionIndexes }}</span>
				</span>
			</span>

            <span :class="active === 'summary' ? 'overview-bar-segment-active' : ''"
                  class="overview-bar-segment overview-bar-summary"
                  v-text="'Sammenfatning'"/>
        </div>

        <div v-if="showTitle" class="header-logo" @click="onLogoClick">
            <no-drag-image class="logo-large" src="@/assets/logo.png"/>
            <no-drag-image class="logo-small" src="@/assets/logo-small.png"/>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {SET_DEBUG_SCORES} from "../../store/mutation-types";

export default {
    name: 'overview-bar',
    props: {
        showTitle: {
            type: Boolean,
            default: false
        },
        coloured: {
            type: Boolean,
            default: false
        },
        active: {
            type: String,
            default: null
        }
    },
    methods: {
        onClick() {
            this.$emit('click');
        },
        onLogoClick() {
            this.$store.commit(SET_DEBUG_SCORES, !this.debugScores)
        }
    },
    computed: {
        ...mapGetters({
            debugScores: 'getDebugScores',

            currentSignIndex: 'getCurrentSignIndex',
            totalSignIndexes: 'getTotalSignIndexes',

            currentReflectionIndex: 'getCurrentReflectionIndex',
            totalReflectionIndexes: 'getTotalReflectionIndexes',
        })
    }
}
</script>

<style lang="scss" scoped>
$width: 211px;
$height: 43px;
$scaleSm: .6;

.overview-bar-container {
    position: relative;
}

.header-title {
    position: absolute;
    top: 3px;
    left: 15px;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
}

.header-logo {
    position: absolute;
    top: 3px;
    right: 15px;

    img {
        max-height: $height;
    }
}

.logo-small {
    display: none;
}

@media only screen and (max-width: 1160px) {
    .logo-large {
        display: none;
    }

    .logo-small {
        display: block;
    }

    .overview-bar-container-with-title {
        height: $height;

        .overview-bar {
            text-align: right !important;
            width: auto;
            position: absolute;
            right: 70px;
        }
    }
}

@media only screen and (max-width: 930px) {
    .overview-bar-container-with-title {
        .header-title {
            max-width: 100px;
            word-break: break-word;
            font-size: 15px;
        }
    }
}

@media only screen and (max-width: 800px) {
    .header-logo {
        position: absolute;
        top: 3px;
        right: 15px;

        img {
            max-height: $height * $scaleSm;
        }
    }

    .overview-bar-container-with-title {
        height: $height * $scaleSm;

        .header-title {
            top: -2px;
        }

        .overview-bar {
            top: 5px;
        }

        .overview-bar-segment {
            width: $width * $scaleSm !important;
            height: $height * $scaleSm !important;
            background-size: ($width * $scaleSm) ($height * $scaleSm) !important;
            line-height: $height * $scaleSm !important;
        }
    }
}

.overview-bar {
    position: relative;
    text-align: center;
    white-space: nowrap;
}

.overview-bar-segment {
    position: relative;
    width: $width;
    height: $height;
    background-size: $width $height;
    display: inline-block;
    background-repeat: no-repeat;
    line-height: $height;
    color: $gray;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 2px;
}

.overview-bar-reflection, .overview-bar-summary {
    margin-left: -6px;
}

.overview-bar-coloured {
    .overview-bar-segment {
        color: $white;
        font-weight: bold;
    }

    .overview-bar-signs {
        background-image: url("~@/assets/components/overview-bar/overview-bar-color-left.png");
    }

    .overview-bar-reflection {
        background-image: url("~@/assets/components/overview-bar/overview-bar-color-middle.png");
    }

    .overview-bar-summary {
        background-image: url("~@/assets/components/overview-bar/overview-bar-color-right.png");
    }
}

.overview-bar-greyscale {
    .overview-bar-segment-active {
        color: $white;
        font-weight: bold;
    }

    .overview-bar-signs {
        background-image: url("~@/assets/components/overview-bar/overview-bar-greyscale-left.png");

        &.overview-bar-segment-active {
            background-image: url("~@/assets/components/overview-bar/overview-bar-greyscale-left-active.png");
        }
    }

    .overview-bar-reflection {
        background-image: url("~@/assets/components/overview-bar/overview-bar-greyscale-middle.png");

        &.overview-bar-segment-active {
            background-image: url("~@/assets/components/overview-bar/overview-bar-greyscale-middle-active.png");
        }
    }

    .overview-bar-summary {
        background-image: url("~@/assets/components/overview-bar/overview-bar-greyscale-right.png");

        &.overview-bar-segment-active {
            background-image: url("~@/assets/components/overview-bar/overview-bar-greyscale-right-active.png");
        }
    }
}

.count-container {
    position: absolute;
    right: 20px;

    .count-current {
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        color: #ffffff;
    }

    .count-total {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        color: #BBBBBB;

        &:before {
            content: "/";
        }
    }
}
</style>
