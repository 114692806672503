import * as DataClasses from './dataClasses';

/**
 * Bit lengths
 *   InitBit: 1
 *   Version: 3
 *   ResultBarPickerValue: 4
 *   AgeGroup: 4
 *   Reflection: 7
 *   SignWithSlider: 7
 *   Sign: 1
 */
export class DataStructure {
    /**
     * @return int
     */
    getBitLength() {
        throw new Error('getBitLength must be overwritten');
    }

    /**
     * @return class
     */
    getDataClass() {
        throw new Error('getDataClass must be overwritten');
    }

    getType() {
        if (this instanceof InitBit) {
            return 'InitBit';
        } else if (this instanceof Version) {
            return 'Version';
        } else if (this instanceof ResultBarPickerValue) {
            return 'ResultBarPickerValue';
        } else if (this instanceof AgeGroup) {
            return 'AgeGroup';
        } else if (this instanceof Reflection) {
            return 'Reflection';
        } else if (this instanceof SignWithSlider) {
            return 'SignWithSlider';
        } else if (this instanceof Sign) {
            return 'Sign';
        }

        return 'Unknown type';
    }

    /**
     * @param DataClasses.DataClass
     *
     * @return string
     */
    compress(dataClass) {
        let value = dataClass.getValue();
        let valueToString = value.toString(2);
        let bitLength = this.getBitLength();

        if (valueToString.length > bitLength) {
            throw new Error('Value for ' + this + ' was longer than ' + bitLength + ' as it was ' + valueToString.length + ' (' + valueToString + ')');
        }

        return valueToString.padStart(bitLength, '0');
    }

    /**
     * @param string
     *
     * @return DataClasses.DataClass
     */
    decompress(binaryString) {
        let dataClass = this.getDataClass();

        return new dataClass(parseInt(binaryString, 2));
    }
}

export class InitBit extends DataStructure {
    /**
     * A class representing the very first "1" in the binary string
     *
     * Bit meaning: Always 1; used to avoid trimming 0's in the start of the string
     *
     * Example:
     *   1
     */

    getBitLength() {
        return 1;
    }

    getDataClass() {
        return DataClasses.InitBit;
    }

    /**
     * @param string
     *
     * @return DataClasses.DataClass
     */
    decompress(binaryString) {
        return new DataClasses.InitBit(1);
    }
}

export class Version extends DataStructure {
    /**
     * A class representing the version of the data in case it ever gets updated
     *
     * Bit meaning: The literal binary string converted to decimal
     *
     * Example:
     *   0 1 0
     *     |
     *     \-- A literal binary representation of the version, in this case the version is "2"
     */

    getBitLength() {
        return 3;
    }

    getDataClass() {
        return DataClasses.Version;
    }
}

export class ResultBarPickerValue extends DataStructure {
    /**
     * A class representing the answer of the age group
     *
     * Bit meaning: The literal binary string converted to decimal
     *
     * Example:
     *   0 1 0 0
     *     |
     *     \-- A literal binary representation of the slider value - in this case the slider value is "4"
     */

    getBitLength() {
        return 4;
    }

    getDataClass() {
        return DataClasses.ResultBarPickerValue;
    }
}

export class AgeGroup extends DataStructure {
    /**
     * A class representing the answer of the age group
     *
     * Bit meaning: The literal binary string converted to decimal
     *
     * Example:
     *   0 1 0 0
     *     |
     *     \-- A literal binary representation of the age group index, in this case the index is "4" is "6 - 9"
     */

    getBitLength() {
        return 4;
    }

    getDataClass() {
        return DataClasses.AgeGroup;
    }
}

export class Reflection extends DataStructure {
    /**
     * A class representing the answer of a Reflection (checkboxes) where every bit means selected/not selected
     *
     * Bit meaning: Indexes are selected/deselected
     *
     * Example:
     *   0 0 0 1 1 0 0
     *         | |
     *         \-\-- Answer 4 and 5 are selected, the rest are not
     */

    getBitLength() {
        return 7;
    }

    getDataClass() {
        return DataClasses.Reflection;
    }
}

export class SignWithSlider extends DataStructure {
    /**
     * A class representing the answer of a Reflection (checkboxes)
     *
     * Bit meaning: The first bit is the yes/no answer, the next 3 are for the "often" answer and the next 3 are for the "worry" answer
     *
     * Example:
     *   1 0 0 1 1 0 0
     *   | | | | | | |
     *   | | | | \-\-\-- The index of the worry answer - 0 being the first answer and 5 being the last one
     *   | | | |
     *   | \-\-\-- The index of the often answer - 0 being the first answer and 5 being the last one
     *   |
     *   \-- The value of the initial question - usually 1
     */

    getBitLength() {
        return 7; // 1 bit for value, 3 bits for often index, 3 bits for worry index
    }

    getDataClass() {
        return DataClasses.SignWithSlider;
    }

    /**
     * @param DataClasses.DataClass
     *
     * @return string
     */
    compress(dataClass) {
        let oftenIndexBinary = dataClass.oftenIndex.toString(2).padStart(3, '0');
        let worryIndexBinary = dataClass.worryIndex.toString(2).padStart(3, '0');

        if (oftenIndexBinary.length > 3) {
            throw new Error('Value for ' + this + '.oftenIndexBinary was longer than 3 as it was ' + oftenIndexBinary.length + ' (' + oftenIndexBinary + ')');
        }

        if (worryIndexBinary.length > 3) {
            throw new Error('Value for ' + this + '-worryIndexBinary was longer than 3 as it was ' + worryIndexBinary.length + ' (' + worryIndexBinary + ')');
        }

        let binaryString = (dataClass.value ? '1' : '0') + oftenIndexBinary + worryIndexBinary;

        return binaryString;
    }

    /**
     * @param string
     *
     * @return DataClasses.DataClass
     */
    decompress(binaryString) {
        let value = parseInt(binaryString[0], 10);
        let oftenIndex = parseInt(binaryString.substr(1, 3), 2);
        let worryIndex = parseInt(binaryString.substr(4, 3), 2);

        /*console.log({
            binaryString: binaryString,
            value: value,
            oftenIndex: oftenIndex,
            worryIndex: worryIndex
        });*/

        return new DataClasses.SignWithSlider(value, oftenIndex, worryIndex);
    }
}

export class Sign extends DataStructure {
    /**
     * A class representing the answer of a Reflection (checkboxes)
     *
     * Bit meaning: The first bit is the yes/no answer
     *
     * Example:
     *   1
     *   |
     *   \-- The "yes/no" answer - 1 for yes and 0 for no
     */

    getBitLength() {
        return 1;
    }

    getDataClass() {
        return DataClasses.Sign;
    }
}

export class Theme extends DataStructure {
    /**
     * A class representing the answer of whether a theme should be included (checkboxes)
     *
     * Bit meaning: The first bit is whether or not to include this theme
     *
     * Example:
     *   1
     *   |
     *   \-- The "include theme" answer - 1 for include, 0 for do not include
     */

    getBitLength() {
        return 1;
    }

    getDataClass() {
        return DataClasses.Theme;
    }
}