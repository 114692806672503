export function isLocalHost() {
    const host = window.location.host;

    return host.match(/^localhost/) || host.match(/^127\./) || host.match(/^192\.168\./);
}

export function getHostWithProtocol() {
    return window.location.protocol + '//' + window.location.host;
}

export function getHostWithProtocolAndPath() {
    return getHostWithProtocol() + (window.location.path || '/');
}