<template>
    <div class="summary-container">
        <summary-container :compressedCode="compressedCode" :expandByDefault="true"
                           :questionAnswers="questionAnswers" :reflectionsQuestions="reflectionsQuestions"
                           :registrationAgeGroup="registrationAgeGroup"
                           :registrationFunction="registrationFunction"
                           :signsQuestions="questions"
                           :showSaveButtons="showSaveButtons"
                           type="summary"/>
    </div>
</template>

<script>
import RoskildeButton from '@/components/buttons/RoskildeButton.vue';
import * as MutationTypes from '@/store/mutation-types'
import {mapGetters} from 'vuex';
import SummaryContainer from '@/components/summary/SummaryContainer.vue';
import config from '@/config';
import {getAllQuestionsInfo, getFilteredQuestions} from "../../utils/signQuestionCalculator";

const axios = require('axios').default;

export default {
    name: 'single-summary-view',
    components: {
        RoskildeButton,
        SummaryContainer,
    },
    beforeMount() {
    },
    mounted() {
        this.$store.commit(MutationTypes.SET_EXTERNAL_LINK, null);
        this.$store.commit(MutationTypes.SET_OVERVIEW_BAR_SHOW, true);
        this.$store.commit(MutationTypes.SET_RESULT_BAR_SHOW, true);
        this.$store.commit(MutationTypes.SET_OVERVIEW_BAR_ACTIVE_STEP, 'summary');
        this.$store.commit(MutationTypes.SET_RESULT_BAR_WITH_PICKER, true);
        this.$store.commit(MutationTypes.SET_RESULT_BAR_INTERACTIVE, false);
        this.$store.commit(MutationTypes.SET_RESULT_BAR_THREAT_DISPLAY, true);
        this.$store.commit(MutationTypes.SET_RESULT_BAR_HELP_DISPLAY, false);

        if (this.compressedCode) {
            axios.get(config.apiBaseUrl + '/load?code=' + this.compressedCode).then((response) => {
                this.$nextTick(() => {
                    if (!response.data.success) {
                        alert(response.data.error);

                        return;
                    }

                    //let data = DataCompressor.decompressFromData(this.compressedCode);
                    let data = response.data.data;

                    console.log('data', data);

                    let questionAnswers = data.questionAnswers;
                    let registrationAgeGroup = data.registrationAgeGroup;
                    let registrationFunction = data.registrationFunction;
                    let signQuestionUids = [];

                    for (let key in questionAnswers) {
                        const questionId = parseInt(key.match(/^\d+/)[0], 10);
                        const questionUid = questionId + '.signs';

                        if (!signQuestionUids.includes(questionUid)) {
                            signQuestionUids.push(questionUid);
                        }
                    }

                    console.log('signQuestionUids', signQuestionUids);

                    // Calc signs
                    let signsQuestions = [];
                    let signs = this.appData.signs;
                    let registrationThemes = [];

                    if (signs.hasOwnProperty(registrationAgeGroup)) {
                        let signsAgeGroup = signs[registrationAgeGroup];

                        for (let questionUid of signsAgeGroup['questionUids']) {
                            if (!signQuestionUids.includes(questionUid)) {
                                continue;
                            }

                            let questionUidYesNo = questionUid + '.yesNo';

                            if (!questionAnswers.hasOwnProperty(questionUidYesNo)) {
                                continue;
                            }

                            const signQuestion = signsAgeGroup['questions'][questionUid];
                            const signTheme = signQuestion.theme;

                            if (!registrationThemes.includes(signTheme)) {
                                registrationThemes.push(signTheme);
                            }

                            signsQuestions.push(signQuestion);
                        }
                    } else {
                        console.error('Could not find signs for age group', registrationAgeGroup);
                    }

                    console.log('registrationThemes', registrationThemes);

                    console.log('signs', signs);

                    // Calc reflections
                    let reflectionsQuestions = [];
                    let reflections = this.appData.reflections;

                    if (reflections.hasOwnProperty(registrationAgeGroup)) {
                        let reflectionsAgeGroup = reflections[registrationAgeGroup];

                        for (let reflectionUid of reflectionsAgeGroup['reflectionUids']) {
                            reflectionsQuestions.push(reflectionsAgeGroup['reflections'][reflectionUid]);
                        }
                    } else {
                        console.error('Could not find reflections for age group', registrationAgeGroup);
                    }

                    console.log('signsQuestions', signsQuestions);

                    this.questionAnswers = questionAnswers;
                    this.registrationAgeGroup = registrationAgeGroup;
                    this.registrationFunction = registrationFunction;
                    this.signsQuestions = signsQuestions;
                    this.reflectionsQuestions = reflectionsQuestions;

                    let allQuestionsInfo = getAllQuestionsInfo(
                        this.appData.signs,
                        this.registrationAgeGroup,
                        registrationThemes
                    );

                    console.log('registrationThemes', registrationThemes, allQuestionsInfo);

                    this.allQuestionUids = allQuestionsInfo.questionUids;
                    this.allQuestions = allQuestionsInfo.questions;

                    this.recalculateQuestions();

                    this.$store.commit(MutationTypes.SET_RESULT_BAR_PICKER_VALUE, 0);
                    this.$store.commit(MutationTypes.SET_REGISTRATION_AGE_GROUP, registrationAgeGroup);
                    this.$store.commit(MutationTypes.SET_REGISTRATION_FUNCTION, registrationFunction);

                    this.$nextTick(() => {
                        this.$store.commit(MutationTypes.SET_RESULT_BAR_PICKER_VALUE, data.resultBarPickerValue);

                        this.showSaveButtons = true;
                    });
                });
            });
        }
    },
    methods: {
        recalculateQuestions() {
            this.questions = getFilteredQuestions(this.allQuestionUids, this.allQuestions, this.questionAnswers);
        },
    },
    data() {
        return {
            registrationAgeGroup: null,
            registrationFunction: null,
            reflectionsQuestions: [],
            questionAnswers: {},

            allQuestionUids: [],
            allQuestions: {},

            questions: [],

            showSaveButtons: false,
        };
    },
    computed: {
        ...mapGetters({
            appData: 'getAppData',
        }),
        compressedCode() {
            return this.$route.params.code || null;
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
