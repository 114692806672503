<template>
    <div v-if="isOpen" class="help-box-container">
        <no-drag-image :class="'help-box-icon-' + iconSide" class="help-box-icon" src="@/assets/icons/info_icon.png"/>
        <div class="help-box">
            <p>{{ text }}</p>
            <div class="help-box-footer">
                <a href="#" v-on:click.prevent="clickSubmit">{{ submitText }}</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'help-box',
    props: {
        text: {
            type: String,
            default: 'Træk markøren på Linealen for at angive din vurdering'
        },
        submitText: {
            type: String,
            default: 'OK'
        },
        iconSide: {
            type: String,
            default: 'left'
        }
    },
    data() {
        return {
            isOpen: true
        }
    },
    methods: {
        clickSubmit() {
            this.isOpen = false;
            this.$emit('submit');
        }
    }
}
</script>

<style lang="scss" scoped>
.help-box-container {
    position: absolute;
    z-index: 10000;
}

.help-box {
    position: relative;

    background: #FFFFFF;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    text-align: left;

    padding: 10px;
    width: auto;
    overflow: hidden;
    padding-bottom: 20px;
    max-width: 230px;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #262626;
    line-height: 160%;
}

.help-box-footer {
    position: absolute;
    height: 31px;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 3px 10px;
    text-align: right;

    a {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: #4B4B4B;

        &:hover {
            text-decoration: underline;
        }
    }

    background: #F9F9F9;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 8px 8px;

}

.help-box-icon {
    position: absolute;
    z-index: 1000;

    &.help-box-icon-left {
        left: -14.5px;
        top: -14.5px;
    }

    &.help-box-icon-right {
        right: -14.5px;
        top: -14.5px;
    }
}
</style>