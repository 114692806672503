export class ConsoleLogger {
	category = null
	categoryStyling = null
	defaultStyling = null
	disable = false;

	constructor(category, categoryStyling, defaultStyling = null, disable = false) {
		this.category = category
		this.categoryStyling = categoryStyling
		this.defaultStyling = defaultStyling || 'background: normal; color: normal'
		this.disable = disable;
	}

	logMessage(message) {
		if (this.disable) {
			return;
		}

		console.log(
			'%c[' + this.category + ']%c ' + message,
			this.categoryStyling,
			this.defaultStyling
		)
	}
}