<template>
    <div class="summary-theme">
        <h5>{{ theme }}</h5>
        <p v-if="themeDescription">{{ themeDescription }}</p>
        <div v-if="type === 'signs'" class="summary-theme-signs">
            <summary-row-sign
                v-for="row in calcRows"
                :key="row.question.uid"
                :row="row"
                :type="type"
            />
        </div>
        <div v-else-if="type === 'reflections'" class="summary-theme-reflections">
            <summary-row-reflection
                v-for="row in calcRows"
                :key="row.question.uid"
                :row="row"
                :type="type"
            />
        </div>
    </div>
</template>

<script>
import SummaryRowReflection from './SummaryRowReflection';
import SummaryRowSign from './SummaryRowSign';
import {mapGetters} from 'vuex';

export default {
    name: 'summary-theme',
    components: {
        SummaryRowReflection,
        SummaryRowSign
    },
    props: {
        type: {
            type: String
        },
        rows: {
            type: Array,
        },
        theme: {
            type: String
        },
    },
    data() {
        return {
            calcRows: []
        }
    },
    mounted() {
        this.computeCalcRows();
    },
    watch: {
        rows() {
            this.computeCalcRows();
        },
    },
    methods: {
        getClassificationScore(classification) {
            let baseScore = 0;

            if (classification === 'positive') {
                baseScore = 0;
            } else if (classification === 'moderate') {
                baseScore = 1000;
            } else if (classification === 'caution') {
                baseScore = 1500;
            } else if (classification === 'critical') {
                baseScore = 2000;
            } else {
                baseScore = 3000; // Unknown ?!
            }

            return baseScore;
        },
        computeCalcRows() {
            let calcRows = [];
            let i = 0;

            for (let row of this.rows) {
                row.index = i++;
                row.classification = row.classification || 'positive';
                row.classificationScore = this.getClassificationScore(row.classification) + i;

                calcRows.push(row);
            }

            calcRows.sort((a, b) => {
                if (a.classificationScore < b.classificationScore) {
                    return -1;
                } else if (a.classificationScore > b.classificationScore) {
                    return 1;
                } else {
                    return 0;
                }
            });

            console.log('calcRows', calcRows);

            this.calcRows = calcRows;
        }
    },
    computed: {
        ...mapGetters({
            appData: 'getAppData',
            registrationAgeGroup: 'getRegistrationAgeGroup',
            registrationFunction: 'getRegistrationFunction',
        }),
        themeDescription() {
            if (!this.appData.themeDescriptions.hasOwnProperty(this.registrationAgeGroup)) {
                return;
            }

            let themeDescriptions = this.appData.themeDescriptions[this.registrationAgeGroup];

            if (!themeDescriptions.hasOwnProperty(this.theme)) {
                return;
            }

            return themeDescriptions[this.theme];
        }
    }
};
</script>

<style lang="scss" scoped>
h5 {
    text-align: left;
}
</style>
