<template>
    <div :class="classifierBallClass" :title="typeLabel" class="classifier-ball">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'classifier-ball',
    props: {
        type: {
            type: String,
            default: 'unknown'
        },
        size: {
            type: String,
            default: 'lg'
        }
    },
    computed: {
        classifierBallClass() {
            let type;

            if (this.type === undefined || this.type === null || this.type === false) {
                type = 'unknown';
            } else {
                type = this.type;
            }

            return [
                'classifier-ball-' + type,
                'classifier-ball-size-' + this.size
            ];
        },
        typeLabel() {
            switch (this.type) {
                case 'critical':
                    return 'Meget bekymret';
                case 'caution':
                    return 'Moderat bekymret';
                case 'moderate':
                    return 'Begyndende bekymret';
                case 'positive':
                    return 'Slet ikke bekymret';
            }

            return 'Nej';
        }
    }
};
</script>

<style lang="scss" scoped>
.classifier-ball {
    background: black;
    display: inline-block;
    margin-right: 5px;
    color: white;
    text-align: center;

    // Types
    &.classifier-ball-critical {
        background: $classifier-red;
    }

    &.classifier-ball-caution {
        background: $classifier-orange;
    }

    &.classifier-ball-moderate {
        background: $classifier-yellow;
    }

    &.classifier-ball-positive {
        background: $classifier-green;
    }

    &.classifier-ball-unknown {
        background: $classifier-green;
    }

    // Sizes
    &.classifier-ball-size-lg {
        border-radius: 12px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 14px;
        font-weight: bold;
    }

    &.classifier-ball-size-sm {
        border-radius: 5px;
        width: 10px;
        height: 10px;
        line-height: 10px;
        font-size: 10px;
        font-weight: bold;
    }
}
</style>
