<template>
    <vue-cookie-toggler
        :cookies-groups="calculatedCookieGroups"
        title="Du bestemmer over dine data"
        settings-title="Cookie indstillinger"
        settings-label="Cookie indstillinger"
        accept-label="Accepter alle"
        save-label="Gem indstillinger"
        cancel-label="Annuller"
        ref="vueCookieToggler"
    >
        Cookies bliver brugt til at indsamle oplysninger om, hvordan brugere anvender hjemmesiden.<br/>
        <br/>
        Ved at trykke på 'Accepter alle' giver du samtykke til alle formål. Du kan også vælge at tilkendegive, hvilke
        formål du vil give samtykke til ved at benytte checkboksene og derefter trykke på 'Gem indstillinger'.
    </vue-cookie-toggler>
</template>

<script>
import VueCookieToggler from 'vue-cookie-toggler';
import {setActiveCategories} from "./store";
import cloneDeep from "clone-deep";

export default {
    name: 'gdpr-cookie-toggler',
    components: {
        VueCookieToggler,
    },
    props: {
        locale: {
            type: String,
            default: 'en',
            required: false,
        },
        cookiesGroups: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            vueCookieToggler: null,

            calculatedCookieGroups: this.getCalculatedCookieGroups(),
            activeCookieCategories: [],
        }
    },
    methods: {
        getCalculatedCookieGroups() {
            let calculatedCookieGroups = cloneDeep(this.cookiesGroups, {});

            for (let cookiesGroup of calculatedCookieGroups) {
                if (cookiesGroup.link) {
                    const cookiesGroupLink = cookiesGroup.link;
                    const cookiesGroupLinkLabel = cookiesGroup.linkLabel || cookiesGroupLink;

                    const aTag = document.createElement('a');

                    aTag.href = cookiesGroupLink;
                    aTag.textContent = cookiesGroupLinkLabel;

                    cookiesGroup.description += `<br /><br />` + aTag.outerHTML;
                }
            }

            return calculatedCookieGroups;
        },
        setActiveCategoriesFromConsent() {
            const consent = this.vueCookieToggler.getConsent();

            let activeCookieCategories = [];

            for (let cookie of consent) {
                if (cookie.active) {
                    activeCookieCategories.push(cookie.category);
                }
            }

            this.activeCookieCategories = activeCookieCategories;

            this.$emit('activeCategoriesChanged', activeCookieCategories);
        },
    },
    watch: {
        'vueCookieToggler.$data.toggleModal'(toggleModal) {
            if (!toggleModal) {
                this.$nextTick(() => {
                    // It's probably just been saved the first time and the popup has been closed

                    this.setActiveCategoriesFromConsent();
                });
            }
        },
        cookieGroups: {
            deep: true,
            handler() {
                this.calculatedCookieGroups = this.getCalculatedCookieGroups();
            },
        },
    },
    emits: ['activeCategoriesChanged'],
    mounted() {
        this.vueCookieToggler = this.$refs['vueCookieToggler'];
        this.calculatedCookieGroups = this.getCalculatedCookieGroups();

        // toggleModal is called automatically on launch
        //if (this.vueCookieToggler.hasConsent()) {
        //    this.setActiveCategoriesFromConsent();
        //}
    }
}
</script>