<template>
    <div class="summary-box">
        <div class="summary-box-header">
            <h4>{{ title }}</h4>

            <button v-if="showToggleButton" class="toggle-button" v-on:click="toggle">
                <no-drag-image v-if="expanded" src="@/assets/icons/ico_minus.png"/>
                <no-drag-image v-else src="@/assets/icons/ico_plus.png"/>
            </button>
        </div>
        <transition-expand>
            <div v-if="expanded">
                <slot/>
            </div>
        </transition-expand>
    </div>
</template>

<script>
import TransitionExpand from '@/components/common/TransitionExpand.vue';

export default {
    name: 'summary-box',
    components: {
        TransitionExpand,
    },
    props: {
        title: {
            type: String
        },
        expandByDefault: {
            type: Boolean,
            default: false
        },
        showToggleButton: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            expanded: this.expandByDefault,
        }
    },
    methods: {
        toggle() {
            this.expanded = !this.expanded;
        }
    }
};
</script>

<style lang="scss" scoped>
.toggle-button {
    position: absolute;
    top: 20px;
    right: 10px;
    border: 0;
    outline: 0;
}

.summary-box {
    position: relative;
    background: $white-almost;
    border-radius: 4px;
    padding: 20px 30px;
}

.summary-box-header {
    h4 {
        text-align: center;
        width: 100%;
        margin-bottom: 0;
    }
}
</style>
