import extend from 'extend'

import flow from './flow'
import registration from './registration'
import misc from './misc'

export default extend(
    flow,
    registration,
    misc,
)
