<template>
    <div v-if="show" class="popup-container">
        <div class="popup">
            <div
                :class="popupContentClasses"
                class="popup-content"
            >
                <h1 v-if="options.title">{{ options.title }}</h1>

                <div class="popup-template container" v-if="options.template">
                    <div class="text-left" v-if="options.template === 'pdfGenerationStep1'">
                        <div>
                            Du er nu ved at generere en PDF med din indtastning.
                        </div>
                        <div class="mt-3">
                            <strong>Barnets navn</strong><br/>
                            <input class="form-control" placeholder="Barnets navn" v-model="data.childName" @keypress.enter="submitPopup"/>
                        </div>
                        <div class="mt-3">
                            <strong>Dato</strong><br/>

                            <DatePicker class="inline-block h-full" v-model="data.date" locale="da-DK">
                                <template v-slot="{ inputValue, togglePopover }">
                                    <input
                                        @focus="togglePopover"
                                        :value="inputValue"
                                        class="form-control"
                                        readonly
                                    />
                                </template>
                            </DatePicker>
                        </div>
                    </div>
                    <div class="text-left pdf-generation-step-2" v-else-if="options.template === 'pdfGenerationStep2'">
                        <div>
                            Gem efter gældende praksis på området (Sikre filer i Teams, Aula eller dit journaliseringssystem).
                        </div>
                    </div>
                    <div class="text-left" v-else-if="options.template === 'pdfGenerationStep3'">
                        <div>
                            Hvad skal din PDF navngives?
                        </div>
                        <div class="mt-3">
                            <input class="form-control" placeholder="PDF filnavn" v-model="data.pdfName" @keypress.enter="submitPopup" />
                        </div>
                    </div>
                    <div class="text-center" v-else-if="options.template === 'pdfGenerationStep4'">
                        <div class="text-center mb-4">
                            <strong>Din PDF er ved at blive genereret. Vent venligst..</strong>
                        </div>
                    </div>
                </div>

                <p v-else-if="options.html" v-html="options.html"></p>
                <p v-else-if="options.text">{{ options.text }}</p>

                <div class="choice-container" v-if="options.choices && options.choices.length">
                    <roskilde-button v-for="choice in options.choices" :key="choice.value"
                                     :type="choice.type || 'light'"
                                     v-on:click.native="onSelectChoice(choice.value)"
                                     :disabled="!choicesEnabled[choice.value]">
                        {{ choice.text }}
                    </roskilde-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import RoskildeButton from '@/components/buttons/RoskildeButton.vue';
import { Calendar, DatePicker } from 'v-calendar';

export default {
    name: 'popup',
    components: {RoskildeButton, Calendar, DatePicker},
    data() {
        return {
            show: false,
            options: {},

            unwatcher: null,
            promiseResolve: null,
            promiseReject: null,

            selectedChoice: null,
            selectedData: {},
            closeReason: null,
            results: {},

            choicesEnabled: {},

            data: {},

            attachedWindowEvent: false,
        };
    },
    created() {
        if (!Vue.prototype.$popup) {
            Object.defineProperty(Vue.prototype, '$popup', {value: this})
        }
    },
    watch: {
        data: {
            handler(data) {
                for (let choice of this.options.choices) {
                    this.choicesEnabled[choice.value] = this.isChoiceEnabled(choice);
                }
            },
            deep: true
        },
        show() {
            if (this.show) {
                if (!this.attachedWindowEvent) {
                    this.attachedWindowEvent = true;

                    window.addEventListener('keypress', this.onPopupKeyPress);
                }
            } else {
                if (this.attachedWindowEvent) {
                    window.removeEventListener('keypress', this.onPopupKeyPress);

                    this.attachedWindowEvent = false;
                }
            }
        }
    },
    methods: {
        onPopupKeyPress(e) {
            if (e.key === 'Enter') {
                this.submitPopup();
            }
        },
        isChoiceEnabled(choice) {
            if (choice.hasOwnProperty('enabled')) {
                if (typeof (choice.enabled) === 'function') {
                    return !!choice.enabled.apply(this);
                } else {
                    return choice.enabled;
                }
            } else {
                return true;
            }
        },
        close() {
            this.show = false;
            this.options = null;
            this.selectedChoice = null;
            this.closeReason = null;

            if (this.unwatcher) {
                this.unwatcher();

                this.unwatcher = null;
            }

            if (this.promiseReject && this.closeReason === 'close') {
                this.promiseReject('close');
                this.promiseReject = null;
            }

            this.promiseResolve = null;
            this.promiseReject = null;
        },
        open(options) {
            if (this.show) {
                this.close();
            }

            if (!options.choices) {
                options.choices = [
                    {
                        value: 'ok',
                        text: 'OK',
                    }
                ];
            }

            if (options.template) {
                if (options.template === 'pdfGenerationStep1') {
                    for (let choice of options.choices) {
                        choice.enabled = () => {
                            return this.data.childName && this.data.date;
                        };
                    }
                } else if (options.template === 'pdfGenerationStep3') {
                    for (let choice of options.choices) {
                        choice.enabled = () => {
                            return this.data.pdfName;
                        };
                    }
                }

                //options.text = null;
                //options.html = options.template.innerHTML;
            }

            this.selectedData = {};
            this.choicesEnabled = {};

            for (let choice of options.choices) {
                if (choice.hasOwnProperty('enabled')) {
                    this.choicesEnabled[choice.value] = this.isChoiceEnabled(choice);
                } else {
                    this.choicesEnabled[choice.value] = true;
                }
            }

            if (options.data) {
                this.data = options.data;
            }

            this.options = options;

            this.selectedChoice = null;
            this.closeReason = null;
            this.show = true;

            return new Promise((resolve, reject) => {
                this.promiseResolve = resolve;
                this.promiseReject = reject;

                this.unwatcher = this.$watch('closeReason', () => {
                    const promiseData = {
                        selectedChoice: this.selectedChoice,
                        selectedData: {...this.data},
                        closeReason: this.closeReason,
                    };

                    this.close();

                    resolve(promiseData);
                });
            });
        },
        onSelectChoice(choiceValue) {
            if (!this.choicesEnabled[choiceValue]) {
                return;
            }

            this.selectedChoice = choiceValue;
            this.closeReason = 'choice';
        },
        submitPopup() {
            if (this.options.choices && this.options.choices.length) {
                for (let choice of this.options.choices) {
                    const choiceValue = this.options.choices[0].value;

                    if (this.choicesEnabled[choiceValue]) {
                        this.onSelectChoice(this.options.choices[0].value);

                        return;
                    }
                }
            }

            console.log('There are no either no active choices or no choices at all, so we cannot call submitPopup');
        }
    },
    computed: {
        popupContentClasses() {
            return [
                this.options.title ? 'with-title' : 'without-title',
                this.options.text ? 'with-text' : 'without-text',
                this.options.template ? 'with-template' : 'without-template',
                'text-scale-' + (this.options.textScale || 'md')
            ];
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
@import "@/styles/variables.scss";

.popup-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000000000;
    background: rgba(0, 0, 0, .9);
}

h1 {
    font-weight: bold;
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.popup {
    @include alignCenter(true);

    background: $white-kind-of;
    box-shadow: 0px 0px 10px rgba(0, 28, 40, 0.3);
    text-align: center;
    max-width: 500px;

    p {
        color: $gray-dark;
        font-size: 26px;
        line-height: 31px;
        white-space: pre-wrap;
        padding: 0 50px;
    }
}

.choice-container {
    margin: 30px 20px 20px 20px;

    button {
        margin-left: 50px;

        &:first-child {
            margin-left: 0;
        }
    }
}

.popup-content {
    h1 {
        letter-spacing: 2px;
    }

    &.without-title {
        padding-top: 25px;
    }
}

.pdf-generation-step-2 {
    font-size: 140%;
}
</style>