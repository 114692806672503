/*********************************************
 * FLOW
 *********************************************/
export const SET_EXPECTED_NEXT_STEP = 'SET_EXPECTED_NEXT_STEP';

/*********************************************
 * MISC
 *********************************************/
export const SET_HEADER_TITLE_SHOW = 'SET_HEADER_TITLE_SHOW';
export const SET_OVERVIEW_BAR_SHOW = 'SET_OVERVIEW_BAR_SHOW';
export const SET_OVERVIEW_BAR_ACTIVE_STEP = 'SET_OVERVIEW_BAR_ACTIVE_STEP';
export const SET_RESULT_BAR_SHOW = 'SET_RESULT_BAR_SHOW';
export const SET_RESULT_BAR_WITH_PICKER = 'SET_RESULT_BAR_WITH_PICKER';
export const SET_RESULT_BAR_INTERACTIVE = 'SET_RESULT_BAR_INTERACTIVE';
export const SET_RESULT_BAR_PICKER_VALUE = 'SET_RESULT_BAR_PICKER_VALUE';
export const SET_RESULT_BAR_THREAT_LEVEL = 'SET_RESULT_BAR_THREAT_LEVEL';
export const SET_RESULT_BAR_THREAT_BIAS = 'SET_RESULT_BAR_THREAT_BIAS';
export const SET_RESULT_BAR_THREAT_DISPLAY = 'SET_RESULT_BAR_THREAT_DISPLAY';
export const SET_RESULT_BAR_HELP_DISPLAY = 'SET_RESULT_BAR_HELP_DISPLAY';
export const SET_RESULT_BAR_HELP_CLOSED = 'SET_RESULT_BAR_HELP_CLOSED';
export const SET_EXTERNAL_LINK = 'SET_EXTERNAL_LINK';
export const SET_CURRENT_SIGN_INDEX = 'SET_CURRENT_SIGN_INDEX';
export const SET_TOTAL_SIGN_INDEXES = 'SET_TOTAL_SIGN_INDEXES';
export const SET_CURRENT_REFLECTION_INDEX = 'SET_CURRENT_REFLECTION_INDEX';
export const SET_TOTAL_REFLECTION_INDEXES = 'SET_TOTAL_REFLECTION_INDEXES';
export const SET_BACK_BUTTON_DISPLAY = 'SET_BACK_BUTTON_DISPLAY';
/*********************************************
 * REGISTRATION
 *********************************************/
export const SET_REGISTRATION_AGE_GROUP = 'SET_REGISTRATION_AGE_GROUP';
export const SET_REGISTRATION_THEMES = 'SET_REGISTRATION_THEMES';
export const SET_REGISTRATION_FUNCTION = 'SET_REGISTRATION_FUNCTION';
export const ANSWER_QUESTION = 'ANSWER_QUESTION';

export const SET_DEBUG_SCORES = 'SET_DEBUG_SCORES';
export const SET_DEBUG_SCORES_STATS = 'SET_DEBUG_SCORES_STATS';
export const SET_SHOW_GDPR_POPUP = 'SET_SHOW_GDPR_POPUP';