export default {
    ageGroupChosen(ageGroupValue) {
        this.event('custom_age_group_chosen', {
            'event_category': 'Age Group Chosen',
            'event_label': ageGroupValue,
            'age_group': ageGroupValue,
        });
    },

    functionChosen(functionValue) {
        this.event('custom_function_chosen', {
            'event_category': 'Function Chosen',
            'event_label': functionValue,
            'function': functionValue,
        });
    },

    themeChosen(themeValue) {
        this.event('custom_theme_chosen', {
            'event_category': 'Theme Chosen',
            'event_label': themeValue,
            'theme_name': themeValue,
        });
    },

    signsStart() {
        this.event('custom_signs_start', {
            'event_category': 'Flow',
            'event_label': 'Signs start',
        });
    },

    signsEnd(secondsTaken) {
        this.event('custom_signs_end', {
            'event_category': 'Flow',
            'event_label': 'Signs end',
            'seconds_taken': secondsTaken,
        });
    },

    signsStartCount(count) {
        this.event('custom_signs_start_count', {
            'event_category': 'Signs start',
            'event_label': 'Count ' + count,
            'count': count,
            'value': count,
        });
    },

    signsEndCount(count, secondsTaken) {
        this.event('custom_signs_end_count', {
            'event_category': 'Signs end',
            'event_label': 'Count ' + count,
            'count': count,
            'seconds_taken': secondsTaken,
        });
    },

    reflectionsStart() {
        this.event('custom_reflections_start', {
            'event_category': 'Flow',
            'event_label': 'Reflections start',
        });
    },

    reflectionsEnd(secondsTaken) {
        this.event('custom_reflections_end', {
            'event_category': 'Flow',
            'event_label': 'Reflections end',
            'seconds_taken': secondsTaken,
        });
    },

    reflectionsStartCount(count) {
        this.event('custom_reflections_start_count', {
            'event_category': 'Reflections start',
            'event_label': 'Count ' + count,
            'count': count,
        });
    },

    reflectionsEndCount(count, secondsTaken) {
        this.event('custom_reflections_end_count', {
            'event_category': 'Reflections end',
            'event_label': 'Count ' + count,
            'count': count,
            'seconds_taken': secondsTaken,
        });
    },

    rulerScore(score) {
        this.event('custom_ruler_score', {
            'event_category': 'Ruler score',
            'event_label': 'Ruler score ' + score,
            'value': score,
            'score': score,
        });
    },

    copiedLink() {
        this.event('custom_copied_link', {
            'event_category': 'Buttons',
            'event_label': 'Copied link',
        });
    },

    downloadedPdf() {
        this.event('custom_downloaded_pdf', {
            'event_category': 'Buttons',
            'event_label': 'Downloaded PDF',
        });
    },
};
