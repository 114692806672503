<template>
    <div>
        <result-bar class="mb-3"/>
        <result-bar :withPicker="true" class="mb-3"/>
        <result-bar :threatBias="threatBias" :threatDisplay="true" :threatLevel="threatLevel" class="mb-3"/>

        <div v-for="n in 11">
            <div style="max-width: 1200px;">
                <result-bar :threatBias="threatBias" :threatDisplay="true" :threatLevel="threatLevel" :withPicker="true"
                            class="mb-3" :value="n - 1" :interactive="false"/>
            </div>
        </div>

        <div>
            <div style="max-width: 1200px;">
                <result-bar :threatBias="threatBias" :threatDisplay="true" :threatLevel="threatLevel" :withPicker="true"
                            class="mb-3" :value="barValue" :interactive="barInteractive"/>
            </div>
        </div>
        <div>
            <div style="max-width: 1100px;">
                <result-bar :threatBias="threatBias" :threatDisplay="true" :threatLevel="threatLevel" :withPicker="true"
                            class="mb-3" :value="barValue" :interactive="barInteractive"/>
            </div>
        </div>
        <div>
            <div style="max-width: 1000px;">
                <result-bar :threatBias="threatBias" :threatDisplay="true" :threatLevel="threatLevel" :withPicker="true"
                            class="mb-3" :value="barValue" :interactive="barInteractive"/>
            </div>
        </div>
        <div>
            <div style="max-width: 900px;">
                <result-bar :threatBias="threatBias" :threatDisplay="true" :threatLevel="threatLevel" :withPicker="true"
                            class="mb-3" :value="barValue" :interactive="barInteractive"/>
            </div>
        </div>
        <div>
            <div style="max-width: 800px;">
                <result-bar :threatBias="threatBias" :threatDisplay="true" :threatLevel="threatLevel" :withPicker="true"
                            class="mb-3" :value="barValue" :interactive="barInteractive"/>
            </div>
        </div>
        <div>
            <div style="max-width: 700px;">
                <result-bar :threatBias="threatBias" :threatDisplay="true" :threatLevel="threatLevel" :withPicker="true"
                            class="mb-3" :value="barValue" :interactive="barInteractive"/>
            </div>
        </div>
        <div>
            <div style="max-width: 600px;">
                <result-bar :threatBias="threatBias" :threatDisplay="true" :threatLevel="threatLevel" :withPicker="true"
                            class="mb-3" :value="barValue" :interactive="barInteractive" />
            </div>
        </div>

        <div class="form-group">
            <div class="col-3">
                <label>Threat level</label>
                <select v-model="threatLevelString" class="form-control">
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                </select>
            </div>
            <div class="col-3">
                <label>Threat bias</label>
                <select v-model="threatBiasString" class="form-control">
                    <option value="">None</option>
                    <option value="-1">-1 (bad)</option>
                    <option value="1">1 (good)</option>
                </select>
            </div>
            <div class="col-3">
                <label>Value</label>
                <select v-model.number="barValue" class="form-control">
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                </select>
            </div>
            <div class="col-3">
                <label>Interactive</label>
                <select v-model.number="barInteractiveDropdown" class="form-control">
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import OverviewBar from '@/components/common/OverviewBar.vue';
import ResultBar from '@/components/result-bar/ResultBar.vue';
import ExternalLink from '../../components/common/ExternalLink';
import ReflectionCheckbox from '../../components/input/ReflectionCheckbox';
import ReflectionCheckboxContainer from '../../components/input/ReflectionCheckboxContainer';

export default {
    name: 'dev-bar',
    components: {
        ReflectionCheckboxContainer,
        ReflectionCheckbox,
        ExternalLink,
        OverviewBar,
        ResultBar,
    },
    data() {
        return {
            threatLevelString: '2',
            threatBiasString: '',
            barValue: 2,
            barInteractiveDropdown: 0,
        }
    },
    computed: {
        barInteractive() {
            return this.barInteractiveDropdown > 0;
        },
        threatLevel() {
            return parseFloat(this.threatLevelString);
        },
        threatBias() {
            return this.threatBiasString === 'none' ? null : parseFloat(this.threatBiasString);
        }
    }
}
</script>
