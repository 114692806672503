const oftenAnswers = {
    '2': '1-3 gange om måneden', //Èn gang om måneden eller mindre',
    '0': '1-3 gange om ugen',
    '-2': 'Hver dag',
};

const oftenAnswerOrder = ['-2', '0', '2'];

const worriedAnswers = {
    '-2': 'Meget',
    '-1': 'Moderat', // New
    '0': 'Begyndende',
    '2': 'Slet ikke',
};

const worriedAnswerOrder = ['2', '0', '-1', '-2'];

export function getOftenLabel(value) {
    return oftenAnswers.hasOwnProperty(value) ? oftenAnswers[value] : 'N/A';
}

export function getWorriedLabel(value) {
    return worriedAnswers.hasOwnProperty(value) ? worriedAnswers[value] : 'N/A';
}

export function getAnswerData() {
    return {
        worriedAnswers: worriedAnswers,
        worriedAnswerOrder: worriedAnswerOrder,

        oftenAnswers: oftenAnswers,
        oftenAnswerOrder: oftenAnswerOrder,
    }
}
