<template>
    <div class="setup-container">
        <div class="center-content">
            <transition-expand>
                <div v-if="askAge">
                    <h4>Aldersgruppe</h4>

                    <roskilde-button-group
                        v-model="activeAgeGroup"
                        :checkboxes="ageGroupsArray"
                        type="radio"
                    />
                </div>
            </transition-expand>

            <transition-expand>
                <div v-if="askFunction" class="mt-3">
                    <h4>Funktion</h4>

                    <roskilde-button-group
                        v-model="activeFunction"
                        :checkboxes="functionsArray"
                        type="radio"
                    />
                </div>
            </transition-expand>

            <transition-expand>
                <div v-if="askTheme" class="mt-3">
                    <h4>Temaer</h4>
                    <h5>Jeg har en bekymring i forhold til...</h5>

                    <roskilde-button-group
                        v-model="activeThemes"
                        :checkboxes="themesArray"
                        type="checkbox"
                    />
                </div>
            </transition-expand>
        </div>

        <div class="button-container">
            <roskilde-button :disabled="!(activeAgeGroup.length && activeThemes.length)" borderType="full" size="lg" type="light"
                             widthType="fixed" v-on:click="nextStep">Næste
                skridt
            </roskilde-button>
        </div>
    </div>
</template>

<script>
import * as MutationTypes from '@/store/mutation-types'
import {mapGetters} from 'vuex'
import RoskildeButtonGroup from '@/components/buttons/RoskildeButtonGroup'
import RoskildeButton from '@/components/buttons/RoskildeButton'
import TransitionExpand from '@/components/common/TransitionExpand.vue';

export default {
    name: 'setup-view',
    components: {
        RoskildeButtonGroup,
        RoskildeButton,
        TransitionExpand,
    },
    data() {
        let ageGroups = {
            //'unborn': 'Ufødt',
            '0-1': '0-1',
            '1-2': '1-2',
            '3-5': '3-5',
            '6-9': '6-9',
            '10-14': '10-14',
            '15-18': '15-18',
        }

        let ageGroupsArray = []

        for (let key in ageGroups) {
            ageGroupsArray.push({
                value: key,
                label: ageGroups[key],
            })
        }

        return {
            ageGroupsArray: ageGroupsArray,
            activeAgeGroup: [],

            themesArray: [],
            activeThemes: [],

            functionsArray: [],
            activeFunction: [],

            activeAgeGroupCalculated: null,
            activeThemesCalculated: [],

            page: null,
        }
    },
    beforeDestroy() {
        this.$gaAnalytics.timerEnd('setup');
    },
    mounted() {
        console.log('BEFORE MOUNT');
        console.log('registrationThemes', this.registrationThemes);
        console.log('registrationAgeGroup', this.registrationAgeGroup);

        this.$gaAnalytics.timerStart('setup');

        this.$store.commit(MutationTypes.SET_OVERVIEW_BAR_SHOW, true)
        this.$store.commit(MutationTypes.SET_RESULT_BAR_SHOW, true)
        this.$store.commit(MutationTypes.SET_HEADER_TITLE_SHOW, true)
        this.$store.commit(MutationTypes.SET_EXTERNAL_LINK, null)

        if (this.registrationAgeGroup) {
            let index = this.getAgeGroupIndexFromValue(this.registrationAgeGroup);

            if (index) {
                this.activeAgeGroup = [index]

                this.recalculateFunctions();
            } else {
                console.log(`Could not find age group index by string ${this.registrationAgeGroup}`)
                console.log('ageGroupsArray', this.ageGroupsArray);
            }
        } else {
            this.activeAgeGroup = []
        }

        this.recalculateCalculatedActiveAgeGroup();

        if (this.registrationFunction) {
            let index = this.getFunctionIndexFromValue(this.registrationFunction);

            if (index) {
                this.activeFunction = [index]

                this.recalculateThemes();
            } else {
                console.log(`Could not find function index by string ${this.registrationFunction}`)
                console.log('functionsArray', this.functionsArray)
            }
        } else {
            this.activeFunction = []
        }

        if (this.registrationThemes) {
            let registrationThemes = [];

            for (let i = 0, len = this.registrationThemes.length; i < len; i++) {
                let registrationTheme = this.registrationThemes[i];

                console.log(registrationTheme, this.getThemeIndexFromValue(registrationTheme));

                registrationThemes.push(this.getThemeIndexFromValue(registrationTheme));
            }

            console.log('registrationThemes', registrationThemes);

            this.activeThemes = registrationThemes
        } else {
            this.activeThemes = []
        }

        this.setPageFromRoute(this.$route);
    },
    methods: {
        nextStep() {
            if (!this.activeAgeGroupCalculated || !this.activeThemesCalculated.length) {
                return
            }

            this.$store.commit(MutationTypes.SET_REGISTRATION_AGE_GROUP, this.activeAgeGroupCalculated)
            this.$store.commit(MutationTypes.SET_REGISTRATION_FUNCTION, this.activeFunctionCalculated)
            this.$store.commit(MutationTypes.SET_REGISTRATION_THEMES, this.activeThemesCalculated)

            this.$gaAnalytics.events.ageGroupChosen(this.activeAgeGroupCalculated);
            this.$gaAnalytics.events.functionChosen(this.activeFunctionCalculated);

            for (const themeValue of this.activeThemesCalculated) {
                this.$gaAnalytics.events.themeChosen(themeValue);
            }

            this.$router.push('/signs')
        },
        recalculateFunctions() {
            let functions = []

            let activeAgeGroup = this.activeAgeGroupCalculated

            console.log(this.appData.offerAgeFunctionsMatrix['age'][activeAgeGroup]);

            if (activeAgeGroup) {
                let _functions = this.appData.offerAgeFunctionsMatrix['age'].hasOwnProperty(activeAgeGroup)
                    ? this.appData.offerAgeFunctionsMatrix['age'][activeAgeGroup]
                    : [];

                if (!_functions) {
                    _functions = [];
                }

                for (let _function of _functions) {
                    let functionName = this.appData.functions[_function.header];

                    functions.push({
                        value: _function.header,
                        label: functionName
                    })
                }
            }

            this.functionsArray = functions

            this.recalculateCalculatedActiveFunction();
        },
        recalculateThemes() {
            let themes = []

            let activeAgeGroup = this.activeAgeGroupCalculated

            if (activeAgeGroup) {
                let themeDescriptions = this.appData.themeDescriptions.hasOwnProperty(activeAgeGroup)
                    ? this.appData.themeDescriptions[activeAgeGroup]
                    : {}

                for (let theme in this.appData.signs[activeAgeGroup].themeQuestionUids) {
                    themes.push({
                        value: theme,
                        label: theme,
                        description: themeDescriptions.hasOwnProperty(theme) ? themeDescriptions[theme] : null,
                    })
                }
            }

            this.themesArray = themes

            this.recalculateCalculatedActiveThemes();
        },
        getAgeGroupValueFromIndex(ageGroup) {
            if (typeof ageGroup === 'string') {
                return ageGroup;
            }

            return this.ageGroupsArray[ageGroup].value
        },
        getAgeGroupIndexFromValue(ageGroup) {
            for (let i = 0, len = this.ageGroupsArray.length; i < len; i++) {
                if (this.ageGroupsArray[i].value === ageGroup) {
                    return i;
                }
            }

            return null;
        },
        getFunctionValueFromIndex(_function) {
            console.log('_function', _function);

            if (typeof _function === 'string') {
                return _function;
            }

            return this.functionsArray[_function].value
        },
        getFunctionIndexFromValue(_function) {
            for (let i = 0, len = this.functionsArray.length; i < len; i++) {
                if (this.functionsArray[i].value === _function) {
                    return i;
                }
            }

            return null;
        },
        getThemeValueFromIndex(theme) {
            if (typeof theme === 'string') {
                return theme;
            }

            if (!this.themesArray || this.themesArray.length === 0 || theme === null) {
                return null;
            }

            return this.themesArray[theme].value
        },
        getThemeIndexFromValue(theme) {
            if (!this.themesArray || this.themesArray.length === 0) {
                return null;
            }

            for (let i = 0, len = this.themesArray.length; i < len; i++) {
                if (this.themesArray[i].value === theme) {
                    return i;
                }
            }

            return null;
        },
        recalculateCalculatedActiveAgeGroup() {
            if (!this.activeAgeGroup || !this.activeAgeGroup.length) {
                this.activeAgeGroupCalculated = null
            } else {
                this.activeAgeGroupCalculated = this.getAgeGroupValueFromIndex(this.activeAgeGroup)
            }

            this.recalculateFunctions()

            if (this.activeAgeGroupCalculated) {
                this.$router.push('/setup/function');
            }
        },
        recalculateCalculatedActiveFunction() {
            if (!this.activeFunction || !this.activeFunction.length) {
                this.activeFunctionCalculated = null
            } else {
                this.activeFunctionCalculated = this.getFunctionValueFromIndex(this.activeFunction)
            }

            this.recalculateThemes()

            if (this.activeFunctionCalculated) {
                this.$router.push('/setup/theme');
            }
        },
        recalculateCalculatedActiveThemes() {
            let activeThemesCalculated = []

            if (this.activeThemes && this.activeThemes.length) {
                for (let i = 0, len = this.activeThemes.length; i < len; i++) {
                    activeThemesCalculated.push(this.getThemeValueFromIndex(this.activeThemes[i]));
                }
            }

            this.activeThemesCalculated = activeThemesCalculated
        },
        setPageFromRoute($route) {
            let page = $route.params.page || $route.meta.page || null;

            console.log('$route', $route);
            console.log('$route.page', page);

            this.setPage(page);
        },
        setPage(page) {
            console.log('PAGE PAGE PAGE', page);

            if (!page) {
                this.$router.push('/setup/age');

                return;
            }

            if (page === 'function') {
                // Check if age is set
                let hasValue = this.activeAgeGroupCalculated && this.activeAgeGroupCalculated.length;

                if (!hasValue) {
                    this.$router.push('/setup/age');

                    return;
                }
            } else if (page === 'theme') {
                // Check if age is set
                let hasValue = this.activeFunctionCalculated && this.activeFunctionCalculated.length;

                if (!hasValue) {
                    this.$router.push('/setup/function');

                    return;
                }
            }

            this.page = page;
        }
    },
    watch: {
        activeFunction() {
            this.recalculateCalculatedActiveFunction()
        },
        activeAgeGroup() {
            this.recalculateCalculatedActiveAgeGroup()
        },
        activeThemes() {
            this.recalculateCalculatedActiveThemes()
        },
        '$route'(to, from) {
            let oldPage = from.params.page || from.meta.page || null;
            let newPage = to.params.page || to.meta.page || null;

            if (oldPage === 'age' && newPage === 'theme') {
                // Slide up
                this.transitionName = 'slide-up';
            } else if (oldPage === 'theme' && newPage === 'age') {
                // Slide down
                this.transitionName = 'slide-down';
            } else {
                // Slide up
                this.transitionName = 'slide-up';
            }

            this.setPageFromRoute(to);
        },
    },
    computed: {
        ...mapGetters({
            appData: 'getAppData',
            registrationAgeGroup: 'getRegistrationAgeGroup',
            registrationThemes: 'getRegistrationThemes',
            registrationFunction: 'getRegistrationFunction',
        }),
        askAge() {
            return this.page === 'age' || !this.page;
        },
        askFunction() {
            return this.page === 'function';
        },
        askTheme() {
            return this.page === 'theme';
        }
    },
}
</script>

<style lang="scss" scoped>
.setup-container {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    padding-top: 130px;
}

.center-content {
    @include alignCenter(true);
}

.button-container {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
}

h4 {
    text-align: center;
    font-weight: bold;
    font-size: 26px;
    color: $black;
}

.button-group {
    text-align: center;
}
</style>
