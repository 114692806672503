export default {
    // Flow
    showGdprPopup: true,
    expectedNextStep: 'signs',
    currentStep: 'setup',

    // Registrations
    registrations: [],
    registrationAgeGroup: null,
    registrationThemes: null,
    registrationFunction: null,
    questionAnswers: {},

    // Misc
    appData: require('../../fixtures/app-data'),
    headerTitleShow: false,
    overviewBarShow: false,
    overviewBarActiveStep: null,
    resultBarShow: false,
    resultBarInteractive: true,
    resultBarWithPicker: false,
    resultBarPickerValue: -1,
    threatLevel: null,
    threatBias: null,
    threatDisplay: false,
    externalLink: null,
    helpDisplay: false,
    resultBarHelpClosed: false,
    backButtonDisplay: false,

    currentSignIndex: null,
    totalSignIndexes: null,

    currentReflectionIndex: null,
    totalReflectionIndexes: null,

    debugScores: false,
    debugScoresStats: {},
}
