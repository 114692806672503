<template>
    <div id="app">
        <popup/>

        <transition name="fade">
            <overview-bar
                v-if="overviewBarShow"
                :active="overviewBarActiveStep"
                :showTitle="headerTitleShow"
                class="pb-3"
            />
        </transition>

        <transition name="fade">
            <result-bar
                v-if="resultBarShow"
                :interactive="resultBarInteractive"
                :threatBias="resultBarThreatBias"
                :threatDisplay="resultBarThreatDisplay"
                :threatLevel="resultBarThreatLevel"
                :value="resultBarPickerValue"
                :withHelp="resultBarHelpDisplay && !resultBarHelpClosed"
                :withPicker="resultBarWithPicker"
                class="mb-3"
                v-on:helpClosed="resultBarPickerOnHelpClosed"
                v-on:input="resultBarPickerValueUpdated"
            />
        </transition>

        <transition name="fade">
            <router-view class="router-view"/>
        </transition>

        <transition name="fade">
            <external-link v-if="externalLink && externalLink.url" :href="externalLink.url" :label="externalLink.label"
                           class="bottom-link"/>
        </transition>

        <transition name="fade">
            <div v-if="backButtonDisplay" class="container back-button-container">
                <back-button class="btn-back"/>
            </div>
        </transition>

        <gdpr-cookie-toggler
            v-if="doShowGdprPopup"
            locale="da"
            :cookies-groups="cookiesGroups"
            @activeCategoriesChanged="onCookieTogglerActiveCategoriesChanged"
        />
    </div>
</template>

<script>
import OverviewBar from '@/components/common/OverviewBar.vue';
import ResultBar from '@/components/result-bar/ResultBar.vue';
import {mapGetters} from 'vuex'
import Popup from '@/components/app-components/popup'
import ExternalLink from './components/common/ExternalLink';
import * as MutationTypes from '@/store/mutation-types';
import BackButton from '@/components/back-button';
import GdprCookieToggler from "./components/app-components/gdpr-cookie-toggler";
import {SET_DEBUG_SCORES} from "./store/mutation-types";

export default {
    components: {
        GdprCookieToggler,
        ExternalLink,
        OverviewBar,
        ResultBar,
        Popup,
        BackButton,
    },
    computed: {
        ...mapGetters({
            showGdprPopup: 'getShowGdprPopup',

            headerTitleShow: 'getHeaderTitleShow',

            overviewBarShow: 'getOverviewBarShow',
            overviewBarActiveStep: 'getOverviewBarActiveStep',

            resultBarShow: 'getResultBarShow',
            resultBarWithPicker: 'getResultBarWithPicker',
            resultBarPickerValue: 'getResultBarPickerValue',
            resultBarThreatLevel: 'getResultBarThreatLevel',
            resultBarThreatDisplay: 'getResultBarThreatDisplay',
            resultBarThreatBias: 'getResultBarThreatBias',
            resultBarInteractive: 'getResultBarInteractive',
            resultBarHelpDisplay: 'getResultBarHelpDisplay',
            resultBarHelpClosed: 'getResultBarHelpClosed',

            backButtonDisplay: 'getBackButtonDisplay',

            debugScores: 'getDebugScores',

            externalLink: 'getExternalLink',
        }),
        doShowGdprPopup() {
            if (window && window.hasOwnProperty('isPdfCreator') && window['isPdfCreator']) {
                return false; // Do not show the popup for the PDF creator
            }

            // Select the value from the store
            return this.showGdprPopup;
        }
    },
    mounted() {
        window.toggleDebugScores = () => {
            this.$store.commit(SET_DEBUG_SCORES, !this.debugScores);
        };
    },
    methods: {
        resultBarPickerValueUpdated(value) {
            console.log('value', value);
            this.$store.commit(MutationTypes.SET_RESULT_BAR_PICKER_VALUE, value);
        },
        resultBarPickerOnHelpClosed() {
            console.log('resultBarPickerOnHelpClosed');
            this.$store.commit(MutationTypes.SET_RESULT_BAR_HELP_CLOSED, true);
        },
        onCookieTogglerActiveCategoriesChanged(activeCategories) {
            if (activeCategories.includes('analytics')) {
                console.log('We accepted analytics, so opting in to google analytics');

                this.$gaAnalytics.setGTag(this.$gtag);
                this.$gaAnalytics.enable();
            } else {
                console.log('Not opting in to google analytics');
            }
        }
    },
    data() {
        return {
            routerPage: null,
            routerTransitionName: 'fade',

            isPrinting: false,

            cookiesGroups: [
                {
                    category: 'analytics',
                    name: 'Statistiske',
                    description: 'Brugt til at se anonymt brug af siden for at optimere løsningen.',
                    link: 'https://www.roskildemodellen.dk/cookies.html',
                    linkLabel: 'Læs mere om cookies',
                    active: true,
                },
            ],
        }
    },
    watch: {
        '$route': {
            deep: true,
            handler() {
                this.routerPage = this.$route.name;

                let showBackButton = false;

                if (this.$route.meta && this.$route.meta.showBackButton) {
                    showBackButton = true;
                }

                console.log('showBackButton', showBackButton);
                this.$store.commit(MutationTypes.SET_BACK_BUTTON_DISPLAY, showBackButton);
            },
        }
    },
}
</script>

<style lang="scss">
@import "@/styles/animations/slide-simple/vue-transitions.scss";
@import "@/styles/main.scss";

html, body {
    height: 100%;
    background: white;
    padding: 0;
    margin: 0;
}

#app {
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    padding: 10px;
    overflow-x: hidden;
    width: 100%;
    min-height: 100%;
    min-width: 575px;
}

h1, h2, h3, h4, h5 {
    font-weight: bold !important;
    font-family: 'Montserrat', sans-serif;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

.bottom-link {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.header {
    z-index: 1000;
    background: white;
}

.back-button-container {
    position: absolute;
    top: 150px;
    left: 50%;
    transform: translateX(-50%);
}
</style>
