export default {
    getRegistrationAgeGroup(state) {
        return state.registrationAgeGroup;
    },
    getRegistrationFunction(state) {
        return state.registrationFunction;
    },
    getRegistrationThemes(state) {
        return state.registrationThemes;
    },
    getQuestionAnswers(state) {
        return state.questionAnswers;
    },
}
