import GaAnalyticsCore from './core';

import Events from './mixins/events';
import Timings from './mixins/timings';

export default class GaAnalytics extends GaAnalyticsCore {
    constructor(enabled = false) {
        super(enabled);

        let events = {};

        for (const event in Events) {
            const callback = Events[event];

            events[event] = callback.bind(this);
        }

        this.events = events;

        let timings = {};

        for (const timing in Timings) {
            const callback = Timings[timing];

            timings[timing] = callback.bind(this);
        }

        this.timings = timings;
    }
}
