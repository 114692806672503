<template>
    <a :href="href" class="external-link" target="_blank">
        <no-drag-image src="@/assets/icons/link-icon.png"/>
        <span>{{ label || href }}</span>
    </a>
</template>

<script>
export default {
    name: 'external-link',
    props: {
        href: {
            type: String,
        },
        label: {
            type: String,
            default: null
        },
    },
}
</script>

<style lang="scss" scoped>
.external-link {
    color: $link;
    line-height: 16px;
    font-weight: 500;
    font-size: 14px;

    span {
        margin-left: 5px;
    }
}
</style>