<template>
	<span class="single-button-container">
		<button v-if="text" :class="buttonClass" class="button" v-on:click="onClick" v-text="text"/>
		<button v-else :class="buttonClass" class="button" v-on:click="onClick">
			<slot/>
		</button>
		<button v-if="description" class="description" v-on:click="openDescription"/>
	</span>
</template>

<script>
export default {
    name: 'roskilde-button',
    props: {
        clickable: {
            // whether or not this button can be clicked in the first place
            type: Boolean,
            default: true
        },
        disabled: {
            // whether or not this button is disabled
            type: Boolean,
            default: false
        },
        canToggle: {
            // whether or not this button can be toggled
            type: Boolean,
            default: false
        },
        value: {
            // if toggled, the value
            type: Boolean,
            default: false,
        },
        type: {
            // dark, light, lighter, white, blue
            type: String,
        },
        toggleType: {
            // dark, light, white, blue
            type: String,
            default() {
                return this.type;
            }
        },
        widthType: {
            // auto, fixed, fixed-small
            type: String,
            default: 'auto'
        },
        size: {
            // xxs, xs, sm, md, lg, xl
            type: String,
            default: 'md'
        },
        borderType: {
            // full, full-left, full-right, partial
            type: String,
            default: 'full'
        },
        description: {
            type: String,
            default: null
        },
        text: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            toggled: this.value
        }
    },
    watch: {
        value() {
            this.toggled = this.value;
        }
    },
    methods: {
        onClick() {
            if (this.disabled) {
                return;
            }

            if (this.canToggle) {
                this.$emit('input', !this.toggled);
            } else {
                this.$emit('click');
            }
        },
        openDescription() {
            this.$popup.open({
                title: this.$slots.default[0].text || 'Information',
                text: this.description
            });
        }
    },
    computed: {
        buttonClass() {
            let classes = [];

            if (this.disabled) {
                classes.push('disabled');
            }

            if (!this.clickable) {
                classes.push('static');
            }

            if (this.toggled) {
                classes.push('toggled');
            }

            classes.push('button-' + this.type);
            classes.push('button-toggle-' + this.toggleType);
            classes.push('button-size-' + this.size);
            classes.push('button-border-' + this.borderType);
            classes.push('button-width-' + this.widthType);

            return classes;
        }
    }
}
</script>
<style lang="scss">
.button {
    letter-spacing: 2px !important;
}
</style>

<style lang="scss" scoped>
$btn-dark-bg: #212529;
$btn-dark-text: #ffffff;

$btn-lighter-bg: $gray-lighter;
$btn-lighter-text: $gray;

$btn-light-bg: $gray-light;
$btn-light-text: $gray-dark;

$btn-white-bg: $white-almost;
$btn-white-text: $black;

$btn-blue-bg: $link;
$btn-blue-text: #ffffff;

.single-button-container {
    position: relative;
}

.description {
    $ballDiameter: 22px;

    position: absolute;
    top: -(math.div($ballDiameter, 2)) - 10px;
    right: -(math.div($ballDiameter, 2));
    width: $ballDiameter;
    height: $ballDiameter;
    border-radius: math.div($ballDiameter, 2);
    line-height: $ballDiameter;
    padding: 0;
    margin: 0;
    text-align: center;
    z-index: 1000;

    &:after {
        font-weight: bold;
        font-size: 16px;
        content: "?";
        position: absolute;
        transform: translate(-50%, -50%);
    }

    &:hover {
        background: rgba(220, 220, 220);
    }

    &:focus {
        outline: 0;
    }
}

.button {
    border: 0;
    transition: background-color 200ms linear, color 200ms linear, opacity 200ms linear;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;

    &:focus {
        outline: 0;
    }

    &.disabled {
        cursor: not-allowed;
    }

    &.static {
        cursor: default !important;
    }

    // Different colours
    &.button-dark, &.button-toggle-dark.toggled {
        background: $btn-dark-bg;
        color: $btn-dark-text;

        &.disabled {
            opacity: .7 !important;
            background: $btn-dark-bg !important;
        }

        &.static {
            background: $btn-dark-bg !important;
        }

        &:hover {
            background: darken($btn-dark-bg, 10%);
        }

        &:active {
            background: darken($btn-dark-bg, 20%);
        }
    }


    &.button-lighter, &.button-toggle-lighter.toggled {
        background: $btn-lighter-bg;
        color: $btn-lighter-text;

        &.disabled {
            opacity: .7 !important;
            background: $btn-lighter-bg !important;
        }

        &.static {
            background: $btn-lighter-bg !important;
        }

        &:hover {
            background: darken($btn-lighter-bg, 10%);
        }

        &:active {
            background: darken($btn-lighter-bg, 20%);
        }
    }

    &.button-light, &.button-toggle-light.toggled {
        background: $btn-light-bg;
        color: $btn-light-text;

        &.disabled {
            background: $white-almost !important;
            color: $gray;
        }

        &.static {
            background: $btn-light-bg !important;
        }

        &:hover {
            background: darken($btn-light-bg, 10%);
        }

        &:active {
            background: darken($btn-light-bg, 20%);
        }
    }


    &.button-white, &.button-toggle-white.toggled {
        background: $btn-white-bg;
        color: $btn-white-text;

        &.disabled {
            opacity: .7 !important;
            background: $btn-white-bg !important;
        }

        &.static {
            background: $btn-white-bg !important;
        }

        &:hover {
            background: darken($btn-white-bg, 10%);
        }

        &:active {
            background: darken($btn-white-bg, 20%);
        }
    }

    &.button-blue, &.button-toggle-blue.toggled {
        background: $btn-blue-bg;
        color: $btn-blue-text;

        &.disabled {
            opacity: .7 !important;
            background: $btn-blue-bg !important;
        }

        &.static {
            background: $btn-blue-bg !important;
        }

        &:hover {
            background: darken($btn-blue-bg, 10%);
        }

        &:active {
            background: darken($btn-blue-bg, 20%);
        }
    }

    // Different widths
    &.button-width-auto {
        padding: 8px 15px;
    }

    &.button-width-fixed {
        min-width: 283px;
        padding: 8px 15px;
    }

    &.button-width-fixed-small {
        min-width: 182px;
        padding: 8px 15px;
    }

    // Different sizes
    &.button-size-xxs {
        font-size: 11px;
    }

    &.button-size-xs {
        font-size: 14px;
    }

    &.button-size-sm {
        font-size: 16px;
    }

    &.button-size-md {
        font-size: 22px;
    }

    &.button-size-lg {
        font-size: 22px;
    }

    &.button-size-xl {
        font-size: 36px;

        &.button-width-auto {
            &.button-border-full-left {
                padding: 8px 15px 8px 30px;
            }

            &.button-border-full-right {
                padding: 8px 30px 8px 15px;
            }
        }
    }

    // Different borders
    &.button-border-partial {
        border-radius: 8px;

        /*&.button-size-xs {
            border-radius: 12px;
        }

        &.button-size-sm {
            border-radius: 20px;
        }

        &.button-size-md {
            border-radius: 32px;
        }

        &.button-size-lg {
            border-radius: 40px;
        }

        &.button-size-xl {
            border-radius: 50px;
        }*/
    }

    &.button-border-full {
        border-radius: 100px;
    }

    &.button-border-full-left {
        border-radius: 100px 0 0 100px;
    }

    &.button-border-full-right {
        border-radius: 0 100px 100px 0;
    }
}
</style>
