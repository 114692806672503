export default {
    getHeaderTitleShow(state) {
        return state.headerTitleShow;
    },
    getOverviewBarShow(state) {
        return state.overviewBarShow;
    },
    getOverviewBarActiveStep(state) {
        return state.overviewBarActiveStep;
    },
    getResultBarShow(state) {
        return state.resultBarShow;
    },
    getResultBarWithPicker(state) {
        return state.resultBarWithPicker;
    },
    getResultBarPickerValue(state) {
        return state.resultBarPickerValue;
    },
    getResultBarInteractive(state) {
        return state.resultBarInteractive;
    },
    getResultBarThreatLevel(state) {
        return state.threatLevel;
    },
    getResultBarThreatBias(state) {
        return state.threatBias;
    },
    getResultBarThreatDisplay(state) {
        return state.threatDisplay;
    },
    getResultBarHelpDisplay(state) {
        return state.helpDisplay;
    },
    getResultBarHelpClosed(state) {
        return state.resultBarHelpClosed;
    },
    getExternalLink(state) {
        return state.externalLink;
    },
    getAppData(state) {
        return state.appData;
    },
    getCurrentSignIndex(state) {
        return state.currentSignIndex;
    },
    getTotalSignIndexes(state) {
        return state.totalSignIndexes;
    },
    getCurrentReflectionIndex(state) {
        return state.currentReflectionIndex;
    },
    getTotalReflectionIndexes(state) {
        return state.totalReflectionIndexes;
    },
    getBackButtonDisplay(state) {
        return state.backButtonDisplay;
    },
    getDebugScores(state) {
        return state.debugScores;
    },
    getDebugScoresStats(state) {
        return state.debugScoresStats;
    },
    getShowGdprPopup(state) {
        return state.showGdprPopup;
    },
}
