<template>
    <div class="intro-container">
        <h1>Linealen</h1>

        <overview-bar :coloured="true" class="mb-3"/>

        <p>
            Brug Linealen, når du ser de første tegn på, at et barn eller en ung ændrer adfærd eller har en adfærd, der
            gør dig bekymret
        </p>

        <div class="button-container">
            <roskilde-button border-type="full" size="lg" type="dark" width-type="fixed" v-on:click="begin">Begynd
            </roskilde-button>
        </div>
    </div>
</template>

<script>
import OverviewBar from '@/components/common/OverviewBar.vue';
import RoskildeButton from '@/components/buttons/RoskildeButton';
import * as MutationTypes from '@/store/mutation-types'
import {mapGetters} from 'vuex';

export default {
    name: 'intro-view',
    components: {
        RoskildeButton,
        OverviewBar,
    },
    methods: {
        begin() {
            this.$router.push('/setup/age');
        }
    },
    mounted() {
        this.$store.commit(MutationTypes.SET_OVERVIEW_BAR_SHOW, false);
        this.$store.commit(MutationTypes.SET_RESULT_BAR_SHOW, false);
        this.$store.commit(MutationTypes.SET_HEADER_TITLE_SHOW, false);
        this.$store.commit(MutationTypes.SET_EXTERNAL_LINK, null);
    },
    computed: {
        ...mapGetters({
            expectedNextStep: 'getExpectedNextStep',
        }),
    }
}
</script>

<style lang="scss" scoped>
.intro-container {
    @include alignCenter(true);
}

h1 {
    font-weight: 900;
    font-size: 60px;
    text-align: center;
    color: $black;
    margin-bottom: 60px;
}

p {
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    margin: 0 auto;
    max-width: 450px;
    color: $gray-dark;

    margin-top: 50px;
}

.button-container {
    text-align: center;

    margin-top: 90px;
}
</style>
