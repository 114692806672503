import Vue from 'vue'
import * as MutationTypes from '@/store/mutation-types'

export default {
    [MutationTypes.SET_REGISTRATION_AGE_GROUP]: (state, registrationAgeGroup) => {
        Vue.set(state, 'registrationAgeGroup', registrationAgeGroup);
    },
    [MutationTypes.SET_REGISTRATION_FUNCTION]: (state, registrationFunction) => {
        Vue.set(state, 'registrationFunction', registrationFunction);
    },
    [MutationTypes.SET_REGISTRATION_THEMES]: (state, registrationThemes) => {
        Vue.set(state, 'registrationThemes', registrationThemes);
    },
    [MutationTypes.ANSWER_QUESTION]: (state, data) => {
        Vue.set(state.questionAnswers, data.uid, data.answer);
    },
}
