<template>
    <div>
        <overview-bar :coloured="true" class="mb-3"/>
        <overview-bar class="mb-3"/>
        <overview-bar active="signs" class="mb-3"/>
        <overview-bar active="reflections" class="mb-3"/>
        <overview-bar active="summary" class="mb-3"/>

        <div>
            <external-link href="https://www.google.com"/>
        </div>
        <div>
            <external-link href="https://www.google.com" label="THIS IS GOOGLE!"/>
        </div>

        <div>
            <reflection-checkbox-container :checkboxes="reflectionCheckboxes"/>
        </div>

        <help-box submitText="OK" text="This is some text for the box and i hope it works"/>
    </div>
</template>

<script>
import OverviewBar from '@/components/common/OverviewBar.vue';
import ResultBar from '@/components/result-bar/ResultBar.vue';
import ExternalLink from '../../components/common/ExternalLink';
import ReflectionCheckbox from '../../components/input/ReflectionCheckbox';
import ReflectionCheckboxContainer from '../../components/input/ReflectionCheckboxContainer';
import HelpBox from '../../components/help-box/index';

export default {
    name: 'dev-components',
    components: {
        HelpBox,
        ReflectionCheckboxContainer,
        ReflectionCheckbox,
        ExternalLink,
        OverviewBar,
        ResultBar,
    },
    data() {
        return {
            reflectionCheckboxes: [
                {
                    "label": "Barnet har en positiv selvf\u00f8lelse",
                    "value": -4
                },
                {
                    "label": "Barnet har lyst til at l\u00e6re",
                    "value": -3
                },
                {
                    "label": "Barnet kan regulere sine f\u00f8lelser",
                    "value": -2
                },
                {
                    "label": "Barnet viser aktivt interesse for omgivelserne",
                    "value": -1
                },
                {
                    "label": "Barnet har en fysisk eller psykisk funktionsneds\u00e6ttelse",
                    "value": 0
                }
            ]
        }
    }
}
</script>
