<template>
    <div class="summary-row-container">
        <div :class="['summary-row-' + (isChild ? 'child' : 'main'), debugScores ? 'summary-row-debug-scores' : '']" class="summary-row summary-row-sign">
            <classifier-ball :type="classification" size="sm"/>
            <p>{{ title }}</p>
            <div v-if="debugScores" class="summary-row-score">{{ score }}</div>
            <div v-if="debugScores" class="summary-row-score-modifiers">
                <div>
                    <strong>Base score: {{ question.confirmation.scoreModification }}</strong><br />
                </div>
                <div v-if="question.sliders.often">
                    <strong>[Often: {{ getOftenAnswerString(answer && answer.slider ? answer.slider.often : null) }}]</strong> {{ getOftenAnswerString(0) }}: {{ question.often.rareBaseScore }} / {{ getOftenAnswerString(-2) }}: {{ question.often.oftenBaseScore}}<br />
                </div>
                <div v-if="question.sliders.worried">
                    <strong>[Worried: {{ getWorriedAnswerString(answer && answer.slider ? answer.slider.worried : null) }}]</strong> {{ getWorriedAnswerString(0) }}: {{ question.worried.notWorriedModifier }} / {{ getWorriedAnswerString(-1) }}: {{ question.worried.moderatelyWorriedModifier}} / {{ getWorriedAnswerString(-2) }}: {{ question.worried.veryWorriedModifier}}
                </div>
            </div>
        </div>
        <div v-if="childQuestions && childQuestions.length" class="summary-row-children">
            <summary-row-sign v-for="childQuestion of childQuestions" :key="childQuestion.uid" :isChild="true"
                              :row="childQuestion"/>
        </div>
    </div>
</template>

<script>
import ClassifierBall from './ClassifierBall';
import RoskildeButton from '@/components/buttons/RoskildeButton.vue';
import Config from '@/config';
import {mapGetters} from "vuex";
import {getOftenLabel, getWorriedLabel} from "../../utils/answers";

export default {
    name: 'summary-row-sign',
    components: {
        ClassifierBall,
        RoskildeButton
    },
    props: {
        type: {
            type: String
        },
        row: {
            type: Object
        },
        isChild: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters({
            debugScores: 'getDebugScores',
        }),

        title() {
            return this.question.question + (Config.showResultScore ? ' (score: ' + this.score + ')' : '');
        },
    },
    data() {
        return {
            question: this.row.question,
            childQuestions: this.row.childQuestions || [],
            classification: this.row.classification,
            answer: this.row.answer,
            score: this.row.score,
        }
    },
    methods: {
        isNaN(number) {
            return isNaN(number);
        },
        getOftenAnswerString(often) {
            return getOftenLabel(often);
        },
        getWorriedAnswerString(worried) {
            return getWorriedLabel(worried);
        }
    },
};
</script>

<style lang="scss" scoped>
.summary-row-container {
    position: relative;
}

.summary-row {
    position: relative;
    background: $gray-lighter;
    border-radius: 4px;
    padding: 10px 50px 10px 15px;
    color: #000000;
    min-height: 60px;

    &.summary-row-debug-scores {
        margin-bottom: 150px;
    }

    .classifier-ball {
        position: absolute;
        top: 5px;
        left: 5px;
    }

    p {
        text-align: left;
        padding-right: 150px;
        @include alignCenterY();
    }

    &.summary-row-main {
        margin-top: 10px;

        p {
            font-size: 18px;
            line-height: 22px;
            font-weight: 500;
        }
    }

    &.summary-row-child {
        p {
            font-size: 16px !important;
            font-weight: 0;
        }
    }

    .button-container {
        position: absolute;
        right: 5px;
        @include alignCenterY();
    }
}

.summary-row-children {
    padding-left: 50px;
}

.summary-row-score-modifiers {
    position: absolute;
    top: 50px;
    right: -25px;
    width: 700px;
    padding: 5px;
    color: white;
    background: #2e427e;
    border: 1px solid black;
}

.summary-row-score {
    position: absolute;
    top: 50%;
    right: -25px;
    width: 50px;
    height: 50px;
    background: #2e427e;
    color: white;
    font-weight: bold;
    line-height: 50px;
    text-align: center;
    border-radius: 25px 25px 0 0;
    transform: translateY(-50%);
}
</style>
