import Vue from 'vue'
import * as MutationTypes from '@/store/mutation-types'

export default {
    [MutationTypes.SET_HEADER_TITLE_SHOW]: (state, headerTitleShow) => {
        Vue.set(state, 'headerTitleShow', headerTitleShow);
    },
    [MutationTypes.SET_OVERVIEW_BAR_SHOW]: (state, overviewBarShow) => {
        Vue.set(state, 'overviewBarShow', overviewBarShow);
    },
    [MutationTypes.SET_OVERVIEW_BAR_ACTIVE_STEP]: (state, overviewBarActiveStep) => {
        Vue.set(state, 'overviewBarActiveStep', overviewBarActiveStep);
    },
    [MutationTypes.SET_RESULT_BAR_SHOW]: (state, resultBarShow) => {
        Vue.set(state, 'resultBarShow', resultBarShow);
    },
    [MutationTypes.SET_RESULT_BAR_WITH_PICKER]: (state, resultBarWithPicker) => {
        Vue.set(state, 'resultBarWithPicker', resultBarWithPicker);
    },
    [MutationTypes.SET_RESULT_BAR_INTERACTIVE]: (state, resultBarInteractive) => {
        Vue.set(state, 'resultBarInteractive', resultBarInteractive);
    },
    [MutationTypes.SET_RESULT_BAR_PICKER_VALUE]: (state, resultBarPickerValue) => {
        Vue.set(state, 'resultBarPickerValue', resultBarPickerValue);
    },
    [MutationTypes.SET_RESULT_BAR_THREAT_LEVEL]: (state, threatLevel) => {
        Vue.set(state, 'threatLevel', threatLevel);
    },
    [MutationTypes.SET_RESULT_BAR_THREAT_BIAS]: (state, threatBias) => {
        Vue.set(state, 'threatBias', threatBias);
    },
    [MutationTypes.SET_RESULT_BAR_THREAT_DISPLAY]: (state, threatDisplay) => {
        Vue.set(state, 'threatDisplay', threatDisplay);
    },
    [MutationTypes.SET_RESULT_BAR_HELP_DISPLAY]: (state, helpDisplay) => {
        Vue.set(state, 'helpDisplay', helpDisplay);
    },
    [MutationTypes.SET_RESULT_BAR_HELP_CLOSED]: (state, resultBarHelpClosed) => {
        Vue.set(state, 'resultBarHelpClosed', resultBarHelpClosed);
    },
    [MutationTypes.SET_EXTERNAL_LINK]: (state, externalLink) => {
        Vue.set(state, 'externalLink', externalLink);
    },
    [MutationTypes.SET_CURRENT_SIGN_INDEX]: (state, currentSignIndex) => {
        if (currentSignIndex > 1000) {
            currentSignIndex = null; // Anything over 1000 is invalid
        }

        Vue.set(state, 'currentSignIndex', currentSignIndex);
    },
    [MutationTypes.SET_TOTAL_SIGN_INDEXES]: (state, totalSignIndexes) => {
        Vue.set(state, 'totalSignIndexes', totalSignIndexes);
    },
    [MutationTypes.SET_CURRENT_REFLECTION_INDEX]: (state, currentReflectionIndex) => {
        if (currentReflectionIndex > 1000) {
            currentReflectionIndex = null; // Anything over 1000 is invalid
        }

        Vue.set(state, 'currentReflectionIndex', currentReflectionIndex);
    },
    [MutationTypes.SET_TOTAL_REFLECTION_INDEXES]: (state, totalReflectionIndexes) => {
        Vue.set(state, 'totalReflectionIndexes', totalReflectionIndexes);
    },
    [MutationTypes.SET_BACK_BUTTON_DISPLAY]: (state, backButtonDisplay) => {
        Vue.set(state, 'backButtonDisplay', !!backButtonDisplay);
    },
    [MutationTypes.SET_DEBUG_SCORES]: (state, debugScores) => {
        Vue.set(state, 'debugScores', !!debugScores);
    },
    [MutationTypes.SET_DEBUG_SCORES_STATS]: (state, debugScoresStats) => {
        Vue.set(state, 'debugScoresStats', debugScoresStats || null);
    },
    [MutationTypes.SET_SHOW_GDPR_POPUP]: (state, showGdprPopup) => {
        Vue.set(state, 'showGdprPopup', !!showGdprPopup);
    },
}
