<template>
    <img ref="image" :class="isLoaded ? 'img-loaded' : ''" :src="src" draggable="false" v-on:load="onLoad"/>
</template>

<script>
export default {
    name: 'no-drag-image',
    props: {
        src: {
            // example @/assets/logo.png
            type: String
        },
        scale: {
            default: 1
        }
    },
    data() {
        return {
            isLoaded: false,
        };
    },
    methods: {
        onLoad() {
            let scale = this.scale;

            if (scale === 'fit') {
                let image = this.$refs.image || null;

                if (image) {
                    // Special case
                    image.style.width = '100%';
                    image.style.height = '100%';
                }

                this.isLoaded = true;
                this.$emit('load', image);

                return;
            }

            if (scale === 'normal') {
                scale = .7;
            } else if (scale === 'small') {
                scale = .6;
            }

            if (typeof (scale) !== 'number') {
                scale = parseFloat(scale);
            }

            if (!scale) {
                scale = 1;
            }

            let image = this.$refs.image || null;

            if (image && scale !== 1) {
                let currentWidth = image.naturalWidth;
                let currentHeight = image.naturalHeight;

                image.style.width = (currentWidth * scale) + 'px';
                image.style.height = (currentHeight * scale) + 'px';
            }

            this.isLoaded = true;
            this.$emit('load', image);
        }
    }
}
</script>

<style lang="scss" scoped>
img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;

    /* This stops dragging in chrome */
    -webkit-user-drag: none;

    opacity: 0;

    &.img-loaded {
        opacity: 1;
    }
}
</style>
