/**
 * Strongly inspired (and partly copied) from console.badge
 *
 * @npm https://www.npmjs.com/package/console-badge
 * @git https://github.com/dennis47528/console-badge#readme
 */

const optionsDefaults = {
    mode: 'default',
    leftTextColor: '#ffffff',
    rightTextColor: '#1a1a1a',
    leftBgColor: '#555555',
    rightBgColor: '#ffc107',
};

function getOptions(options) {
    let optionsType = typeof options;

    if (optionsType === 'undefined') {
        options = {};
    } else if (optionsType === 'string') {
        options = {
            mode: optionsType,
        };
    }

    for (let prop in optionsDefaults) {
        if (!options.hasOwnProperty(prop)) {
            options[prop] = optionsDefaults[prop];
        }
    }

    return options;
}

function browserSupportsStyledConsole() {
    let agent = navigator.userAgent.toLowerCase();

    return /(chrome|firefox|safari)/.test(agent);
}

function getStyles(options) {
    let leftBoxStyle = [
        'background-color: ' + options.leftBgColor,
        'color: ' + options.leftTextColor
    ];

    let rightBoxStyle = [
        'background-color: ' + options.rightBgColor,
        'color: ' + options.rightTextColor
    ];

    let shieldsioFontFamily = 'font-family: DejaVu Sans,Verdana,Geneva,sans-serif';
    let shieldsioTextShadow = 'text-shadow: 0 1px 0 rgba(1, 1, 1, 0.3)';
    let defaultPadding = 'padding: 16px 10px 16px 10px';
    let useSpacer = false;

    if (options.mode === 'shields.io') {
        leftBoxStyle.push(
            'padding: 3px 4px 3px 6px',
            shieldsioFontFamily,
            shieldsioTextShadow
        );

        rightBoxStyle.push(
            'padding: 3px 6px 3px 4px',
            'border-radius: 0 3px 3px 0',
            shieldsioFontFamily,
            shieldsioTextShadow
        );

        useSpacer = true;
    } else {
        leftBoxStyle.push(
            defaultPadding
        );

        rightBoxStyle.push(
            defaultPadding
        );

        useSpacer = false;
    }

    let spacerStyle = ['background-color: transparent'].join(';');

    return {
        left: leftBoxStyle.join(';'),
        right: rightBoxStyle.join(';'),
        spacer: spacerStyle,
        useSpacer: useSpacer
    };
}

export function getObjBadge(obj, options) {
    options = getOptions(options);

    if (browserSupportsStyledConsole()) {
        let styles = getStyles(options);

        let textParts = [];
        let textStyles = [];

        for (let key in obj) {
            textParts.push(`%c ${key} %c ${obj[key]} %c`);

            textStyles.push(styles.left);
            textStyles.push(styles.right);
            textStyles.push(styles.spacer);
        }

        let returnValues = [textParts.join(styles.useSpacer ? ' ' : '')];

        for (let textStyle of textStyles) {
            returnValues.push(textStyle);
        }

        return returnValues;
    } else {
        // Can't style it, so we're just returning it raw
        let objParts = [];

        for (let key in obj) {
            objParts.push(key + ' - ' + obj[key]);
        }

        return [objParts.join(' | ')];
    }
}

export function getBadge(options) {
    options = getOptions(options);

    if (browserSupportsStyledConsole()) {
        let styles = getStyles(options);

        return [
            '\n\n %c ' + options.leftText + ' %c ' + options.rightText + ' %c \n\n\n',
            styles.left,
            styles.right,
            styles.spacer
        ];
    } else {
        // Can't style it, so we're just returning it raw
        return [options.leftText + ' - ' + options.rightText];
    }
}

export function logObj(obj, options) {
    console.log.apply(this, getObjBadge(obj, options));
}

export function warnObj(obj, options) {
    console.warn.apply(this, getObjBadge(obj, options));
}

export function errorObj(obj, options) {
    console.error.apply(this, getObjBadge(obj, options));
}

export function infoObj(obj, options) {
    console.info.apply(this, getObjBadge(obj, options));
}


export function log(options) {
    console.log.apply(this, getBadge(options));
}

export function warn(options) {
    console.warn.apply(this, getBadge(options));
}

export function error(options) {
    console.error.apply(this, getBadge(options));
}

export function info(options) {
    console.info.apply(this, getBadge(options));
}

export function logAppInfo() {
    let build = process.env.VUE_APP_BUILD_ENVIRONMENT || 'unknown';
    let version = process.env.PACKAGE_VERSION || 'N/A';
    let buildTime = process.env.BUILD_TIME || 'unknown';

    logObj({
        'Build': build,
        'Version': version,
        'Build time': buildTime,
    }, {
        mode: 'shields.io',
    });
}