<template>
    <div class="summary-row-container">
        <div class="summary-row summary-row-reflection">
            <classifier-ball v-if="classification" :type="classification" size="sm"/>
            <h5>{{ title }}</h5>
            <reflection-checkbox-container :checkboxes="answers" :value="userAnswers" type="summary"/>
        </div>
    </div>
</template>

<script>
import ClassifierBall from './ClassifierBall';
import RoskildeButton from '@/components/buttons/RoskildeButton.vue';
import ReflectionCheckbox from '@/components/input/ReflectionCheckbox';
import ReflectionCheckboxContainer from '../input/ReflectionCheckboxContainer';
import Config from '@/config';

export default {
    name: 'summary-row-reflection',
    components: {
        ReflectionCheckboxContainer,
        ReflectionCheckbox,
        ClassifierBall,
        RoskildeButton
    },
    props: {
        type: {
            type: String
        },
        row: {
            type: Object
        }
    },
    data() {
        return {
            reflection: '',
            answers: [],
            classification: null,
            userAnswers: [],
            score: null
        }
    },
    computed: {
        title() {
            return this.reflection + (Config.showResultScore ? ' (score: ' + this.score + ')' : '');
        }
    },
    mounted() {
        this.recalculate();
    },
    methods: {
        recalculate() {
            let answers = [];

            for (let answer of this.row.question.answers) {
                answers.push({
                    label: answer.text,
                    value: answer.scoreModification
                });
            }

            this.answers = answers;
            this.reflection = this.row.question.reflection;
            this.userAnswers = this.row.answer || [];
            this.score = this.row.score;
            this.classification = this.row.classification;
        }
    },
    watch: {
        row: {
            deep: true,
            handler() {
                this.recalculate();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.summary-row {
    position: relative;
    background: $gray-lighter;
    border-radius: 4px;
    padding: 10px 50px 10px 15px;
    color: #000000;
    min-height: 60px;

    .classifier-ball {
        display: inline-block;
        position: relative;
        top: -2px;
    }

    h5 {
        display: inline-block;
    }

    p {
        text-align: left;
        padding-right: 150px;
        @include alignCenterY();
    }

    &.summary-row-main {
        margin-top: 10px;

        p {
            font-size: 18px;
            line-height: 22px;
            font-weight: 500;
        }
    }

    &.summary-row-child {
        p {
            font-size: 16px;
            line-height: 19px;
        }
    }

    .button-container {
        position: absolute;
        right: 5px;
        @include alignCenterY();
    }
}

.summary-row-children {
    padding-left: 50px;
}

.reflection-checkbox {
    margin: 5px auto auto auto !important;
}

h5 {
    font-weight: bold;
    font-size: 22px;
}
</style>
