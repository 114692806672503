import * as ScoreCalculator from '@/utils/scoreCalculator';

export function getAllQuestionsInfo(signs, registrationAgeGroup, registrationThemes) {
    if (!registrationThemes) {
        console.error('No registration themes');

        registrationThemes = [];
    }

    let allQuestionUids = [];
    let allQuestions = {};

    if (!registrationAgeGroup) {
        console.error('No registration themes');
    } else {
        if (signs.hasOwnProperty(registrationAgeGroup)) {
            let signData = signs[registrationAgeGroup];

            for (let i = 0, len = signData.questionUids.length; i < len; i++) {
                let questionUid = signData.questionUids[i];
                let question = signData.questions[questionUid];

                if (registrationThemes.includes(question.theme)) {
                    allQuestionUids.push(questionUid);
                    allQuestions[questionUid] = question;
                }
            }
        } else {
            console.error('Could not find signs for age group', registrationAgeGroup);
        }
    }

    console.log('allQuestionUids', allQuestionUids);
    console.log('questionUids', allQuestions);

    return {
        questionUids: allQuestionUids,
        questions: allQuestions
    };
}

export function getFilteredQuestions(allQuestionUids, allQuestions, questionAnswers) {
    let questions = [];

    for (let questionUid of allQuestionUids) {
        let question = allQuestions[questionUid];

        let useQuestion = false;

        if (question.parent) {
            let parentQuestion = allQuestions[question.parent];

            if (parentQuestion) {
                let parentQuestionAnswerUid = ScoreCalculator.getAnswerUid(parentQuestion.uid, 'signs', 'yesNo');

                // If the question has a parent, let's make sure that the parent has been answered with a confirmation
                if (questionAnswers.hasOwnProperty(parentQuestionAnswerUid)) {
                    if (questionAnswers[parentQuestionAnswerUid] === parentQuestion.confirmation.answer) {
                        useQuestion = true;
                    } else {
                        console.log('Not going to use sub question', question, 'because parent question',
                            parentQuestion, 'had the wrong answer',
                            questionAnswers[parentQuestionAnswerUid], 'vs',
                            parentQuestion.confirmation.answer);
                    }
                } else {
                    console.log('Could not find parent question answer by uid', parentQuestionAnswerUid);
                }
            } else {
                console.error('Could not find parent question by uid', question.parent);
            }
        } else {
            useQuestion = true;
        }

        if (useQuestion) {
            questions.push(question);
        }
    }

    return questions;
}