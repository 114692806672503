<template>
    <div :class="'classifier-' + type" class="classifier">
        <classifier-ball :type="type">{{ count }}</classifier-ball>
        <label>{{ typeLabel }}</label>
    </div>
</template>

<script>
import ClassifierBall from './ClassifierBall';

export default {
    name: 'classifier',
    components: {
        ClassifierBall
    },
    props: {
        count: {
            default: 0
        },
        type: {
            type: String,
            default: 'unknown'
        },
        size: {
            type: String,
            default: 'lg'
        }
    },
    computed: {
        typeLabel() {
            switch (this.type) {
                case 'critical':
                    return 'Meget bekymret';
                case 'caution':
                    return 'Moderat bekymret';
                case 'moderate':
                    return 'Begyndende bekymret';
                case 'positive':
                    return 'Slet ikke bekymret';
            }

            return 'Ukendt';
        }
    }
};
</script>

<style lang="scss" scoped>
.classifier {
    display: inline-block;
    margin-right: 10px;
}

.classifier-critical .count {
    background: $classifier-red;
}

.classifier-caution .count {
  background: $classifier-orange;
}

.classifier-moderate .count {
    background: $classifier-yellow;
}

.classifier-positive .count {
    background: $classifier-green;
}

.count {
    border-radius: 12px;
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 5px;
    color: white;
}

label {
    color: $gray-dark;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
}
</style>
