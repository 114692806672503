<template>
    <div :class="[currentPage === 'summary' ? '' : 'game-container']" class="signs-container">
        <transition-group :duration="transitionDuration" :name="transitionName">
            <div v-if="currentPage === 'start'" :key="'start'" class="signs-intro page-slide-child">
                <div class="question-container">
                    <h3 class="mb-5">Tegn</h3>
                    <p>Du vil blive præsenteret for en række tegn, som kan være tegn på begyndende mistrivsel. Tag
                        stilling til, om du har observeret disse tegn.</p>
                </div>
                <div class="button-container">
                    <roskilde-button borderType="full" size="lg" type="white" widthType="fixed"
                                     v-on:click="showFirstSigns">Vis første tegn
                    </roskilde-button>
                </div>
            </div>
            <div v-if="currentPage === 'questions' && currentQuestion" :key="questionIndex" class="page-slide-child">
                <div class="question-container">
                    <div v-if="currentTheme" class="parent-question-info">
                        <strong>{{ currentTheme }}</strong>
                    </div>

                    <div v-if="currentQuestionParent" class="parent-question-info">
                        <strong>Hovedtegn</strong>
                        <p>{{ currentQuestionParent.question }}</p>
                    </div>

                    <p class="observation-header">Jeg har observeret, at..</p>

                    <p class="question">
                        {{ currentQuestion.question }}
                    </p>

                    <transition-expand>
                        <div v-if="currentQuestionPhase === 'slider' && currentQuestion.sliders.often && sliderType === 'often'"
                             class="question-slider">
                            <div class="slider-container">
                                <div class="slider-row">
                                    <strong>Hvor ofte er det gældende?</strong>
                                    <div class="slider-button-container">
                                        <roskilde-button
                                            v-for="(oftenAnswerKey, i) in oftenAnswerKeys"
                                            :key="i"
                                            :canToggle="true"
                                            :class="i > 0 ? 'ml-2' : ''"
                                            :text="oftenAnswers[oftenAnswerKey]"
                                            :type="answerToString(oftenAnswer) === oftenAnswerKey ? 'dark' : 'white'"
                                            :value="answerToString(oftenAnswer) === oftenAnswerKey"
                                            borderType="full"
                                            size="xs"
                                            toggleType="blue"
                                            widthType="fixed-small"
                                            v-on:click.native="answerOften(oftenAnswerKey)"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition-expand>
                    <transition-expand>
                        <div v-if="currentQuestionPhase === 'slider' && currentQuestion.sliders.worried && sliderType === 'worried'"
                             class="question-slider">
                            <div class="slider-container">
                                <div class="slider-row">
                                    <strong>Hvor bekymret er du?</strong>
                                    <div class="slider-button-container">
                                        <roskilde-button
                                            v-for="(worriedAnswerKey, i) in worriedAnswerKeys"
                                            :key="i"
                                            :canToggle="true"
                                            :class="i > 0 ? 'ml-2' : ''"
                                            :text="worriedAnswers[worriedAnswerKey]"
                                            :type="answerToString(worriedAnswer) === worriedAnswerKey ? 'dark' : 'white'"
                                            :value="answerToString(worriedAnswer) === worriedAnswerKey"
                                            borderType="full"
                                            size="xs"
                                            toggleType="blue"
                                            widthType="fixed-small"
                                            v-on:click.native="answerWorried(worriedAnswerKey)"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition-expand>
                </div>

                <div v-if="currentQuestion" class="button-container">
                    <div v-if="currentQuestionPhase === 'yesNo'">
                        <roskilde-button
                            :text="'Nej'"
                            :type="currentQuestionAnswer === 0 ? 'blue' : 'white'"
                            borderType="full-left"
                            size="xl"
                            widthType="auto"
                            v-on:click="answerQuestion(0)"
                        />
                        <roskilde-button
                            :text="'Ja'"
                            :type="currentQuestionAnswer === 1 ? 'blue' : 'white'"
                            borderType="full-right"
                            class="ml-2"
                            size="xl"
                            widthType="auto"
                            v-on:click="answerQuestion(1)"
                        />
                    </div>
                </div>
            </div>
        </transition-group>
        <div v-if="currentPage === 'summary'" :key="'summary'" class="summary page-slide-child">
            <summary-container :questionAnswers="questionAnswers" :signsQuestions="questions" type="signs"
                               v-on:startReflection="startReflection"/>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import RoskildeButton from '@/components/buttons/RoskildeButton.vue';
import SummaryContainer from '@/components/summary/SummaryContainer.vue'
import TransitionExpand from '@/components/common/TransitionExpand.vue';
import * as MutationTypes from '@/store/mutation-types'
import * as ScoreCalculator from '@/utils/scoreCalculator';
import {getAnswerData} from "../../utils/answers";
import {getAllQuestionsInfo, getFilteredQuestions} from "../../utils/signQuestionCalculator";

export default {
    name: 'signs-view',
    components: {
        RoskildeButton,
        SummaryContainer,
        TransitionExpand,
    },
    beforeMount() {
        if (!this.registrationAgeGroup) {
            this.$router.push('/setup');

            return;
        }
    },
    beforeDestroy() {
        const signsSecondsTaken = this.$gaAnalytics.timerGetSeconds('signs');

        this.$gaAnalytics.events.signsEnd(signsSecondsTaken);
        this.$gaAnalytics.events.signsEndCount(this.questions.length, signsSecondsTaken);

        this.$gaAnalytics.timerEnd('signs');
    },
    data() {
        let answersData = getAnswerData();

        return {
            allQuestionUids: [],
            allQuestions: {},

            questions: [],

            transitionName: null,
            transitionDuration: 300,

            /*oftenAnswers: {
                '2': 'Færre end 1 gang om måneden',
                '1': 'Flere gange på en måned',
                '0': 'Få gange ugenligt',
                '-1': 'Dagligt',
                '-2': 'Flere gange dagligt',
            },*/
            oftenAnswers: answersData.oftenAnswers,
            oftenAnswerKeys: answersData.oftenAnswerOrder,

            worriedAnswers: answersData.worriedAnswers,
            worriedAnswerKeys: answersData.worriedAnswerOrder,

            oftenAnswer: null,
            worriedAnswer: null,

            currentQuestionPhase: null,

            sliderType: null,

            clickNextTimer: null,
        }
    },
    mounted() {
        this.$store.commit(MutationTypes.SET_OVERVIEW_BAR_SHOW, true);
        this.$store.commit(MutationTypes.SET_RESULT_BAR_SHOW, true);
        this.$store.commit(MutationTypes.SET_HEADER_TITLE_SHOW, true);
        this.$store.commit(MutationTypes.SET_OVERVIEW_BAR_ACTIVE_STEP, 'signs');
        this.$store.commit(MutationTypes.SET_EXTERNAL_LINK, null);
        this.$store.commit(MutationTypes.SET_CURRENT_SIGN_INDEX, null);
        this.$store.commit(MutationTypes.SET_CURRENT_REFLECTION_INDEX, null);
        this.$store.commit(MutationTypes.SET_RESULT_BAR_THREAT_DISPLAY, false);

        let allQuestionsInfo = getAllQuestionsInfo(
            this.appData.signs,
            this.registrationAgeGroup,
            this.registrationThemes
        );

        this.allQuestionUids = allQuestionsInfo.questionUids;
        this.allQuestions = allQuestionsInfo.questions;

        this.recalculateQuestions();
        this.recalculateRoutePhase(this.$router.currentRoute);
        this.recalculateExistingAnswers();

        this.$gaAnalytics.events.signsStart();
        this.$gaAnalytics.events.signsStartCount(this.questions.length);

        this.$gaAnalytics.timerStart('signs');
    },
    methods: {
        answerToString(number) {
            let type = typeof(number);

            if (type === 'undefined') {
                return null;
            }

            return number.toString();
        },
        startReflection() {
            this.$router.push('/reflections');
        },
        clickNext() {
            if (!this.canContinue) {
                return;
            }

            if (this.sliderType === 'often' && this.currentQuestion.sliders.worried) {
                this.$router.push(`/signs/questions/${this.questionIndex + 1}/slider/worried`);

                return;
            }
            console.log('answerQuestion', {
                worried: parseInt(this.worriedAnswer, 10),
                often: parseInt(this.oftenAnswer, 10),
            });

            this.answerQuestion({
                worried: parseInt(this.worriedAnswer, 10),
                often: parseInt(this.oftenAnswer, 10),
            });
        },
        recalculateQuestions() {
            let questions = getFilteredQuestions(this.allQuestionUids, this.allQuestions, this.questionAnswers);

            this.questions = questions;

            this.$store.commit(MutationTypes.SET_TOTAL_SIGN_INDEXES, questions.length);
        },
        getCurrentQuestionAnswerUid() {
            return this.getAnswerUid(this.currentQuestionUid, this.currentQuestionPhase);
        },
        getAnswerUid(uid, phase) {
            let answerUid = ScoreCalculator.getAnswerUid(uid, 'signs', phase);

            return answerUid;
        },
        clickNextSoon() {
            if (this.clickNextTimer) {
                clearTimeout(this.clickNextTimer);

                this.clickNextTimer = null;
            }

            this.clickNextTimer = setTimeout(() => {
                this.clickNext();
            }, 400);
        },
        answerOften(i) {
            console.log('answerOften', i);
            this.oftenAnswer = i;

            this.clickNextSoon();
        },
        answerWorried(i) {
            console.log('answerWorried', i);
            this.worriedAnswer = i;

            this.clickNextSoon();
        },
        answerQuestion(answer) {
            let answerUid = this.getCurrentQuestionAnswerUid();

            this.$store.commit(MutationTypes.ANSWER_QUESTION, {
                uid: answerUid,
                answer: answer
            });

            this.recalculateQuestions();

            let isConfirmingQuestion = this.currentQuestion.confirmation.answer === answer;

            console.log('answeransweranswer', answer, isConfirmingQuestion);

            if ((this.currentQuestion.sliders.often || this.currentQuestion.sliders.worried) && this.currentQuestionPhase === 'yesNo' && isConfirmingQuestion) {
                if (this.currentQuestion.sliders.often) {
                    this.$router.push(`/signs/questions/${this.questionIndex + 1}/slider/often`);
                } else if (this.currentQuestion.sliders.worried) {
                    this.$router.push(`/signs/questions/${this.questionIndex + 1}/slider/worried`);
                }

                return;
            }

            if (this.questionIndex < (this.questions.length - 1)) {
                if (this.currentQuestion.confirmation.popupWarning) {
                    this.$popup.open({
                        title: 'Advarsel!',
                        text: this.currentQuestion.confirmation.popupWarning,
                        choices: [
                            {
                                value: 'ok',
                                text: 'OK',
                            }
                        ]
                    }).then((choice) => {
                        this.$router.push('/signs/questions/' + (this.questionIndex + 2));
                    });
                } else {
                    this.$router.push('/signs/questions/' + (this.questionIndex + 2));
                }
            } else {
                this.$router.push('/signs/summary');
                /*this.$popup.open({
                    title: 'Direkte til overblik',
                    text: 'Vi anbefaler at hvert tegn overvejes i isolation, for at skabe det mest objektive grundlag for den videre Refleksion.',
                    choices: [
                        {
                            value: 'back',
                            text: 'Tilbage til tegn',
                        },
                        {
                            value: 'summary',
                            text: 'Fortsæt til overblik',
                        }
                    ]
                }).then((choice) => {
                    if (choice === 'summary') {
                        this.$router.push('/signs/summary');
                    } else if (choice === 'back') {
                        this.showFirstSigns();
                    }
                })*/
            }
        },
        showFirstSigns() {
            this.$router.push('/signs/questions/1');
        },
        getQuestionIndexFromRoute($route) {
            let questionIndex;

            if ($route.params.page === 'start') {
                questionIndex = -1;
            } else if ($route.params.page === 'summary') {
                questionIndex = 1000;
            } else if ($route.params.questionIndex !== null) {
                questionIndex = Math.max(0, parseInt($route.params.questionIndex, 10) - 1);
            }

            if (isNaN(questionIndex) || questionIndex < 0) {
                questionIndex = -1;
            }

            return questionIndex;
        },
        recalculateRoutePhase(route) {
            if (route && route.meta && route.meta.withSlider) {
                this.currentQuestionPhase = 'slider';
            } else {
                this.currentQuestionPhase = 'yesNo';
            }

            this.sliderType = route.meta.sliderType || null;
        },
        recalculateExistingAnswers() {
            if (this.currentQuestion && this.currentQuestion.sliders.worried) {
                this.worriedAnswer = this.currentQuestionAnswer.worried;
            } else {
                this.worriedAnswer = null;
            }

            if (this.currentQuestion && this.currentQuestion.sliders.often) {
                this.oftenAnswer = this.currentQuestionAnswer.often;
            } else {
                this.oftenAnswer = null;
            }

            console.log('recalculateExistingAnswers', {
                worriedAnswer: this.worriedAnswer,
                oftenAnswer: this.oftenAnswer,
            })
        }
    },
    computed: {
        ...mapGetters({
            appData: 'getAppData',
            expectedNextStep: 'getExpectedNextStep',
            registrationAgeGroup: 'getRegistrationAgeGroup',
            registrationThemes: 'getRegistrationThemes',
            questionAnswers: 'getQuestionAnswers',
        }),
        questionIndex() {
            let questionIndex = this.getQuestionIndexFromRoute(this.$route);

            return questionIndex >= 0 ? questionIndex : null;
        },
        currentTheme() {
            return this.currentQuestion ? this.currentQuestion.theme : null;
        },
        currentPage() {
            return this.$route.params.page || this.$route.meta.page || 'start';
        },
        currentQuestionUid() {
            return this.currentQuestion ? this.currentQuestion.uid : null;
        },
        currentQuestion() {
            return this.questions[this.questionIndex];
        },
        currentQuestionParent() {
            if (this.currentQuestion && this.currentQuestion.parent) {
                return this.allQuestions[this.currentQuestion.parent];
            }

            return null;
        },
        currentQuestionAnswer() {
            let questionAnswerKey = this.getAnswerUid(this.currentQuestionUid, this.currentQuestionPhase);

            if (!this.questionAnswers.hasOwnProperty(questionAnswerKey)) {
                if (this.currentQuestionPhase === 'yesNo') {
                    return -1;
                } else if (this.currentQuestionPhase === 'slider') {
                    return {worried: this.worriedAnswer, often: this.oftenAnswer};
                }
            }

            return this.questionAnswers[questionAnswerKey];
        },
        canContinue() {
            if (this.currentQuestion.sliders.worried && this.sliderType === 'worried' && this.worriedAnswer === null) {
                return false;
            }

            if (this.currentQuestion.sliders.often && this.sliderType === 'often' && this.oftenAnswer === null) {
                return false;
            }

            return true;
        },
    },
    watch: {
        questionIndex(to, from) {
            this.$store.commit(MutationTypes.SET_CURRENT_SIGN_INDEX, to);
        },
        '$route'(to, from) {
            let questionIndexFrom = this.getQuestionIndexFromRoute(from);
            let questionIndexTo = this.getQuestionIndexFromRoute(to);

            if (to.params.page === 'summary') {
                this.transitionName = null;
                this.transitionDuration = 0;
            } else {
                this.transitionDuration = 300;

                let questionFrom = this.questions[questionIndexFrom];
                let questionTo = this.questions[questionIndexTo];

                if (questionTo && questionTo.parent) {
                    this.transitionName = 'slide-up';
                } else if (questionIndexFrom === questionIndexTo) {
                    // Same question - might be changing phase
                    let fromPhase = 'yesNo';
                    let toPhase = 'yesNo';

                    if (from.meta && from.meta.withSlider) {
                        fromPhase = 'slider';
                    }

                    if (to.meta && to.meta.withSlider) {
                        toPhase = 'slider';
                    }

                    if (toPhase === 'slider' && fromPhase === 'yesNo') {
                        this.transitionName = 'slide-forward';
                    } else if (toPhase === 'yesNo' && fromPhase === 'slider') {
                        this.transitionName = 'slide-backward';
                    }
                } else if (questionIndexFrom < questionIndexTo) {
                    // Going forward
                    this.transitionName = 'slide-forward';
                } else if (questionIndexFrom > questionIndexTo) {
                    // Going backwards
                    this.transitionName = 'slide-backward';
                } else {
                    this.transitionName = null;
                }
            }
            console.log({
                transitionName: this.transitionName,
                transitionDuration: this.transitionDuration,
            });

            this.$popup.close();

            this.recalculateRoutePhase(to);
        },
        currentQuestion() {
            if (this.currentQuestion && this.currentQuestion.link && this.currentQuestion.link.url) {
                this.$store.commit(MutationTypes.SET_EXTERNAL_LINK, {
                    url: this.currentQuestion.link.url,
                    label: this.currentQuestion.link.label || null,
                });
            } else {
                this.$store.commit(MutationTypes.SET_EXTERNAL_LINK, null);
            }

            this.recalculateExistingAnswers();
        }
    }
}
</script>

<style lang="scss" scoped>
h3 {
    font-weight: bold;
    font-size: 50px;
    color: $gray-dark;
}

.signs-intro {
    strong {
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

.slider-row {
    margin-top: 50px;
}

.slider-button-container {
    white-space: nowrap;
    margin-top: 5px;

    .button {
        margin-top: 0;

        margin-left: 10px;

        &:first-child {
            margin-left: 0;
        }
    }
}

strong {
    color: $gray-dark;
    font-size: 22px;
    display: block;
}

p {
    color: $gray-dark;
    font-size: 18px;
    max-width: 600px;
    display: inline-block;
    font-family: Montserrat, sans-serif !important;
}

p.question {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;
}

.parent-question-info {
    strong {
        display: block;
        font-weight: bold;
        font-size: 20px;
        color: $gray;
        margin: 0;
    }

    p {
        font-size: 20px;
        color: $black;
        margin: 0;
    }

    border-bottom: 1px solid #BBBBBB;
    width: 535px;
    display: inline-block;
    margin: 0 auto 50px 0;
}

.question-container {
    //@include alignCenter(true);
}

.observation-header {
    clear: both;
    display: block;
    text-align: center;
    margin: 0 auto;
    font-size: 25px;
}

.page-slide-child {
    margin-top: 40px;
}
</style>
