let consoleCallbacks = [
    'warn', 'log', 'info', 'error', 'assert',
    'dir', 'table', 'group', 'groupEnd', 'time',
    'timeEnd', 'timeLog', 'trace', 'clear', 'count',
    'debug', 'dirxml', 'groupCollapsed',
];

if (!window.console) {
    window.console = {}
}

for (let callback of consoleCallbacks) {
    if (!window.console[callback]) {
        window.console[callback] = function () {
            return this
        }
    }
}

import gaAnalytics from "./mixins/gaAnalytics";
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/animate.css/animate.min.css'
import NoDragImage from '@/components/common/NoDragImage'
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
    config: {
        id: "G-Z5WVV5H2TH",
        params: {
            send_page_view: true
        }
    },
    pageTrackerScreenviewEnabled: true,
    appName: 'Linealen',
    useDebugger: true,
    enabled: false, // Not enabled by default, we enable this alter when we opt in
    bootstrap: false,
});

Vue.use(gaAnalytics);

Vue.config.productionTip = false

Vue.component('no-drag-image', NoDragImage)

require('@/utils/consoleBadges').logAppInfo()

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
