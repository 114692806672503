import axios from "axios";
import { saveAs } from 'file-saver';

export default function createPdf(data) {
    const pdfUsername = 'roskilde';
    const pdfPassword = 'iCtT7B<GRaw4q[Cs$R]@.Y?<:i$i0K85P]L5xF,1%o*D0$?SbC~]e?8NH].[reW(';

    const token = Buffer.from(`${pdfUsername}:${pdfPassword}`, 'utf8').toString('base64');

    data.devDebug = 1;

    const options = {
        headers: {
            'Authorization': `Basic ${token}`
        },
    };

    return axios.post('https://pdf.seriousgames.net/convert', data, options).then((response) => {
        if (response.status !== 200) {
            throw new Error(`PDF converter received a non 200 OK response (${response.status}`);
        }

        const responseData = response.data;

        if (!responseData.success) {
            let errorMessage = responseData.error || 'unknown';

            throw new Error(`PDF converter received a non-successful response (${errorMessage}`);
        }

        return responseData.url;
    });
}

export function savePdf(data) {
    return new Promise((resolve, reject) => {
        createPdf(data).then((pdfUrl) => {
            resolve(pdfUrl, false);

            return;

            let savedFile = false;

            try {
                const isFileSaverSupported = !!new Blob;

                if (isFileSaverSupported) {
                    saveAs(pdfUrl);

                    savedFile = true;

                    resolve(pdfUrl, true);
                }
            } catch (e) {
                console.log('Could not call FileSaver.saveAs', e);
            }

            if (!savedFile) {
                resolve(pdfUrl, false);
            }
        });
    })
}