<template>
    <div :class="[value ? 'reflection-checkbox-checked' : '', 'reflection-checkbox-type-' + type]"
         class="reflection-checkbox" v-on:click="toggle">
        <div class="checkbox-ball-container">
			<span class="checkbox-ball">
				<no-drag-image v-if="value" class="checkbox-ball-icon"
                               src="@/assets/components/reflection-checkbox/checked-icon.png"/>
			</span>
        </div>
        <div class="label-container">
            <label>{{ label }}</label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'reflection-checkbox',
    props: {
        type: {
            type: String,
            default: 'toggle'
        },
        value: {
            type: Boolean,
            default: false
        },
        label: {
            type: String
        }
    },
    methods: {
        toggle() {
            if (this.type === 'summary') {
                return;
            }

            this.$emit('input', !this.value);
        }
    }
}
</script>
<style lang="scss" scoped>
.reflection-checkbox {
    text-align: left;
    width: 500px;
    position: relative;
    margin-bottom: 10px !important;

    &, * {
        @include noSelect();
    }
}

.reflection-checkbox-type-toggle {
    margin: 0 auto;

    &, * {
        cursor: pointer;
        @include noSelect();
    }
}

.checkbox-ball-container {
    display: inline-block;
    width: 24px;
    margin-right: 10px;
}

.reflection-checkbox-checked {
    .checkbox-ball {
        background: $link;
        border-color: $link;
    }
}

.checkbox-ball {
    display: inline-block;
    border-radius: 12px;
    border: 2px solid $gray;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    left: 0;

    .checkbox-ball-icon {
        @include alignCenter();
    }
}

.label-container {
    display: inline-block;
    width: 400px;
}

label {
    position: relative;
    top: -2px;
    line-height: 24px;
    font-size: 22px;
    color: $gray-dark;
}
</style>
