<template>
    <div class="summary-container">
        <summary-container
            :compressedCode="compressedCode"
            :expandByDefault="true"
            :questionAnswers="questionAnswers"
            :reflectionsQuestions="reflectionsQuestions"
            :registrationAgeGroup="registrationAgeGroup"
            :registrationFunction="registrationFunction"
            :showHelp="true"
            :signsQuestions="questions"
            type="summary"
        />
    </div>
</template>

<script>
import RoskildeButton from '@/components/buttons/RoskildeButton.vue';
import * as MutationTypes from '@/store/mutation-types'
import FlowSteps from '@/enum/flow-steps'
import {mapGetters} from 'vuex';
import SummaryContainer from '@/components/summary/SummaryContainer.vue';
import {debounce} from 'throttle-debounce';
import config from '@/config';
import {getAllQuestionsInfo, getFilteredQuestions} from "../../utils/signQuestionCalculator";

const axios = require('axios').default;

export default {
    name: 'summary-view',
    components: {
        RoskildeButton,
        SummaryContainer,
    },
    beforeMount() {
        if (this.expectedNextStep !== FlowSteps.Summary) {
            this.$router.push('/summary');
        }
    },
    data() {
        return {
            allQuestionUids: [],
            allQuestions: {},

            questions: [],

            compressedCode: null,
            updateCodeApi: debounce(250, () => {
                axios.post(config.apiBaseUrl + '/save', {
                    resultBarPickerValue: this.resultBarPickerValue,
                    registrationAgeGroup: this.registrationAgeGroup,
                    registrationFunction: this.registrationFunction,
                    questionAnswers: this.questionAnswers
                }).then((response) => {
                    this.compressedCode = response.data.data.code;
                });
            })
        }
    },
    mounted() {
        this.$store.commit(MutationTypes.SET_EXTERNAL_LINK, null);
        this.$store.commit(MutationTypes.SET_OVERVIEW_BAR_SHOW, true);
        this.$store.commit(MutationTypes.SET_RESULT_BAR_SHOW, true);
        this.$store.commit(MutationTypes.SET_OVERVIEW_BAR_ACTIVE_STEP, 'summary');
        this.$store.commit(MutationTypes.SET_RESULT_BAR_WITH_PICKER, true);
        this.$store.commit(MutationTypes.SET_RESULT_BAR_INTERACTIVE, true);
        this.$store.commit(MutationTypes.SET_CURRENT_SIGN_INDEX, null);
        this.$store.commit(MutationTypes.SET_CURRENT_REFLECTION_INDEX, null);
        this.$store.commit(MutationTypes.SET_RESULT_BAR_THREAT_DISPLAY, true);
        this.$store.commit(MutationTypes.SET_RESULT_BAR_HELP_DISPLAY, true);

        let allQuestionsInfo = getAllQuestionsInfo(
            this.appData.signs,
            this.registrationAgeGroup,
            this.registrationThemes
        );

        this.allQuestionUids = allQuestionsInfo.questionUids;
        this.allQuestions = allQuestionsInfo.questions;

        this.recalculateQuestions();

        this.updateCompressedCode();
    },
    methods: {
        updateCompressedCode() {
            this.compressedCode = null;

            console.log('updatingCompressedCode');
            this.updateCodeApi();

            //this.compressedCode = DataCompressor.compressFromAgeGroup(this.resultBarPickerValue, this.registrationAgeGroup, this.questionAnswers);
        },
        recalculateQuestions() {
            this.questions = getFilteredQuestions(this.allQuestionUids, this.allQuestions, this.questionAnswers);
        },
    },
    computed: {
        ...mapGetters({
            appData: 'getAppData',
            expectedNextStep: 'getExpectedNextStep',
            registrationAgeGroup: 'getRegistrationAgeGroup',
            registrationFunction: 'getRegistrationFunction',
            questionAnswers: 'getQuestionAnswers',
            resultBarPickerValue: 'getResultBarPickerValue',
            registrationThemes: 'getRegistrationThemes',
        }),
        signsQuestions() {

            let signs = this.appData.signs;
            let questions = [];

            if (signs.hasOwnProperty(this.registrationAgeGroup)) {
                let signsAgeGroup = signs[this.registrationAgeGroup];

                for (let questionUid of signsAgeGroup['questionUids']) {
                    questions.push(signsAgeGroup['questions'][questionUid]);
                }
            } else {
                console.error('Could not find signs for age group', this.registrationAgeGroup);
            }

            return questions;
        },
        reflectionsQuestions() {
            let reflections = this.appData.reflections;
            let questions = [];

            if (reflections.hasOwnProperty(this.registrationAgeGroup)) {
                let reflectionsAgeGroup = reflections[this.registrationAgeGroup];

                for (let reflectionUid of reflectionsAgeGroup['reflectionUids']) {
                    questions.push(reflectionsAgeGroup['reflections'][reflectionUid]);
                }
            } else {
                console.error('Could not find reflections for age group', this.registrationAgeGroup);
            }

            return questions;
        },
        currentPage() {
            return this.$route.params.page || 'start';
        },
    },
    watch: {
        resultBarPickerValue() {
            this.updateCompressedCode();
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
