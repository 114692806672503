<template>
    <div class="data-class-row">
        <div>
            <h4>{{ type }}</h4>
        </div>
        <div>
            <input :value="value" class="form-control" v-on:input="updateValue"/>
            <p><strong>Binary: {{ binaryValue }}</strong></p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'segment-row',
    props: {
        dataClass: {
            type: Object
        }
    },
    data() {
        return {
            value: '',
            binaryValue: ''
        }
    },
    methods: {
        recalculateDataClassValue() {
            this.updateValue(this.dataClass.getValue());
        },
        updateValue(value) {
            if (value instanceof InputEvent) {
                value = value.data;
            }

            this.value = value;
            this.binaryValue = parseInt(this.value, 10).toString(2);
        }
    },
    computed: {
        disabled() {
            return ['InitByte', 'Version'].includes(this.type);
        },
        type() {
            return this.dataClass.getType();
        }
    },
    watch: {
        dataClass: {
            deep: true,
            handler() {
                this.recalculateDataClassValue();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.data-class-row {
    background: $gray;
    color: $black;
    padding: 10px;
    margin-bottom: 5px;
}
</style>