<template>
    <div class="button-group">
        <roskilde-button
            v-for="(checkbox, i) in checkboxes"
            :key="i"
            :canToggle="true"
            :class="i > 0 ? 'ml-2' : ''"
            :description="checkbox.description"
            :value="selectedCheckboxes[i]"
            borderType="partial"
            size="sm"
            toggleType="blue"
            type="light"
            widthType="auto"
            v-on:input="toggleCheckbox(i)"
        >{{ checkbox.label }}
        </roskilde-button>
    </div>
</template>

<script>
import RoskildeButton from '@/components/buttons/RoskildeButton.vue';

export default {
    name: 'roskilde-button-group',
    components: {RoskildeButton},
    props: {
        type: {
            type: String,
            default: 'toggle'
        },
        checkboxes: {
            type: Array
        },
        value: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            selectedCheckboxes: {}
        };
    },
    mounted() {
        this.resetSelectedCheckboxes();

        this.setSelectedCheckboxesFromValue(this.value);
    },
    methods: {
        setSelectedCheckboxesFromValue(values) {
            if (values === null) {
                values = [];
            } else if (values instanceof String) {
                values = [values];
            }

            let valuesString = [];

            for (let value of values) {
                valuesString.push(parseInt(value, 10));
            }

            for (let i = 0, len = this.checkboxes.length; i < len; i++) {
                let checkbox = this.checkboxes[i];

                let checkboxValueString = checkbox.value;

                if (valuesString.includes(i)) {
                    this.$set(this.selectedCheckboxes, i, true);
                } else {
                    this.$set(this.selectedCheckboxes, i, false);
                }
            }
        },
        toggleCheckbox(i) {
            i = i.toString();

            if (this.type === 'radio') {
                if (this.selectedCheckboxes[i]) {
                    // Radios can't be deselected - select another value if you need to

                    this.emitValues();

                    return;
                }

                this.resetSelectedCheckboxes();
            }

            this.$set(this.selectedCheckboxes, i, !this.selectedCheckboxes[i]);

            this.emitValues();
        },
        resetSelectedCheckboxes() {
            for (let i = 0, len = this.checkboxes.length; i < len; i++) {
                this.$set(this.selectedCheckboxes, i, false);
            }
        },
        emitValues() {
            let selectedValues = [];

            for (let key in this.selectedCheckboxes) {
                if (this.selectedCheckboxes[key]) {
                    selectedValues.push(parseInt(key, 10));
                }
            }

            this.$emit('input', selectedValues);
        },
    },
    watch: {
        checkboxes() {
            this.resetSelectedCheckboxes();
        },
        value() {
            this.setSelectedCheckboxesFromValue(this.value);
        }
    }
}
</script>


<style lang="scss" scoped>
.button-group {
    .button {
        margin-bottom: 10px;
    }
}
</style>
