let appData = require('../../../fixtures/app-data');
let bigInt = require('big-integer');
import * as DataClass from './dataClasses';
import * as DataStructure from './dataStructures';
import clone from 'clone';
import $store from '@/store';

import {ageGroupKeys} from '@/collections';

export default class DataCompressor {
    constructor(dataStructures = null, addFirstBits = true) {
        if (!dataStructures) {
            dataStructures = [];
        }

        if (addFirstBits) {
            // The data version
            dataStructures.unshift(new DataStructure.Version());

            // The initial data bit
            dataStructures.unshift(new DataStructure.InitBit());
        }

        this.dataStructures = dataStructures;
    }

    static initialize(dataCompressedString) {
        let initialDataCompressor = new DataCompressor();

        let decompressed = initialDataCompressor.decompress(dataCompressedString);

        console.log('decompressed', decompressed, dataCompressedString);
    }

    static fromStore() {
        let appData = $store.getters.getAppData;
        let registrationAgeGroup = $store.getters.getRegistrationAgeGroup;
        let questionAnswers = $store.getters.getQuestionAnswers;

        this.fromUserData(registrationAgeGroup, questionAnswers);
    }

    static getUidsFromAgeGroup(registrationAgeGroup) {

    }

    static decompressFromData(dataCompressedString, refObject) {
        let appData = $store.getters.getAppData;

        let initialDataCompressor = new DataCompressor([
            new DataStructure.ResultBarPickerValue(),
            new DataStructure.AgeGroup(),
        ]);

        let decompressed = initialDataCompressor.decompress(dataCompressedString);

        let resultBarPickerValue = decompressed[0].value;

        let registrationAgeGroupIndex = decompressed[1].value;
        let registrationAgeGroup = ageGroupKeys[registrationAgeGroupIndex];

        let signs = appData.signs[registrationAgeGroup];
        let reflections = appData.reflections[registrationAgeGroup];

        let structures = this.getDataStructureFromAgeGroup(registrationAgeGroup);

        let dataCompressor = new DataCompressor(structures);

        let data = dataCompressor.decompress(dataCompressedString);

        let i = 2;
        let questionAnswers = {};
        let calcSigns = [];
        let calcReflections = [];

        for (let questionUid of signs.questionUids) {
            let dataSegment = data[i];
            let question = signs.questions[questionUid];

            let yesNoUid = question.uid + '.yesNo';

            questionAnswers[yesNoUid] = dataSegment.value;

            if (question.hasSlider) {
                let sliderUid = question.uid + '.slider';

                questionAnswers[sliderUid] = {often: dataSegment.oftenIndex, worried: dataSegment.worryIndex};
            }

            calcSigns.push({
                sign: question,
                answer: questionAnswers[yesNoUid]
            });

            i++;
        }

        for (let reflectionUid of reflections.reflectionUids) {
            let dataSegment = data[i];
            let reflection = reflections.reflections[reflectionUid];

            questionAnswers[reflectionUid] = dataSegment.reflectionAnswers;

            calcReflections.push({
                reflection: reflection,
                answer: questionAnswers[reflectionUid],
            });

            i++;
        }

        return {
            questionAnswers: questionAnswers,
            registrationAgeGroup: registrationAgeGroup,
            resultBarPickerValue: resultBarPickerValue,
            signs: calcSigns,
            reflections: calcReflections
        };
    }

    static getDataStructureFromAgeGroup(registrationAgeGroup) {
        let appData = $store.getters.getAppData;
        let signs = appData.signs[registrationAgeGroup];
        let reflections = appData.reflections[registrationAgeGroup];

        let structures = [
            new DataStructure.ResultBarPickerValue(),
            new DataStructure.AgeGroup()
        ];

        console.log(appData);

        for (let questionUid of signs.questionUids) {
            let question = signs.questions[questionUid];

            if (question.hasSlider) {
                structures.push(new DataStructure.SignWithSlider());
            } else {
                structures.push(new DataStructure.Sign());
            }
        }

        for (let reflectionUid of reflections.reflectionUids) {
            structures.push(new DataStructure.Reflection());
        }

        return structures;
    }

    static compressFromAgeGroup(resultBarPickerValue, registrationAgeGroup, questionAnswers) {
        let appData = $store.getters.getAppData;
        let signs = appData.signs[registrationAgeGroup];
        let reflections = appData.reflections[registrationAgeGroup];

        let registrationAgeGroupIndex = ageGroupKeys.indexOf(registrationAgeGroup);

        let structures = this.getDataStructureFromAgeGroup(registrationAgeGroup);

        let data = [
            new DataClass.ResultBarPickerValue(resultBarPickerValue),
            new DataClass.AgeGroup(registrationAgeGroupIndex)
        ];

        for (let questionUid of signs.questionUids) {
            let question = signs.questions[questionUid];

            let yesNoUid = question.uid + '.yesNo';

            let questionAnswerYesNo = 0;

            if (questionAnswers.hasOwnProperty(yesNoUid)) {
                questionAnswerYesNo = questionAnswers[yesNoUid];
            }

            if (question.slider.worried || question.slider.often) {
                let sliderUid = question.uid + '.slider';

                let questionAnswerSlider = {often: 0, worried: 0};

                if (questionAnswers.hasOwnProperty(sliderUid)) {
                    questionAnswerSlider = questionAnswers[sliderUid];
                }

                data.push(new DataClass.SignWithSlider(questionAnswerYesNo, questionAnswerSlider.often || 0, questionAnswerSlider.worried || 0));
            } else {
                data.push(new DataClass.Sign(questionAnswerYesNo));
            }
        }

        for (let reflectionUid of reflections.reflectionUids) {
            let questionAnswerReflection = [];

            if (questionAnswers.hasOwnProperty(reflectionUid)) {
                questionAnswerReflection = questionAnswers[reflectionUid];
            }

            data.push(new DataClass.Reflection(questionAnswerReflection));
        }

        let dataCompressor = new DataCompressor(structures);

        let compressedData = dataCompressor.compress(data);

        return compressedData;
    }

    getDataVersion() {
        return 1;
    }

    compressBinary(dataArray) {
        dataArray = clone(dataArray);

        let segmentsBinary = [];
        let i = 0;

        // The data version
        dataArray.unshift(new DataClass.Version(this.getDataVersion()));

        // The initial data bit
        dataArray.unshift(new DataClass.InitBit());

        let dataStructuresLengthMinusOne = this.dataStructures.length;

        //console.log('dataArray', dataArray)
        //console.log('dataStructures', this.dataStructures);
        for (let i = 0, len = dataArray.length; i < len; i++) {
            if (dataStructuresLengthMinusOne <= i) {
                console.error('Could not find data structure for index', i);

                continue;
            }
            let dataStructure = this.dataStructures[i];
            let dataSegment = dataArray[i];

            let dataValueCompressed = dataStructure.compress(dataSegment);

            segmentsBinary.push(dataValueCompressed);
        }

        console.log('compress.segmentsBinary', segmentsBinary);

        let totalBinaryString = segmentsBinary.join('');

        //console.log('compress.totalBinaryString', totalBinaryString);

        return totalBinaryString;
    }

    compress(dataArray) {
        let totalBinaryString = this.compressBinary(dataArray);

        let bigNumber = bigInt(totalBinaryString, 2);

        return bigNumber.toString(36);
    }

    decompress(dataCompressedString) {
        let bigNumber = bigInt(dataCompressedString, 36);
        let totalBinaryString = bigNumber.toString(2);

        let dataClassSegments = [];

        for (let i = 0, offset = 0, len = this.dataStructures.length; i < len; i++) {
            let dataStructure = this.dataStructures[i];
            let dataStructureBitLength = dataStructure.getBitLength();

            let binaryData = totalBinaryString.substr(offset, dataStructureBitLength);

            offset += binaryData.length;

            let decompressed = dataStructure.decompress(binaryData);

            //console.log(i);
            //console.log('dataStructure', dataStructure);
            //console.log('binaryData', binaryData);
            //console.log('decompressed', decompressed);

            dataClassSegments.push(decompressed);
        }

        console.log(dataClassSegments);

        let initBitValue = dataClassSegments[0].getValue();
        let versionValue = dataClassSegments[1].getValue();

        let expectVersion = this.getDataVersion();

        if (versionValue !== expectVersion) {
            throw new Error('Cannot decompress data with version ' + versionValue + ' as we expected ' + expectVersion);
        }

        return dataClassSegments.slice(2);
    }
}