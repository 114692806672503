<template>
    <div class="result-bar-container">
        <div :class="{'result-bar-with-value': sliderValue > 0, 'result-bar-with-picker': withPicker, 'result-bar-interactive': interactive, 'result-bar-static': !interactive}"
             class="result-bar mt-3">
            <div ref="contentContainer" class="row result-bar-content">
                <help-box
                    v-if="withHelp && !helpClosed && sliderValue === 0"
                    style="left: 150px; top: 80px;"
                    text="Træk markøren på Linealen for at angive din vurdering"
                    v-on:submit="onHelpClose"
                />
                <div v-if="scaleWidthLeft || scaleWidthRight" class="result-bar-scale-container col-10 offset-2">
                    <div class="result-bar-scale-bg"></div>
                    <div :style="{ width: scaleWidthLeft + '%' }"
                         class="result-bar-nonscale-bg result-bar-nonscale-left-bg"/>
                    <div :style="{ width: scaleWidthRight + '%' }"
                         class="result-bar-nonscale-bg result-bar-nonscale-right-bg"/>
                </div>
                <div v-show="withPicker" :style="{'padding-left': pickerPaddingLeft }"
                     class="result-bar-picker-container col-12">
                    <div ref="picker" class="result-bar-picker"/>
                </div>
                <div class="result-segment-container col-2">
                    <span class="result-bar-title">Barnet</span>
                </div>
                <div :class="threatSegments[1] || threatSegments[2] ? 'result-segment-container-active' : ''"
                     class="result-segment-container col-2">
                    <span>Truet</span>
                    <div class="result-segments row">
                        <div ref="firstResultSegment" :class="threatSegments[1] ? 'result-segment-active' : ''" class="result-segment col-6"
                             v-on:click="onClickSegment(1)">
                            1
                            <div class="result-segment-line"></div>
                        </div>
                        <div :class="threatSegments[2] ? 'result-segment-active' : ''" class="result-segment col-6"
                             v-on:click="onClickSegment(2)">
                            2
                            <div class="result-segment-line"></div>
                        </div>
                    </div>
                </div>
                <div :class="threatSegments[3] || threatSegments[4] ? 'result-segment-container-active' : ''"
                     class="result-segment-container col-2">
                    <span>Udsat</span>
                    <div class="result-segments row">
                        <div :class="threatSegments[3] ? 'result-segment-active' : ''" class="result-segment col-6"
                             v-on:click="onClickSegment(3)">
                            3
                            <div class="result-segment-line"></div>
                        </div>
                        <div :class="threatSegments[4] ? 'result-segment-active' : ''" class="result-segment col-6"
                             v-on:click="onClickSegment(4)">
                            4
                            <div class="result-segment-line"></div>
                        </div>
                    </div>
                </div>
                <div :class="threatSegments[5] || threatSegments[6] ? 'result-segment-container-active' : ''"
                     class="result-segment-container col-2">
                    <span>Sårbar</span>
                    <div class="result-segments row">
                        <div :class="threatSegments[5] ? 'result-segment-active' : ''" class="result-segment col-6"
                             v-on:click="onClickSegment(5)">
                            5
                            <div class="result-segment-line"></div>
                        </div>
                        <div :class="threatSegments[6] ? 'result-segment-active' : ''" class="result-segment col-6"
                             v-on:click="onClickSegment(6)">
                            6
                            <div class="result-segment-line"></div>
                        </div>
                    </div>
                </div>
                <div :class="threatSegments[7] || threatSegments[8] || threatSegments[9] ? 'result-segment-container-active' : ''"
                     class="result-segment-container col-3">
                    <span>Moderat trivsel</span>
                    <div class="result-segments row">
                        <div :class="threatSegments[7] ? 'result-segment-active' : ''" class="result-segment col-4"
                             v-on:click="onClickSegment(7)">
                            7
                            <div class="result-segment-line"></div>
                        </div>
                        <div :class="threatSegments[8] ? 'result-segment-active' : ''" class="result-segment col-4"
                             v-on:click="onClickSegment(8)">
                            8
                            <div class="result-segment-line"></div>
                        </div>
                        <div :class="threatSegments[9] ? 'result-segment-active' : ''" class="result-segment col-4"
                             v-on:click="onClickSegment(9)">
                            9
                            <div class="result-segment-line"></div>
                        </div>
                    </div>
                </div>
                <div :class="threatSegments[10] ? 'result-segment-container-active' : ''"
                     class="result-segment-container col-1">
                    <span>Trivsel</span>
                    <div class="result-segments row">
                        <div :class="threatSegments[10] ? 'result-segment-active' : ''" class="result-segment col-12"
                             v-on:click="onClickSegment(10)">
                            10
                            <div class="result-segment-line"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="print-spy" ref="print-spy"></div>
    </div>
</template>

<script>
import noUiSlider from 'nouislider';
import HelpBox from '@/components/help-box/index';
import computedStyle from 'computed-style';

console.log('computedStyle', computedStyle);

export default {
    name: 'result-bar',
    components: {HelpBox},
    props: {
        withPicker: {
            type: Boolean,
            default: false,
        },
        withHelp: {
            type: Boolean,
            default: false,
        },
        threatLevel: {
            type: Number,
            default: -1
        },
        threatBias: {
            type: Number,
            default: null
        },
        threatDisplay: {
            type: Boolean,
            default: false
        },
        value: {
            type: Number,
            default: -1
        },
        interactive: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            sliderValue: 0,
            noUiSlider: null,
            sliderHandle: null,
            sliderHandleText: null,

            threatSegments: {
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false,
                7: false,
                8: false,
                9: false,
                10: false
            },

            scaleWidthLeft: null,
            scaleWidthRight: null,
            pickerPaddingLeft: '136px',

            helpClosed: false
        }
    },
    mounted() {
        setTimeout(() => {
            if (this.withPicker) {
                this.attachPicker();
            } else {
                this.destroyPicker();
            }
        }, 50);

        this.recalculateThreat();
    },
    methods: {
        attachPicker() {
            let slider = this.$refs['picker'];

            if (!slider) {
                console.log('Did not find slider by ref "picker" - trying again in 50 ms');
                setTimeout(this.attachPicker, 50);

                return;
            }

            if (this.interactive) {
                slider.removeAttribute('disabled');
            } else {
                slider.setAttribute('disabled', true);
            }

            let range = {
                'min': 0,
                'max': 10
            };

            let rangeStart = 4.9;

            let rangeLength = 100 - rangeStart;
            let rangeSegment = rangeLength / 10;

            for (let i = 1; i < 10; i++) {
                let percent = rangeStart + (rangeSegment * i);

                range[percent + '%'] = i;
            }

            if (!this.noUiSlider) {
                noUiSlider.create(slider, {
                    start: [0],
                    range: range,
                    snap: true
                });

                this.noUiSlider = slider.noUiSlider;

                let handle = this.noUiSlider.target.querySelector('.noUi-handle');

                this.sliderHandle = handle;

                if (!handle.querySelector('.handle-text')) {
                    let handleText = document.createElement('span');

                    handleText.classList = 'handle-text';
                    handleText.textContent = 'Min Vurdering';

                    this.sliderHandleText = handleText;

                    console.log(handleText);

                    handle.appendChild(handleText);
                }

                this.updateSliderHandleWidth();

                this.noUiSlider.on('update', (values, handle) => {
                    this.sliderValue = parseInt(values[0], 10);

                    this.updateSliderHandleWidth();
                });

                window.removeEventListener('resize', this.updateSliderHandleWidth.bind(this));
                window.addEventListener('resize', this.updateSliderHandleWidth.bind(this));
            }

            if (this.value >= 0) {
                this.onClickSegment(this.value, true);
            }
        },
        destroyPicker() {
            if (this.noUiSlider) {
                this.noUiSlider.destroy();

                this.noUiSlider = null;
            }
        },
        recalculateThreat() {
            if (this.threatLevel < 0 || !this.threatDisplay) {
                for (let i = 1; i <= 10; i++) {
                    this.threatSegments[i] = false;
                }

                this.scaleWidthLeft = 0;
                this.scaleWidthRight = 0;

                return;
            }

            let percentage = ((this.threatLevel - 0.5) / 10) * 100;
            let percentageAccuracy = 15;

            if (this.threatBias !== null && !isNaN(this.threatBias)) {
                percentageAccuracy = 10;

                if (this.threatBias === -1) {
                    percentage += -5;
                } else if (this.threatBias === 0) {
                    percentage += 0;
                } else if (this.threatBias === 1) {
                    percentage += 5;
                }
            }

            let startPercentage = Math.max(0, percentage - percentageAccuracy);
            let endPercentage = Math.min(100, percentage + percentageAccuracy);

            startPercentage = Math.max(0, startPercentage);
            endPercentage = Math.min(100, endPercentage);

            for (let i = 1; i <= 10; i++) {
                let iPercent = (i - .5) * 10;

                this.threatSegments[i] = (iPercent >= startPercentage && iPercent <= endPercentage);
            }

            console.log('Threat percent', {
                threatLevel: this.threatLevel,
                threatBias: this.threatBias,

                threatSegments: this.threatSegments,

                percentage: percentage,
                percentageAccuracy: percentageAccuracy,
            });

            this.scaleWidthLeft = startPercentage;
            this.scaleWidthRight = 100 - endPercentage;
        },
        onClickSegment(segmentValue, ignoreInteractive = false) {
            if (!ignoreInteractive && !this.interactive) {
                return;
            }

            if (!this.noUiSlider) {
                return;
            }

            this.noUiSlider.set(segmentValue);
        },
        updateSliderHandleWidth(e) {
            if (!this.sliderHandle) {
                return;
            }

            if (this.$refs['firstResultSegment'] && this.$refs['contentContainer']) {
                let firstResultSegmentWidth = this.$refs['firstResultSegment'].clientWidth;
                let resultBarContentWidth = this.$refs['contentContainer'].clientWidth;

                this.sliderHandle.style.width = (firstResultSegmentWidth + 10) + 'px';

                let maxWidth = 1200;
                let minWidth = 634;
                let maxPadding = 136;
                let minPadding = 60;

                let percentage = (resultBarContentWidth - minWidth) / (maxWidth - minWidth);
                let reversePercentage = 1 - percentage;
                let diffPadding = maxPadding - minPadding;
                let calcPadding = minPadding + (diffPadding * percentage);

                //console.log('resultBarContentWidth', resultBarContentWidth, percentage, reversePercentage);

                const isPdfCreator = !!(window && window.hasOwnProperty('isPdfCreator') && window['isPdfCreator']);

                if (isPdfCreator) {
                    // Print stylesheets and the pdf creator has some weird problem with margins and the ruler
                    // This weird magic calculation gets it into the right place
                    calcPadding += 40 + (this.value * 4);
                }

                this.pickerPaddingLeft = calcPadding + 'px';

                // resultBarContentWidth = 1200
                // pickerPaddingLeft = 136

                // resultBarContentWidth = 634 (52.8%)
                // pickerPaddingLeft = 60 (44.1%)
            }
        },
        onHelpClose() {
            this.helpClosed = true;

            this.$emit('helpClosed');
        }
    },
    beforeDestroy() {
        this.destroyPicker();
    },
    watch: {
        withPicker() {
            if (this.withPicker) {
                this.attachPicker();
            } else {
                this.destroyPicker();
            }
        },
        value(newValue, oldValue) {
            if (this.noUiSlider && newValue !== this.sliderValue) {
                this.noUiSlider.set(this.value);
            }
        },
        sliderValue() {
            this.$emit('input', this.sliderValue);
        },
        threatLevel() {
            this.recalculateThreat();
        },
        threatBias() {
            this.recalculateThreat();
        },
        threatDisplay() {
            this.recalculateThreat();
        },
        interactive() {
            this.attachPicker();
        }
    }
};
</script>

<style lang="scss">
@import "node_modules/nouislider/distribute/nouislider";

.result-bar-static .result-bar-picker.noUi-target .noUi-handle {
    cursor: default !important;
}

.result-bar-picker {
    &.noUi-target {
        background: transparent;
        border-radius: 0;
        border: 0;
        box-shadow: none;

        * {
            -webkit-tap-highlight-color: transparent;
        }

        .noUi-handle {
            background: url("~@/assets/components/result-bar/picker_small.png");
            height: 92px;
            background-size: 100% 92px;
            cursor: col-resize;
            position: relative;
            left: 14px;

            span.handle-text {
                left: 50%;
                transform: translateX(-50%);
                top: 12px;

                position: absolute;
                margin-top: 50px;
                width: 80px;
                font-size: 9px;
                text-align: center;
                background: #2e427e;
                white-space: nowrap;
            }

            &:focus {
                outline: 0;
            }

            font-weight: bold;
            font-size: 14px;
            color: white;
            text-align: center;
            padding-top: 56px;
            border: 0;
            border-radius: 0;
            box-shadow: none;

            &:before, &:after {
                height: 0 !important;
                background: transparent !important;
                left: 0 !important;
                right: 0 !important;
            }
        }
    }
}
</style>
<style lang="scss" scoped>
$height: 50px;

.result-bar-container {
    min-width: 600px;
}

.result-bar {
    &.result-bar-with-value {
        .result-bar-scale-bg {
            opacity: .4;
        }
    }
}

.result-bar-scale-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $height - 6;
    padding: 0;

    .result-bar-scale-bg, .result-bar-nonscale-left-bg, .result-bar-nonscale-right-bg {
        position: absolute;
        height: 100%;
        top: 0;
    }

    .result-bar-scale-bg {
        width: 100%;
        left: 0;
        background: linear-gradient(90deg, #CD3625 0%, #F4BC65 55.3%, #21855A 100%);
        transition-property: opacity;
        transition-duration: 300ms;
    }

    .result-bar-nonscale-left-bg, .result-bar-nonscale-right-bg {
        background: #E6E6E6;
    }

    .result-bar-nonscale-left-bg {
        left: 0;

        width: 20%;
    }

    .result-bar-nonscale-right-bg {
        right: 0;

        width: 60%;
    }
}

.result-bar {
    position: relative;
    background: #E6E6E6;
    /* White */

    border: 3px solid #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    height: $height;
    margin-left: -20px;
    margin-right: -20px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;

    &.result-bar-with-picker {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

.result-bar-picker-container {
    position: absolute;
    height: 44px;
    top: -2px;
    width: 100%;
}

.result-bar-content {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}

@media print {
    .result-bar-content {
    }
}

.result-bar-with-picker {
    .result-segment {
        cursor: pointer;
    }
}

.result-bar-with-picker.result-bar-static {
    .result-segment {
        cursor: default !important;
    }
}

.result-segment-container {
    position: relative;
    height: 44px;

    &.result-segment-container-active {
        > span {
            font-weight: bold;
        }
    }

    .result-segment {
        text-align: center;
        color: $black;
        height: 44px;
        position: relative;
        padding-top: 22px;
        border: 1px solid rgba(#ffffff, .3);
        font-size: 14px;

        .result-segment-line {
            left: 50%;
            top: 0;
            width: 1px;
            position: absolute;
            background: rgba(0, 0, 0, 0.05);
            height: 44px;
            transform: translate(-0.5px, 0);
        }

        &.result-segment-active {
            font-weight: bold;
        }
    }
}

span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: $black;

    &.result-bar-title {
        top: 0;
        right: 10px;
        font-weight: 500;
        font-size: 22px;
        color: $gray-dark;
        line-height: 44px;

    }
}

.result-bar-nonscale-bg {
    transition: width 600ms ease-in-out;
    overflow: hidden;
    will-change: width;
}

#print-spy {
    display: inline-block;
    position: fixed;
    bottom: -50px;
    width: 1px;
    height: 1px;
    left: -50px;
    visibility: hidden;
}

@media print {
    #print-spy {
        height: 2px;
    }
}
</style>
