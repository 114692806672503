<template>
    <div class="reflections-container game-container">
        <transition :duration="300" :name="transitionName">
            <div v-if="currentPage === 'start'" :key="'start'" class="reflections-intro page-slide-child">
                <div class="question-container intro-text-container">
                    <h4>Refleksion</h4>
                    <p>Tag stilling til om der er forhold i barnets eller den unges hverdag, som kan være en ressource
                        eller en beskyttende faktor.</p>
                </div>
                <div class="button-container">
                    <roskilde-button borderType="full" size="lg" type="white" widthType="fixed"
                                     v-on:click="showFactors">Vis faktorer
                    </roskilde-button>
                </div>
            </div>
            <div v-if="currentPage === 'questions' && currentQuestion" :key="questionIndex"
                 class="page-slide-child">
                <div class="question-container">
                    <h5>Refleksion</h5>
                    <h4>{{ currentQuestion.reflection }}</h4>

                    <reflection-checkbox-container :checkboxes="currentCheckboxes" :value="currentCheckboxesAnswers"
                                                   v-on:input="currentCheckboxesAnswers = $event"/>
                </div>

                <div v-if="currentQuestion" class="button-container">
                    <roskilde-button
                        :disabled="currentCheckboxesAnswers.length === 0"
                        :text="'Vis næste kategori'"
                        borderType="full"
                        size="xl"
                        type="white"
                        widthType="fixed"
                        v-on:click="clickNext"
                    />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import RoskildeButton from '@/components/buttons/RoskildeButton.vue';
import SummaryContainer from '@/components/summary/SummaryContainer.vue';
import * as MutationTypes from '@/store/mutation-types'
import {mapGetters} from 'vuex';
import ReflectionCheckboxContainer from '@/components/input/ReflectionCheckboxContainer';
import * as ScoreCalculator from '@/utils/scoreCalculator';

export default {
    name: 'reflections-view',
    components: {
        ReflectionCheckboxContainer,
        RoskildeButton,
        SummaryContainer,
    },
    beforeMount() {
        if (!this.registrationAgeGroup) {
            this.$router.push('/setup');

            return;
        }
    },
    beforeDestroy() {
        let reflectionsSecondsTaken = this.$gaAnalytics.timerGetSeconds('reflections');

        this.$gaAnalytics.events.reflectionsEnd(reflectionsSecondsTaken);
        this.$gaAnalytics.events.reflectionsEndCount(this.questions.length, reflectionsSecondsTaken);

        this.$gaAnalytics.timerEnd('reflections');
    },
    data() {
        return {
            reflectionsStartTime: null,

            allQuestionUids: [],
            allQuestions: {},

            questions: [],

            transitionName: null,

            currentCheckboxes: [],
            currentCheckboxesAnswers: [],
        }
    },
    mounted() {
        this.$store.commit(MutationTypes.SET_OVERVIEW_BAR_SHOW, true);
        this.$store.commit(MutationTypes.SET_RESULT_BAR_SHOW, true);
        this.$store.commit(MutationTypes.SET_HEADER_TITLE_SHOW, true);
        this.$store.commit(MutationTypes.SET_OVERVIEW_BAR_ACTIVE_STEP, 'reflections');
        this.$store.commit(MutationTypes.SET_EXTERNAL_LINK, null);
        this.$store.commit(MutationTypes.SET_CURRENT_SIGN_INDEX, null);
        this.$store.commit(MutationTypes.SET_CURRENT_REFLECTION_INDEX, null);
        this.$store.commit(MutationTypes.SET_RESULT_BAR_THREAT_DISPLAY, true);

        let allQuestionUids = [];
        let allQuestions = {};

        if (this.appData.reflections.hasOwnProperty(this.registrationAgeGroup)) {
            let reflectionData = this.appData.reflections[this.registrationAgeGroup];

            allQuestionUids = reflectionData.reflectionUids;
            allQuestions = reflectionData.reflections;
        } else {
            console.error('Could not find reflections for age group', this.registrationAgeGroup);
        }

        this.allQuestionUids = allQuestionUids;
        this.allQuestions = allQuestions;

        for (let questionUid of allQuestionUids) {
            this.questions.push(allQuestions[questionUid]);
        }

        this.$store.commit(MutationTypes.SET_TOTAL_REFLECTION_INDEXES, this.questions.length);

        this.$gaAnalytics.events.reflectionsStart();
        this.$gaAnalytics.events.reflectionsStartCount(this.questions.length);

        this.$gaAnalytics.timerStart('reflections');
    },
    methods: {
        showFactors() {
            this.$router.push('/reflections/questions/1');
        },
        clickNext() {
            this.answerQuestion(this.currentCheckboxesAnswers);
        },
        getCurrentQuestionAnswerUid() {
            return this.getAnswerUid(this.currentQuestionUid);
        },
        getAnswerUid(uid) {
            return ScoreCalculator.getAnswerUid(uid, 'reflections');
        },
        answerQuestion(answer) {
            let answerUid = this.getCurrentQuestionAnswerUid();

            let answerInts = [];

            for (let singleAnswer of answer) {
                answerInts.push(parseInt(singleAnswer, 10));
            }

            this.$store.commit(MutationTypes.ANSWER_QUESTION, {
                uid: answerUid,
                answer: answerInts
            });

            if (this.questionIndex < (this.questions.length - 1)) {
                this.$router.push('/reflections/questions/' + (this.questionIndex + 2));
            } else {
                this.$router.push('/summary');
            }
        },
        getQuestionIndexFromRoute($route) {
            let questionIndex;

            if ($route.params.page === 'start') {
                questionIndex = -1;
            } else if ($route.params.questionIndex !== null) {
                questionIndex = Math.max(0, parseInt($route.params.questionIndex, 10) - 1);
            }

            if (isNaN(questionIndex) || questionIndex < 0) {
                questionIndex = -1;
            }

            return questionIndex;
        }
    },
    computed: {
        ...mapGetters({
            appData: 'getAppData',
            expectedNextStep: 'getExpectedNextStep',
            registrationAgeGroup: 'getRegistrationAgeGroup',
            questionAnswers: 'getQuestionAnswers',
        }),
        questionIndex() {
            let questionIndex = this.getQuestionIndexFromRoute(this.$route);

            return questionIndex >= 0 ? questionIndex : null;
        },
        currentPage() {
            return this.$route.params.page || 'start';
        },
        currentQuestionUid() {
            return this.currentQuestion ? this.currentQuestion.uid : null;
        },
        currentQuestion() {
            return this.questions[this.questionIndex];
        },
        currentQuestionAnswer() {
        }
    },
    watch: {
        questionIndex(to, from) {
            this.$store.commit(MutationTypes.SET_CURRENT_REFLECTION_INDEX, to);
        },
        '$route'(to, from) {
            let questionIndexFrom = this.getQuestionIndexFromRoute(from);
            let questionIndexTo = this.getQuestionIndexFromRoute(to);

            if (questionIndexFrom === questionIndexTo) {
                // Same question - might be changing phase
                let fromPhase = 'yesNo';
                let toPhase = 'yesNo';

                if (from.meta && from.meta.withSlider) {
                    fromPhase = 'slider';
                }

                if (to.meta && to.meta.withSlider) {
                    toPhase = 'slider';
                }

                if (toPhase === 'slider' && fromPhase === 'yesNo') {
                    this.transitionName = 'slide-forward';
                } else if (toPhase === 'yesNo' && fromPhase === 'slider') {
                    this.transitionName = 'slide-backward';
                }
            } else if (questionIndexFrom < questionIndexTo) {
                // Going forward
                this.transitionName = 'slide-forward';
            } else if (questionIndexFrom > questionIndexTo) {
                // Going backwards
                this.transitionName = 'slide-backward';
            } else {
                this.transitionName = null;
            }
        },
        currentQuestion() {
            let externalLink = null;

            if (this.currentQuestion) {
                if (this.currentQuestion.link && this.currentQuestion.link.url) {
                    externalLink = {
                        url: this.currentQuestion.link.url,
                        label: this.currentQuestion.link.label || null,
                    };
                }

                let currentCheckboxes = [];

                for (let answer of this.currentQuestion.answers) {
                    currentCheckboxes.push({
                        label: answer.text,
                        value: answer.scoreModification,
                    })
                }

                this.currentCheckboxes = currentCheckboxes;

                if (this.questionAnswers.hasOwnProperty(this.currentQuestionUid)) {
                    let questionAnswers = this.questionAnswers[this.currentQuestionUid];

                    let checkboxAnswers = [];

                    for (let questionAnswer of questionAnswers) {
                        checkboxAnswers.push(questionAnswer.toString());
                    }

                    this.currentCheckboxesAnswers = checkboxAnswers;
                } else {
                    this.currentCheckboxesAnswers = [];
                }
            }

            this.$store.commit(MutationTypes.SET_EXTERNAL_LINK, externalLink);
        }
    }
}
</script>

<style lang="scss" scoped>
.reflections-intro .question-container {
    //@include alignCenter(true);
}

.button-container {
    margin-bottom: 30px;
}

h4 {
    font-weight: bold;
    font-size: 40px;
    color: $gray-dark;
    text-align: center;
}

h3 {
    font-weight: bold;
    font-size: 50px;
    color: $gray-dark;
    text-align: center;
}

.question-container {
    margin-top: -40px;

    h5 {
        color: $gray;
        font-size: 26px;
        margin-top: 30px;
    }

    h4 {
        font-size: 36px;
    }
}

.reflection-checkbox-container {
    margin-top: 30px;
}

.intro-text-container {
    color: #4b4b4b;
    font-size: 18px;
    max-width: 640px;
    text-align: center;
    margin: 0 auto;
}

.page-slide-child {
    margin-top: 40px;
}
</style>
