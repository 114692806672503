import Vue from 'vue'
import Vuex from 'vuex'

import initState from './state'

//import initState from './state-dev'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

Vue.use(Vuex)

const store = new Vuex.Store({
    actions: actions,
    state: initState,
    getters: getters,
    mutations: mutations,
})

export default store
